import PropTypes from "prop-types";
import "./styles.scss";

const Wrapper = ({
  children,
  textAlign,
  borderRadius,
  cursorPointer = false,
  width = "initial",
  maxWidth = "initial",
  isInline = false,
  divider = false,
  shadow = false,
  hover = false,
  scrollX = false,
  backgroundColor = "",
  onClick = () => {},
  src,
  ...props
}) => {
  const classNames = Object.keys(props).map((key) => `hg-${key}-${props[key]}`);

  if (shadow) {
    classNames.push("hg-wrapper-with-shadow");
  }
  if (backgroundColor) {
    classNames.push(`hg-wrapper-background-${backgroundColor}`);
  }
  if (hover) {
    classNames.push("hg-wrapper-with-hover");
  }
  if (isInline) {
    classNames.push("hg-wrapper-inline");
  }
  if (textAlign) {
    classNames.push(`hg-al-${textAlign}`);
  }
  if (borderRadius) {
    classNames.push(`hg-border-radius-${borderRadius}`);
  }
  if (scrollX) {
    classNames.push(`hg-scroll-x`);
  }
  if (cursorPointer) {
    classNames.push(`hg-wrapper-cursor-pointer`);
  }

  return (
    <div
      onClick={onClick}
      style={{ width: width, maxWidth: maxWidth }}
      className={classNames.join(" ")}
    >
      {children}
    </div>
  );
};

export default Wrapper;

Wrapper.propTypes = {
  m: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9]),
  mx: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9]),
  my: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9]),
  mt: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9]),
  mr: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9]),
  mb: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9]),
  ml: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9]),
  p: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9]),
  px: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9]),
  py: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9]),
  pt: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9]),
  pr: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9]),
  pb: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9]),
  pl: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9]),
  children: PropTypes.node,
  isInline: PropTypes.bool,
  divider: PropTypes.bool,
  shadow: PropTypes.bool,
  scrollX: PropTypes.bool,
  display: PropTypes.oneOf(["block", "inline", "flex"]),
  justifyContent: PropTypes.oneOf([
    "space-between",
    "center",
    "flex-end",
    "flex-start",
  ]),
  alignItems: PropTypes.oneOf(["center", "flex-end"]),
  flexDirection: PropTypes.oneOf(["column"]),
  textAlign: PropTypes.oneOf([
    "left",
    "right",
    "center",
    "justify",
    "initial",
    "inherit",
  ]),
};
