export default {
  add: async (type) => {
    return fetch("/backend/country", {
      method: "POST",
      body: JSON.stringify(type),
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      if (response.status !== 401) {
        return response.json().then((data) => data);
      } else return { message: { msgBody: "UnAuthorized" }, msgError: true };
    });
  },
  get: async (params) => {
    return fetch("/backend/country?" + new URLSearchParams(params)).then(
      (response) => {
        if (response.status !== 401) {
          return response.json().then((data) => data);
        } else return { message: { msgBody: "UnAuthorized" }, msgError: true };
      }
    );
  },
  update: async ({ id, updatedData }) => {
    return fetch(`/backend/country/${id}`, {
      method: "PATCH",
      body: JSON.stringify(updatedData),
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      if (response.status !== 401) {
        return response.json().then((data) => data);
      } else return { message: { msgBody: "UnAuthorized" }, msgError: true };
    });
  },
};
