import Icon from "@ant-design/icons";

const LocationSvg = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d)">
      <rect x="2" width="36" height="36" rx="8" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.917 16.5982C12.917 12.765 16.1202 9.66675 19.9949 9.66675C23.8804 9.66675 27.0837 12.765 27.0837 16.5982C27.0837 18.5298 26.3812 20.3231 25.2249 21.8431C23.9493 23.5197 22.3771 24.9805 20.6074 26.1271C20.2024 26.3921 19.8369 26.4121 19.3924 26.1271C17.6126 24.9805 16.0404 23.5197 14.7757 21.8431C13.6186 20.3231 12.917 18.5298 12.917 16.5982ZM17.6622 16.814C17.6622 18.0982 18.71 19.1081 19.9949 19.1081C21.2805 19.1081 22.3385 18.0982 22.3385 16.814C22.3385 15.5399 21.2805 14.4808 19.9949 14.4808C18.71 14.4808 17.6622 15.5399 17.6622 16.814Z"
        fill="#FB5B4E"
      />
      <rect x="2.5" y="0.5" width="35" height="35" rx="7.5" stroke="#FB5B4E" />
    </g>
    <defs>
      <filter
        id="filter0_d"
        x="0"
        y="0"
        width="40"
        height="40"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.141176 0 0 0 0 0.345098 0 0 0 0 1 0 0 0 0.04 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export const LocationIconOutline = (props) => (
  <Icon component={LocationSvg} {...props} />
);
