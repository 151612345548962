import Icon from "@ant-design/icons";

const ArrowRightSvg = ({fill = "#FB5B4E"}) => (
  <svg
    width="12"
    height="14"
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.999998 8.00006L8.59 8.00006L5.29 11.2901C5.10169 11.4784 4.99591 11.7338 4.99591 12.0001C4.99591 12.2664 5.10169 12.5218 5.29 12.7101C5.4783 12.8984 5.7337 13.0041 6 13.0041C6.2663 13.0041 6.52169 12.8984 6.71 12.7101L11.71 7.71006C11.801 7.61495 11.8724 7.50281 11.92 7.38006C12.02 7.1366 12.02 6.86352 11.92 6.62006C11.8724 6.49731 11.801 6.38516 11.71 6.29006L6.71 1.29006C6.61703 1.19633 6.50643 1.12194 6.38457 1.07117C6.26272 1.0204 6.13201 0.99426 6 0.99426C5.86799 0.99426 5.73728 1.0204 5.61542 1.07117C5.49356 1.12194 5.38296 1.19633 5.29 1.29006C5.19627 1.38302 5.12188 1.49362 5.07111 1.61548C5.02034 1.73734 4.9942 1.86805 4.9942 2.00006C4.9942 2.13207 5.02034 2.26278 5.07111 2.38464C5.12188 2.50649 5.19627 2.61709 5.29 2.71006L8.59 6.00006L0.999998 6.00006C0.734781 6.00006 0.480426 6.10541 0.29289 6.29295C0.105354 6.48049 -2.31294e-06 6.73484 -2.33613e-06 7.00006C-2.35931e-06 7.26527 0.105354 7.51963 0.29289 7.70716C0.480426 7.8947 0.734781 8.00006 0.999998 8.00006Z"
      fill={fill}
    />
  </svg>
);

export const ArrowRight = (props) => (
  <Icon component={ArrowRightSvg} {...props} />
);
