import GoogleLogin from "react-google-login";
import { Button } from "antd";
import GoogleLoginSvg from "../../images/googleLogin.svg";

import "./styles.scss";

const LoginWithGoogle = ({ onLogin }) => {
  const responseGoogle = async (response) => {
    const { name, email, googleId } = await response.profileObj;
    const data = {
      email,
      fullName: name,
      provider: "google",
      googleId,
    };
    onLogin(data);
  };

  return (
    <GoogleLogin
      render={(renderProps) => (
        <Button
          icon={<img alt={"google"} src={GoogleLoginSvg} />}
          className={"my-google-button-class"}
          onClick={renderProps.onClick}
          disabled={renderProps.disabled}
        />
      )}
      clientId="694298487279-t7l5n9hn3nm39n1jh2q3f57139t4dcb1.apps.googleusercontent.com"
      buttonText="Login"
      onSuccess={responseGoogle}
      onFailure={responseGoogle}
      cookiePolicy={"single_host_origin"}
    />
  );
};

export default LoginWithGoogle;
