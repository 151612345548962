import { memo } from "react";
import { Row, Col } from "antd";
import Layout from "layout";
import { useFieldsTranslation } from "utils/hooks";
import RestaurantMenu from "./RestaurantMenu";
import RestaurantInfo from "./RestaurantInfo";

const Restaurant = memo(({ match, location }) => {
  const { t } = useFieldsTranslation();
  const colPropsRestInfo = {
    xs: 24,
    sm: 24,
    md: 24,
    lg: 8,
    xl: 8,
    xxl: 8,
  };
  const colPropsFood = {
    xs: 24,
    sm: 24,
    md: 24,
    lg: 16,
    xl: 16,
    xxl: 16,
  };

  return (
    <Layout type="sm">
      <Row gutter={[16, 16]}>
        <Col {...colPropsRestInfo}>
          <RestaurantInfo match={match} />
        </Col>
        <Col {...colPropsFood}>
          <RestaurantMenu match={match} location={location} />
        </Col>
      </Row>
    </Layout>
  );
});

export default Restaurant;
