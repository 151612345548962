import Icon from "@ant-design/icons";

const CallingSvg = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 40 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <rect x="2" width="36" height="36" rx="8" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.9635 9.67336C21.7776 9.64671 21.5909 9.70167 21.4423 9.81992C21.2896 9.93984 21.1942 10.1131 21.1734 10.3071C21.1295 10.6993 21.4125 11.0541 21.8042 11.0982C24.5056 11.3997 26.6053 13.5041 26.909 16.2139C26.9497 16.5769 27.2543 16.8509 27.6178 16.8509C27.6452 16.8509 27.6717 16.8493 27.6991 16.8459C27.8891 16.8251 28.0585 16.731 28.178 16.5811C28.2966 16.4312 28.3506 16.2447 28.329 16.054C27.9506 12.673 25.3338 10.0489 21.9635 9.67336ZM22.0154 12.575C21.6187 12.5017 21.2544 12.7549 21.1788 13.1421C21.1033 13.5294 21.3573 13.9074 21.7432 13.9832C22.9051 14.2097 23.8022 15.1091 24.0296 16.275V16.2758C24.0943 16.6114 24.3898 16.8554 24.7301 16.8554C24.7757 16.8554 24.8214 16.8513 24.8678 16.8429C25.2537 16.7655 25.5077 16.3882 25.4322 16.0002C25.0927 14.2589 23.7524 12.914 22.0154 12.575ZM23.3337 20.5755C23.7099 20.3586 24.1349 20.1136 24.6709 20.2276C25.1564 20.33 26.8113 21.6733 27.2644 22.1388C27.5615 22.4436 27.7266 22.7584 27.7524 23.0732C27.7972 24.3081 26.1191 25.718 25.8137 25.8937C25.4079 26.186 24.9356 26.3334 24.4053 26.3334C23.8634 26.3334 23.2592 26.1794 22.6002 25.8721C19.025 24.3798 13.5633 19.0259 12.1192 15.4784C11.52 14.1576 11.5159 13.0684 12.1093 12.2498C12.3458 11.8659 13.6944 10.2611 14.9019 10.3119C15.2231 10.3394 15.5352 10.5043 15.8414 10.8041C16.3045 11.2579 17.6141 12.9176 17.7153 13.4048C17.8274 13.9461 17.5817 14.3766 17.3635 14.7547C17.3169 14.836 17.2542 14.9324 17.1855 15.0381C16.9247 15.4391 16.5775 15.9729 16.7004 16.3136C17.5809 18.4755 19.615 20.3617 21.7778 21.2477C22.1123 21.3693 22.6457 21.0197 23.0455 20.7577C23.1496 20.6895 23.2447 20.6272 23.3248 20.5807L23.3337 20.5755Z"
        fill="#FB5B4E"
      />
      <rect x="2.5" y="0.5" width="35" height="35" rx="7.5" stroke="#FB5B4E" />
    </g>
  </svg>
);

export const CallingIcon = (props) => (
  <Icon className="calling-icon" component={CallingSvg} {...props} />
);
