import {
  createContext,
  useState,
  useEffect,
  useContext,
  useCallback,
} from "react";
import { AuthContext } from "context/AuthContext";
import FeedbackService from "services/FeedbackService";
export const UserRateContext = createContext();

export default ({ children }) => {
  const { isAuthenticated, user } = useContext(AuthContext);
  const [userRates, setUserRates] = useState([]);
  const [rates, _setRates] = useState({});

  const setRates = useCallback((id, value) => {
    _setRates((rates) => ({
      ...rates,
      [id]: value,
    }));
  }, []);

  useEffect(() => {
    updateUserRates();
  }, [user]);

  function updateUserRates() {
    if (user?.id) {
      const feedbackCondition = {
        where: JSON.stringify({
          userId: user.id,
        }),
      };

      FeedbackService.get(feedbackCondition).then((data) => {
        if (data.feedback && data.feedback.length) {
          setUserRates(data.feedback);
          const newRates = {};
          data.feedback.map((item) => {
            newRates[item.entityId] = item.rate;
          });
          _setRates(newRates);
        }
      });
    } else {
      _setRates({});
      setUserRates([]);
    }
  }

  return (
    <UserRateContext.Provider
      value={{
        userRates,
        setUserRates,
        isAuthenticated,
        rates,
        setRates,
        user,
        updateUserRates,
      }}
    >
      {children}
    </UserRateContext.Provider>
  );
};
