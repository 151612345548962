import React, { useState } from "react";
import { Form, Button } from "antd";

const TranslatableFormItem = ({
  name,
  label,
  field: Field,
  fieldProps = {},
  ...rest
}) => {
  const [showTranslation, setShowTranslation] = useState(false);

  return (
    <>
      <Form.Item label={label} name={name} {...rest}>
        <Field {...fieldProps} />
      </Form.Item>
      <Button
        size="middle"
        type="dashed"
        onClick={() => setShowTranslation(!showTranslation)}
      >
        translations
      </Button>
      {showTranslation && (
        <>
          <Form.Item
            label={`${label} HY`}
            name={`${name}_hy`}
            {...rest}
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Field {...fieldProps} />
          </Form.Item>
          <Form.Item
            label={`${label} RU`}
            name={`${name}_ru`}
            {...rest}
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Field {...fieldProps} />
          </Form.Item>
          <Form.Item
            label={`${label} EN`}
            name={`${name}_en`}
            {...rest}
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Field {...fieldProps} />
          </Form.Item>
        </>
      )}
    </>
  );
};

export default TranslatableFormItem;
