import { useState, useRef, useContext, useEffect } from "react";
import { useFieldsTranslation } from "utils/hooks";
import { Popover, Row, Col, Radio, Spin, message } from "antd";
import LoginModal from "../LoginModal";
import FeedbackService from "../../services/FeedbackService";
import AvgEmoji from "../../images/avgEmoji.svg";
import BadEmoji from "../../images/badEmoji.svg";
import GreatEmoji from "../../images/greatEmoji.svg";
import { AverageRateEmoji } from "../Icons/AverageRateEmoji";
import { GreatRateEmoji } from "../Icons/GreatRateEmoji";
import { BadRateEmoji } from "../Icons/BadRateEmoji";
import Wrapper from "../Wrapper";
import "./styles.scss";
import { UserRateContext } from "context/UserRateContext";

// for ignor storbook context  error uncoment this
// const storybookData = {
// userRates: [],
// isAuthenticated: true,
// user: {},
// updateUserRates: () => { },
// };

const RatingCard = ({ rateData }) => {
  const { t } = useFieldsTranslation();
  const loginModalRef = useRef(null);
  const { feedbackType, entityId, maxRate } = rateData;

  const {
    userRates,
    setUserRates,
    setRates,
    user,
    isAuthenticated,
    rates,
  } = useContext(UserRateContext);
  // const { userRates, isAuthenticated, user, updateUserRates } = storybookData;
  const userId = user.id;
  const ratedValue = rates[entityId];
  const [rate, setRate] = useState(rateData.rate);
  const [loading, setLoading] = useState(false);
  const ratedData = userRates?.filter(
    (item) => item.entityId === entityId && item.userId === userId
  );
  function handleShowLoginModal() {
    if (!isAuthenticated) {
      loginModalRef.current.open();
    }
  }
  const emojiRate = {
    1: <BadRateEmoji />,
    3: <AverageRateEmoji />,
    5: <GreatRateEmoji />,
  };

  function rateEmoji(rate) {
    if (rate > 4 || rate == 4) return GreatEmoji;
    if ((rate < 4 && rate > 3) || rate == 3) return AvgEmoji;
    if (rate < 3) return BadEmoji;
  }

  async function handleRate(value, userId) {
    if (!ratedData.length && !loading) {
      setLoading(true);
      const resData = await FeedbackService.add({
        userId,
        entityId,
        rate: value || ratedValue,
        feedbackType,
        comment: "",
      });
      if (resData.food) {
        setRate(resData.food?.rating);
        setUserRates([...userRates, resData.feedback]);
      }
      if (resData?.message.msgError) {
        message.warning(resData.message.msgBody);
      }
      setLoading(false);
    }
  }

  async function handleClickEmoji(e) {
    const value = e.target.value;
    setRates(entityId, value);
    if (!isAuthenticated) {
      handleShowLoginModal();
    } else {
      handleRate(value, userId);
    }
  }

  const userRate = emojiRate[ratedData[0]?.rate || ratedValue];

  const content = (
    <>
      {!userRate ? (
        <Radio.Group
          className="hg-rate-radio-group"
          onChange={handleClickEmoji}
          value={ratedData[0]?.rate}
        >
          <Row gutter={8}>
            <Col span={8}>
              <div className="hg-emoji-wrapper">
                <Radio value={1} />
                <BadRateEmoji />
              </div>
            </Col>
            <Col span={8}>
              <div className="hg-emoji-wrapper">
                <Radio value={3} />
                <AverageRateEmoji />
              </div>
            </Col>
            <Col span={8}>
              <div className="hg-emoji-wrapper">
                <Radio value={5} />
                <GreatRateEmoji />
              </div>
            </Col>
          </Row>
        </Radio.Group>
      ) : (
        <div className="hg-rated-emoji-wrapper">{userRate}</div>
      )}
    </>
  );

  return (
    <div
      className="hg-food-rating-container"
      onClick={(e) => e.stopPropagation()}
    >
      {rate ? (
        <>
          <Popover
            zIndex={1}
            className={"hg-rate-popover"}
            content={content}
            title={
              !ratedData.length || !rate ? t("rate.it") : t("thanks.for.rate")
            }
            trigger="click"
          >
            {loading ? (
              <Spin />
            ) : (
              <Wrapper display="flex" alignItems="center">
                {rate} / {maxRate}
              </Wrapper>
            )}
            <Wrapper pl={7}>
              <img
                style={{ width: "24px", height: "24px" }}
                className="hg-emoji"
                src={rateEmoji(rate)}
              />
            </Wrapper>
          </Popover>
        </>
      ) : (
        <Popover
          zIndex={1}
          className={"hg-rate-popover"}
          content={content}
          title={t("rate.it")}
          trigger="click"
        >
          <span>{t("rate.it")}</span>
        </Popover>
      )}
      <LoginModal
        ref={loginModalRef}
        onLogin={(user) => handleRate(ratedValue, user.id)}
        onFailure={() => {
          setRates(entityId, null);
        }}
      />
    </div>
  );
};

export default RatingCard;
