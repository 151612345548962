export default {
  login: (user) => {
    return fetch(`/backend/user/login${`-${user.provider}`}`, {
      method: "POST",
      body: JSON.stringify(user),
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => {
      if (res.status !== 401) return res.json().then((data) => data);
      else
        return {
          isAuthenticated: false,
          user: { email: "", role: "" },
          message: { msgBody: "Unauthorized", msgError: true, status: 401 },
        };
    });
  },
  register: (user) => {
    return fetch("/backend/user/register", {
      method: "POST",
      body: JSON.stringify(user),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => data);
  },
  logout: () => {
    return fetch("/backend/user/logout")
      .then((res) => res.json())
      .then((data) => data);
  },
  isAuthenticated: () => {
    return fetch("/backend/user/authenticated").then((res) => {
      if (res.status !== 401) return res.json().then((data) => data);
      else
        return {
          isAuthenticated: false,
          user: { email: "", role: "" },
          message: { msgBody: "Unauthorized", msgError: true, status: 401 },
        };
    });
  },
};
