import React, { useState, useContext, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Upload,
  Select,
  InputNumber,
  Switch,
  DatePicker,
  Row,
  Col,
  Typography,
  Divider,
} from "antd";
import TranslatableFormItem from "components/TranslatableFormItem";
import { UploadOutlined } from "@ant-design/icons";
import FoodService from "services/FoodService";
import { formatImgUrl } from "utils/helper";
import FoodCategoryService from "services/FoodCategoryService";
import FoodSubcategoryService from "services/FoodSubcategoryService";
import RestaurantService from "services/RestaurantService";
import CountryService from "services/CountryService";
import RemoveFileService from "services/RemoveFileService";
import { feedbackMessage } from "utils/helper";
import Layout from "layout";
import { AuthContext } from "context/AuthContext";
import moment from "moment";
import "../styles.scss";

const { Option } = Select;
const { TextArea } = Input;
const { Text } = Typography;
const { RangePicker } = DatePicker;

const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};
const tailLayout = {
  style: {
    textAlign: "center",
  },
};

const UpdateFood = () => {
  const { user = {} } = useContext(AuthContext);
  const [restaurants, setRestaurants] = useState([]);
  const [food, setFood] = useState([]);
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [countries, setCountries] = useState([]);
  const [selectedFoodData, setSelectedFoodData] = useState({});
  const [restaurantId, setRestaurantId] = useState();
  const [ingredients, setIngredients] = useState([]);
  const [keywords, setKeywords] = useState([]);
  const [isManager, setIsManager] = useState(false);
  const authContext = useContext(AuthContext);
  const [form] = Form.useForm();
  const formRef = React.createRef();

  const handleRemoveImg = (data) => {
    RemoveFileService.remove(data.response).then((res) => {
      feedbackMessage({
        status: res.message.status,
        message: res.message.msgBody,
      });
    });
  };

  const paramsFood = {
    where: JSON.stringify({
      restaurantId: restaurantId,
    }),
    sort: JSON.stringify({
      name: "asc",
    }),
  };
  const paramsCountry = {
    where: JSON.stringify({
      restaurantId: restaurantId,
    }),
    sort: JSON.stringify({
      name: "asc",
    }),
  };
  const generalParams = {
    sort: JSON.stringify({
      name: "asc",
    }),
  };

  const getRestaurantByUserType = () => {
    if (user.role === "manager") {
      const paramsUserManager = {
        where: JSON.stringify({
          _id: user.restaurantId,
        }),
        sort: JSON.stringify({
          name: "asc",
        }),
      };
      RestaurantService.get(paramsUserManager).then((data) => {
        setRestaurants(data.restaurants);
      });
    } else {
      const params = {
        sort: JSON.stringify({
          name: "asc",
        }),
      };
      RestaurantService.get(params).then((data) => {
        setRestaurants(data.restaurants);
      });
    }
  };

  useEffect(() => {
    setIsManager(user.role === "manager");
  }, []);

  useEffect(() => {
    getRestaurantByUserType();
    FoodService.get().then((data) => {
      setFood(data.food);
    });
    FoodCategoryService.get(generalParams).then((data) => {
      setCategories(data.categories);
    });
    FoodSubcategoryService.get(generalParams).then((data) => {
      setSubcategories(data.subcategories);
    });
    CountryService.get(paramsCountry).then((data) => {
      setCountries(data.countries);
    });
  }, []);

  useEffect(() => {
    FoodService.get(paramsFood).then((data) => {
      setFood(data.food);
    });
  }, [restaurantId]);

  function resetForm() {
    formRef.current.resetFields();
    FoodService.get(paramsFood).then((data) => {
      setFood(data.food);
    });
    setSelectedFoodData({});
  }

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleNewChange = (value) => {
    setSelectedFoodData({
      ...selectedFoodData,
      new: value,
    });
  };

  const handleFeaturedChange = (value) => {
    setSelectedFoodData({
      ...selectedFoodData,
      featured: value,
    });
  };

  const handleWeeklyChange = (value) => {
    setSelectedFoodData({
      ...selectedFoodData,
      weekly: value,
    });
  };

  const handleForKidsChange = (value) => {
    setSelectedFoodData({
      ...selectedFoodData,
      forKids: value,
    });
  };

  const handleDietChange = (value) => {
    setSelectedFoodData({
      ...selectedFoodData,
      diet: value,
    });
  };

  const handleVegetarianChange = (value) => {
    setSelectedFoodData({
      ...selectedFoodData,
      vegetarian: value,
    });
  };
  const handleDiscountAvailableChange = (value) => {
    setSelectedFoodData({
      ...selectedFoodData,
      isDiscountAvailable: value,
    });
  };

  const handleComboChange = (value) => {
    setSelectedFoodData({
      ...selectedFoodData,
      isCombo: value,
    });
  };

  const handleRestaourantChange = (value) => {
    setRestaurantId(value);
  };

  const handleFoodChange = (value) => {
    setLoading(true);
    if (value) {
      FoodService.getById(value).then((data) => {
        setLoading(false);
        const {
          countryId,
          image,
          categoryId,
          subcategoryId,
          restaurantId,
          discountStart,
          discountEnd,
          ...food
        } = data.food || {};
        setRestaurantId(restaurantId._id);
        setSelectedFoodData(food);
        form.setFieldsValue({
          countryId: countryId._id,
          image: [
            {
              uid: "-1",
              name: image?.split("/")[1],
              status: "done",
              url: formatImgUrl(image),
              response: image,
            },
          ],
          categoryId: categoryId._id,
          subcategoryId: subcategoryId._id,
          restaurantId: restaurantId._id,
          discountDuration: [
            discountStart && moment.utc(discountStart, "YYYY-MM-DD[T]HH:mm[Z]"),
            discountEnd && moment.utc(discountEnd, "YYYY-MM-DD[T]HH:mm[Z]"),
          ],
          ...food,
        });
      });
    } else {
      resetForm();
      setLoading(false);
    }
  };
  const onFinish = ({ image, discountDuration, ...values }) => {
    const updatedData = {
      image: image[0].response,
      discountStart: discountDuration && discountDuration[0],
      discountEnd: discountDuration && discountDuration[1],
      ...values,
    };
    FoodService.update({ food: updatedData, id: selectedFoodData._id }).then(
      (data) => {
        const { message } = data;
        if (!message.msgError) {
          feedbackMessage({ status: message.status, message: message.msgBody });
        } else if (message.msgBody === "UnAuthorized") {
          feedbackMessage({ status: message.status, message: message.msgBody });
          authContext.setUser({ email: "", role: "" });
          authContext.setIsAuthenticated(false);
        } else {
          feedbackMessage({ status: message.status, message: message.msgBody });
        }
      }
    );
  };
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  useEffect(() => {
    FoodService.getIngredients().then((data) => {
      setIngredients(data.ingredients);
    });
    FoodService.getKeywords().then((data) => {
      setKeywords(data.keywords);
    });
  }, []);

  const ingredientsChildren = [];
  for (let i = 0; i < ingredients.length; i++) {
    ingredientsChildren.push(
      <Option key={ingredients[i]}>{ingredients[i]}</Option>
    );
  }
  const keywordsChildren = [];
  for (let i = 0; i < keywords.length; i++) {
    keywordsChildren.push(<Option key={keywords[i]}>{keywords[i]}</Option>);
  }

  return (
    <>
      <Layout type="xs">
        <Row gutter={[16, 16]}>
          <Col sm={24}>
            <div className="selectLable">
              <Text>Select Restaurant</Text>
            </div>

            <Select
              size="large"
              showSearch
              style={{ width: 250 }}
              placeholder="Select restaurant name"
              onChange={handleRestaourantChange}
              allowClear
              onClear={() => resetForm()}
            >
              {restaurants.map((restaurant) => {
                return (
                  <Option key={restaurant["_id"]} value={restaurant["_id"]}>
                    {restaurant.name}
                  </Option>
                );
              })}
            </Select>
          </Col>
          {!!restaurantId && (
            <Col sm={24}>
              <div className="selectLable">
                <Text>Select Food</Text>
              </div>
              <Select
                size="large"
                style={{ width: 250 }}
                placeholder="Select food name"
                onChange={handleFoodChange}
                allowClear
              >
                {food.map((food) => {
                  return (
                    <Option key={food["_id"]} value={food["_id"]}>
                      {food.name}
                    </Option>
                  );
                })}
              </Select>
            </Col>
          )}
        </Row>
        <Divider />

        <Layout loading={loading} />
        <Form
          form={form}
          {...layout}
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          ref={formRef}
        >
          <Form.Item
            label="Select Restaurant Name"
            name="restaurantId"
            rules={[
              {
                required: true,
                message: "Please input restaurant name!",
              },
            ]}
          >
            <Select
              size="large"
              showSearch
              style={{ width: 200 }}
              placeholder="Select or search restaurant name"
              onChange={handleRestaourantChange}
            >
              {restaurants.map((restaurant) => {
                return (
                  <Option key={restaurant["_id"]} value={restaurant["_id"]}>
                    {restaurant.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <TranslatableFormItem
            label="Food Name"
            name="name"
            field={Input}
            fieldProps={{ size: "large" }}
            rules={[
              {
                required: true,
                message: "Please input food name!",
              },
            ]}
          />
          <Form.Item label="Combo" name="isCombo">
            <Switch
              checked={selectedFoodData.isCombo}
              onChange={handleComboChange}
            />
          </Form.Item>
          {!!selectedFoodData.isCombo && (
            <>
              <Form.Item label="Whats inside Combo" name="insideCombo">
                <Select
                  size="large"
                  mode="tags"
                  style={{ width: "100%" }}
                  placeholder="Tags Mode"
                >
                  {food.map((food) => {
                    return (
                      <Option key={food._id} value={food.name}>
                        {food.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </>
          )}
          <Form.Item
            label="Food Price"
            name="price"
            rules={[
              {
                required: true,
                message: "Please input food price!",
              },
            ]}
          >
            <InputNumber size="large" />
          </Form.Item>

          <Form.Item label="Is Discount Available" name="isDiscountAvailable">
            <Switch
              checked={selectedFoodData.isDiscountAvailable}
              onChange={handleDiscountAvailableChange}
            />
          </Form.Item>
          {selectedFoodData.isDiscountAvailable && (
            <>
              <Form.Item label="Discount Percent %" name="discountPercent">
                <InputNumber max={100} min={0} size="large" />
              </Form.Item>
              <Form.Item
                label="Start/End Date for Discount"
                name="discountDuration"
              >
                <RangePicker size="large" />
              </Form.Item>
            </>
          )}

          <Row>
            <Col span={8}>
              <Form.Item label="Featured" name="featured">
                <Switch
                  checked={selectedFoodData.featured}
                  onChange={handleFeaturedChange}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="For Kids" name="forKids">
                <Switch
                  checked={selectedFoodData.forKids}
                  onChange={handleForKidsChange}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Diet" name="diet">
                <Switch
                  checked={selectedFoodData.diet}
                  onChange={handleDietChange}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="New" name="new">
                <Switch
                  checked={selectedFoodData.new}
                  onChange={handleNewChange}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Vegetarian" name="vegetarian">
                <Switch
                  checked={selectedFoodData.vegetarian}
                  onChange={handleVegetarianChange}
                />
              </Form.Item>
            </Col>
            {!isManager && (
              <Col span={8}>
                <Form.Item label="Weekly" name="weekly">
                  <Switch
                    checked={selectedFoodData.weekly}
                    onChange={handleWeeklyChange}
                  />
                </Form.Item>
              </Col>
            )}
          </Row>
          <Form.Item
            label="Select Food Category"
            name="categoryId"
            rules={[
              {
                required: true,
                message: "Please select food category!",
              },
            ]}
          >
            <Select
              size="large"
              showSearch
              style={{ width: 200 }}
              placeholder="Select or search food category"
            >
              {categories.map((category) => {
                return (
                  <Option key={category["_id"]} value={category["_id"]}>
                    {category.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            label="Select Food Subcategory"
            name="subcategoryId"
            rules={[
              {
                required: true,
                message: "Please select food subcategory!",
              },
            ]}
          >
            <Select
              size="large"
              showSearch
              style={{ width: 200 }}
              placeholder="Select or search food subcategory"
            >
              {subcategories.map((subcategory) => {
                return (
                  <Option key={subcategory["_id"]} value={subcategory["_id"]}>
                    {subcategory.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            label="Select Food Country"
            name="countryId"
            rules={[
              {
                required: true,
                message: "Please input food country",
              },
            ]}
          >
            <Select
              size="large"
              showSearch
              style={{ width: 200 }}
              placeholder="Select or search food country"
            >
              {countries.map((country) => {
                return (
                  <Option key={country["_id"]} value={country["_id"]}>
                    {country.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            label="Upload"
            name="image"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            extra="text"
            rules={[
              {
                required: true,
                message: "Please upload image for food",
              },
            ]}
          >
            <Upload
              multiple={false}
              name="sampleFile"
              action="/backend/upload"
              listType="picture"
              maxCount={1}
              onRemove={handleRemoveImg}
            >
              <Button size="large" icon={<UploadOutlined />}>
                Click to upload
              </Button>
            </Upload>
          </Form.Item>
          <TranslatableFormItem
            label="Description of food"
            name="description"
            field={TextArea}
          />
          <Form.Item label="Whats Inside" name="whatsInside">
            <Select
              size="large"
              mode="tags"
              style={{ width: "100%" }}
              placeholder="Tags Mode"
            >
              {ingredientsChildren}
            </Select>
          </Form.Item>
          <Form.Item label="Keywords" name="keywords">
            <Select
              size="large"
              mode="tags"
              style={{ width: "100%" }}
              placeholder="Tags Mode"
            >
              {keywordsChildren}
            </Select>
          </Form.Item>
          {!isManager && (
            <Form.Item label="Food Order" name="foodOrder">
              <InputNumber size="large" />
            </Form.Item>
          )}

          <Form.Item {...tailLayout}>
            <Button size="large" type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Layout>
    </>
  );
};

export default UpdateFood;
