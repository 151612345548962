import { useState, useContext } from "react";
import FacebookLogin from "react-facebook-login";
import { FacebookIcon } from "../Icons/FacebookIcon";
import "./styles.scss";

const LoginWithFacbook = ({ onLogin }) => {
  const [autoLoad, setAutoLoad] = useState(false);
  const responseFacebook = async (response) => {
    const { name, email, id } = await response;
    const data = {
      email,
      fullName: name,
      provider: "facebook",
      facebookId: id,
    };
    onLogin(data);
  };
  function handleFacbookLoginClick() {
    setAutoLoad(true);
  }
  return (
    <FacebookLogin
      appId="124206433036359"
      autoLoad={autoLoad}
      fields="name,email,picture"
      callback={responseFacebook}
      icon={<FacebookIcon />}
      cssClass="my-facebook-button-class"
      isMobile={true}
      disableMobileRedirect={true}
      onClick={handleFacbookLoginClick}
      textButton=''
    />
  );
};

export default LoginWithFacbook;
