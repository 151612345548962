export default {
  remove: async (fileName) => {
    return fetch(
      "/backend/remove-file?fileName=" + encodeURIComponent(fileName),
      {
        method: "DELETE",
      }
    ).then((response) => {
      if (response.status !== 401) {
        return response.json().then((data) => data);
      } else return { message: { msgBody: "UnAuthorized" }, msgError: true };
    });
  },
};
