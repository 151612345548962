import { Skeleton } from "antd";
import "./styles.scss";

const FoodCardSkeleton = () => {
  return (
    <div className="hg-food-card-container-skeleton">
      <Skeleton.Image className="hg-image-wrapper-skeleton" />
      <div className="hg-content-wrapper-skeleton">
        <Skeleton avatar active paragraph={{ rows: 1 }} />
      </div>
    </div>
  );
};

export default FoodCardSkeleton;
