import { useState, useEffect, memo } from "react";
import { useHistory } from "react-router-dom";
import { useFieldsTranslation } from "utils/hooks";
import { formatImgUrl } from "utils/helper";
import RestaurantService from "services/RestaurantService";
import CuisineCardList from "components/CuisineCardList";
import AvatarWithText from "components/AvatarWithText";
import Wrapper from "components/Wrapper";
import Text from "components/Text";
import { Link } from "react-router-dom";

const CuisineSection = memo(() => {
  const [countries, setCountries] = useState([]);
  const history = useHistory();
  const { t, tField } = useFieldsTranslation();

  useEffect(() => {
    RestaurantService.getRestaurantsGroupByCountry().then((data) => {
      setCountries(data.data);
    });
  }, []);

  const handleCardClick = (country, countryId) => {
    history.push(`/cuisine/${country}/${countryId}`);
  };
  return (
    <>
      <Wrapper
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        pb={3}
      >
        <Text level={2} semiBold={true}>
          {t("combo.section.food.by.country")}
        </Text>
      </Wrapper>
      <CuisineCardList
        scrollX={true}
        loading={!countries?.length}
        data={countries?.map((item) => {
          return {
            key: item.country._id,
            originName: tField(item.country, "name"),
            title: tField(item.country, "name"),
            text: `${item.restaurant.placesCount} ${t("combo.section.places")}`,
            image: formatImgUrl(item.country.image),
            handleCardClick: () =>
              handleCardClick(item.country.name, item.country._id),
            extraContent: (
              <AvatarWithText
                src={formatImgUrl(item.restaurant.randomRestaurant.profileImg)}
                text={
                  <Link
                    onClick={(e) => e.stopPropagation()}
                    to={`restaurant/${item.restaurant.randomRestaurant.name.replace(
                      /\s/g,
                      ""
                    )}/${item.restaurant.randomRestaurant._id}`}
                  >
                    <Text
                      title={`${item.restaurant.randomRestaurant.name}`}
                      level={3}
                      rows={1}
                      textColor="white"
                    >
                      {`${tField(item.restaurant.randomRestaurant, "name")}`}
                    </Text>
                  </Link>
                }
              />
            ),
          };
        })}
      />
    </>
  );
});

export default CuisineSection;
