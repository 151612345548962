import { createContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
export const NavigationContext = createContext();

export default ({ children }) => {
  const location = useLocation();
  const [current, setCurrent] = useState({ current: location.pathname });
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setCurrent({ current: location.pathname });
  }, [location.pathname]);

  return (
    <NavigationContext.Provider
      value={{ current, setCurrent, visible, setVisible }}
    >
      {children}
    </NavigationContext.Provider>
  );
};
