import { useEffect, useState, useContext, memo } from "react";
import { message } from "antd";
import Layout from "layout";
import { formatImgUrl } from "utils/helper";
import { FavoritesContext } from "context/FavoritesContext";
import { useFieldsTranslation } from "utils/hooks";
import Wrapper from "components/Wrapper";
import FoodCardList from "components/FoodCardList";
import { RemoveIcon } from "components/Icons/RemoveIcon";
import Text from "components/Text";
import FoodService from "services/FoodService";
import FavoriteService from "services/FavoriteService";
import EmptyMessage from "./EmptyMessage";
import "./styles.scss";

const Favorites = memo(({ match }) => {
  const { favorites, user, setFavorites } = useContext(FavoritesContext);

  const { t, tField } = useFieldsTranslation();
  const [food, setFood] = useState([]);
  const [sum, setSum] = useState(0);
  const [loading, setLoading] = useState(true);

  const { params } = match;
  const conditionSubCategoryFood = {
    populate: JSON.stringify(["restaurantId"]),
    where: JSON.stringify({
      _id: { $in: favorites.map((item) => item.entityId) },
    }),
    sort: JSON.stringify({
      restaurantId: "asc",
    }),
  };

  async function removeAll() {
    const removedFavorites = await FavoriteService.remove({ userId: user.id });
    if (removedFavorites?.message.msgError) {
      message.warning(removedFavorites.message.msgBody);
    } else {
      setFavorites([]);
    }
  }

  useEffect(() => {
    // setLoading(true);
    if (favorites.length) {
      FoodService.get(conditionSubCategoryFood).then((data) => {
        setFood(data.food);
        setLoading(false);
      });
    }
  }, [favorites.length]);

  useEffect(() => {
    let sum = 0;
    food?.map((item) => {
      if (favorites.some((obj) => obj.entityId === item._id)) {
        sum +=
          item.price *
          favorites.filter((obj) => obj.entityId === item._id)[0]?.count;
      }
    });
    setSum(sum);
  }, [food, favorites]);

  return (
    <Layout type="sm">
      {!!favorites.length && (
        <Wrapper
          mb={3}
          mt={8}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Text semiBold level={2} rows={1}>
            {t("fav.favorites")}
          </Text>

          <Wrapper
            cursorPointer
            backgroundColor="light-50"
            py={7}
            px={5}
            display="flex"
            alignItems="center"
            borderRadius="base"
            onClick={() => removeAll()}
          >
            <Wrapper mr={7}>
              <Text rows={1} level={3} textColor="dark-0">
                {t("fav.clear.all")}
              </Text>
            </Wrapper>
            <RemoveIcon />
          </Wrapper>
        </Wrapper>
      )}
      {!!favorites.length ? (
        <>
          <FoodCardList
            loading={loading}
            food={food?.map((item) => {
              return {
                id: item._id,
                name: tField(item, "name"),
                image: formatImgUrl(item.image),
                price: item.price,
                rateData: {
                  feedbackType: "food",
                  entityId: item._id,
                  rate: item.rating,
                  maxRate: 5,
                },
                phone: item.restaurantId.phone,
                restaurantName: tField(item.restaurantId, "name"),
                restaurantIcon: formatImgUrl(item.restaurantId.icon),
                currency: t(item.currency),
                insideCombo: item.insideCombo,
                isCombo: item.isCombo,
                whatsInside: item.whatsInside,
                location: item.restaurantId.address[0].name,
                restaurantId: item.restaurantId._id,
              };
            })}
          />
        </>
      ) : (
        <div className="hg-favorites-empty-message ">
          <EmptyMessage />
        </div>
      )}
    </Layout>
  );
});

export default Favorites;
