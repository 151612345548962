import { useEffect, useState, memo, useCallback } from "react";
import { formatImgUrl } from "utils/helper";
import { useFieldsTranslation } from "utils/hooks";
import Wrapper from "components/Wrapper";
import Title from "components/Title";
import FoodCardList from "components/FoodCardList";
import FoodService from "services/FoodService";
import InfiniteScroll from "react-infinite-scroll-component";
import { Spin } from "antd";

const NearFoodList = memo(({ match }) => {
  const { t, tField } = useFieldsTranslation();
  const [food, setFood] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [dinamicDistance, setDinamicDistance] = useState(1000);

  const { params } = match;

  const fetchMoreData = useCallback(() => {
    const itemPerPage = 24;
    const conditionNearFood = {
      populate: JSON.stringify(["restaurantId"]),
      coordinates: JSON.stringify([Number(params.lat), Number(params.lng)]),
      maxDistance: dinamicDistance,
      where: JSON.stringify({
        featured: true,
      }),
      sort: JSON.stringify({
        foodOrder: "asc",
        name: "asc",
      }),
      skip: page * itemPerPage,
      limit: itemPerPage,
      total: true,
    };
    FoodService.getNear(conditionNearFood).then((data) => {
      if (!data.food.length && dinamicDistance < 100000) {
        setDinamicDistance(dinamicDistance + 1000);
      }
      setTotal(data.total);
      setFood((item) => [...item, ...data.food]);
    });
  }, [page, dinamicDistance]);

  useEffect(() => {
    fetchMoreData();
  }, [fetchMoreData]);

  return (
    <>
      <Wrapper mb={2}>
        <Wrapper
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          py={3}
        >
          <Title level={4} semiBold={true}>
            {t("near.me.page.food.near.me")}
          </Title>
        </Wrapper>
        <InfiniteScroll
          dataLength={food.length}
          next={() => setPage(page + 1)}
          hasMore={food.length < total}
          loader={
            <Wrapper display="flex" py={4} justifyContent="center">
              <Spin />
            </Wrapper>
          }
          style={{ overflow: "initial" }}
        >
          <FoodCardList
            scrollX={false}
            loading={!food?.length}
            food={food?.map((item) => {
              return {
                id: item._id,
                name: tField(item, "name"),
                image: formatImgUrl(item.image),
                price: item.price,
                rateData: {
                  feedbackType: "food",
                  entityId: item._id,
                  rate: item.rating,
                  maxRate: 5,
                },
                phone: item.restaurantId.phone,
                restaurantName: tField(item.restaurantId, "name"),
                restaurantIcon: formatImgUrl(item.restaurantId.icon),
                currency: t(item.currency),
                insideCombo: item.insideCombo,
                isCombo: item.isCombo,
                whatsInside: item.whatsInside,
                location: item.restaurantId.address[0].name,
                restaurantId: item.restaurantId._id,
              };
            })}
          />
        </InfiniteScroll>
      </Wrapper>
    </>
  );
});

export default NearFoodList;
