import Layout from "layout";
import Text from "components/Text";
import Title from "components/Title";
import Wrapper from "components/Wrapper";
import PartnerImg from "./images/partner-image.png";
import { Row, Col, Button } from "antd";
import "./styles.scss";

const colProps = {
  xs: 24,
  sm: 24,
  md: 24,
  lg: 12,
  xl: 12,
  xxl: 12,
  style: {
    marginBottom: "16px",
  },
};

const BecomeAPartner = () => {
  const handleBecomePartnerClick = () => {};
  return (
    <Layout type="xs">
      <div className="hg-become-partner-main-container">
        <div className="hg-become-partner-section-1">
          <Wrapper mb={3}>
            <Title bold titleColor="yellow" level={2}>
              This is a food offer
            </Title>
            <Title bold titleColor="mandarine" level={2}>
              and it is Free!
            </Title>
          </Wrapper>
          <Wrapper>
            <Text level={4}>untill December 2021</Text>
          </Wrapper>
        </div>
        <div className="hg-become-partner-section-2">
          <Wrapper mb={3}>
            <Title semiBold level={3}>
              Our Collaboration 🚀
            </Title>
          </Wrapper>
          <Wrapper mb={3}>
            <Text level={3}>
              We are live 🎉🚀 since September 20! Our relationship will never
              be client/company relation, it will be more friendly.
            </Text>
          </Wrapper>
          <Wrapper mb={3}>
            <Text level={3}>
              Our objective is to highlight on your best food and try to upsell
              by applying different kind of marketing methods that will be
              explained in the below sections.
            </Text>
          </Wrapper>
          <Wrapper mb={3}>
            <Text level={3}>
              We will be adding you as a premium food place and give you a full
              access to our features. By that we mean we will be displaying 20
              of your food.
            </Text>
          </Wrapper>
        </div>
        <div className="hg-become-partner-section-3">
          <Wrapper mb={3}>
            <Title semiBold level={3}>
              What will you benefit?
            </Title>
          </Wrapper>
          <Row gutter={[16, 16]}>
            <Col {...colProps}>
              <Wrapper mb={9}>
                <Text bold level={3}>
                  Community of food lovers 💛
                </Text>
              </Wrapper>
              <Text level={5}>
                The faster they decide what to eat the bigger is the probability
                is for them to come again.
              </Text>
            </Col>
            <Col {...colProps}>
              <Wrapper mb={9}>
                <Text bold level={3}>
                  Promote new food 🌭
                </Text>
              </Wrapper>
              <Text level={5}>
                Our tools will track the user behaivor and will be transparent
                with providing you with reports.
              </Text>
            </Col>
            <Col {...colProps}>
              <Wrapper mb={9}>
                <Text bold level={3}>
                  Targetted Ads 🎯
                </Text>
              </Wrapper>
              <Text level={5}>
                Our data is structured and easily navigated. All the foods are
                categorized in a smart way and navigation friendly.
              </Text>
            </Col>
            <Col {...colProps}>
              <Wrapper mb={9}>
                <Text bold level={3}>
                  Increase your sales 🚀
                </Text>
              </Wrapper>
              <Text level={5}>
                We create the bridge between the restaurant that sells burger
                and john that loves to explore new burger.
              </Text>
            </Col>
          </Row>
        </div>
        <div className="hg-become-partner-section-4">
          <div className="hg-partner-section-text-wrapper">
            <Title titleColor="white" bold level={3}>
              Suggesting good food anytime, anywhere!
            </Title>
          </div>
          <div className="hg-partner-section-img-wrapper">
            <img alt={"partner"} title={"partner"} src={PartnerImg} />
          </div>
        </div>
        <div className="hg-become-partner-section-5">
          <div className="hg-partner-section-text-wrapper">
            <Title level={2} bold titleColor="mandarine">
              What we offer to businesses?
            </Title>
          </div>
          <Row gutter={[16, 16]}>
            <Col {...colProps}>
              <Wrapper mb={9}>
                <Text bold level={3}>
                  Promoting Your Best food 🍕
                </Text>
              </Wrapper>
              <Text level={5}>
                People are interested in the best you can offer so let them see
                your tasty side.
              </Text>
            </Col>
            <Col {...colProps}>
              <Wrapper mb={9}>
                <Text bold level={3}>
                  Community of food lovers 💛
                </Text>
              </Wrapper>
              <Text level={5}>
                Our platform will be based on food lovers, people that are
                enthusiast about what they eat.
              </Text>
            </Col>
            <Col {...colProps}>
              <Wrapper mb={9}>
                <Text bold level={3}>
                  Food/User Analytics ⏱
                </Text>
              </Wrapper>
              <Text level={5}>
                The platform will give you structured insights about the
                relation between food & consumer.
              </Text>
            </Col>
            <Col {...colProps}>
              <Wrapper mb={9}>
                <Text bold level={3}>
                  Targetted Ads 🎯
                </Text>
              </Wrapper>
              <Text level={5}>
                Our data centric platform will provide the users with what they
                love.
              </Text>
            </Col>
          </Row>
        </div>
        <div className="hg-become-a-partner-card">
          <div className="hg-become-a-partner-card-text-wrapper">
            <Text textColor="primary">Become a partner!</Text>
            <Text level={4} textColor="dark-50">
            Chat with us and we will tell you the details 🚀😎
            </Text>
          </div>
          <div className="hg-become-a-partner-card-button-wrapper">
            <Button
              onClick={handleBecomePartnerClick}
              type="primary"
              size="large"
            >
              <a target="_blank" href="http://m.me/hungryguys.am">
              Chat with us
              </a>
            </Button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default BecomeAPartner;
