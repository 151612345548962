import "./styles.scss";

// export type Title = {
//   level?: 1 | 2 | 3 | 4,
//   bold?: boolean,
//   paddingBottom?: 1 | 2 | 3 | 4,
//   rows?: 1 | 2 | 3 | null,
//   alt?: string,
//   textColor?:
//     | "dark-100"
//     | "dark-50"
//     | "dark-30"
//     | "dark-20"
//     | "dark-10"
//     | "indicator-good"
//     | "indicator-caution"
//     | "indicator-bad"
//     | "indicator-neutral"
//     | "light"
//     | "white"
//     | "primary",
// };

const Title = ({
  children,
  level = 1,
  bold = false,
  semiBold = false,
  rows,
  titleColor = "dark-100",
  alt,
}) => {
  const classNames = ["hg-title", `hg-${level}`];

  if (bold) classNames.push("hg-bold");
  if (semiBold) classNames.push("hg-semiBold");
  if (titleColor) classNames.push(`hg-title-color-${titleColor}`);
  if (rows) classNames.push(`hg-title-row-${rows}`);

  const Tag = `h${level}`;

  return (
    <Tag className={classNames.join(" ")} title={alt}>
      {children}
    </Tag>
  );
};
export default Title;

// Title.propTypes = {
//   level: PropTypes.oneOf([1, 2, 3, 4]),
//   bold: PropTypes.bool,
//   rows: PropTypes.oneOf([1, 2, 3, null]),
//   alt: PropTypes.string,
//   titleColor: PropTypes.oneOf([
//     "dark-100",
//     "dark-50",
//     "dark-30",
//     "dark-20",
//     "dark-10",
//     "indicator-good",
//     "indicator-caution",
//     "indicator-bad",
//     "indicator-neutral",
//     "light",
//     "white",
//     "primary",
//   ]),
// };
