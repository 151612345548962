import Loading from "../Loading";
import "./styles.scss";

const Layout = ({ children, loading, type = "lg" }) => {
  if (loading) {
    return <Loading />;
  }
  return <div className={`${type}`}>{children}</div>;
};

export default Layout;
