import { useContext } from "react";
import { Row, Col } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import { resizeArray } from "utils/helper";
import FoodCardSkeleton from "../../components/Skeletons/FoodCardSkeleton";
import FoodCard from "../FoodCard";
import PropTypes from "prop-types";

const FoodCardList = ({
  food,
  loading,
  scrollX,
  skeletonCardCount = 9,
  scrollXCol = {
    xs: 22,
    sm: 14,
    md: 13,
    lg: 9,
    xl: 7,
    xxl: 7,
  },
  scrollYCol = {
    xs: 24,
    sm: 12,
    md: 12,
    lg: 8,
    xl: 8,
    xxl: 8,
  },
}) => {
  const history = useHistory();
  const location = useLocation();

  const handleCardClick = (data) => {
    history.push(
      `${location.pathname}${
        location.search ? `${location.search}&` : "?"
      }name=${encodeURIComponent(data.name.replace(/\s/g, ""))}&id=${data.id}`,
      {
        food: data,
        modal: true,
      }
    );
  };
  const handleRestaurantClick = (data, e) => {
    e.stopPropagation();
    history.replace(`/restaurant/${data.restaurantName}/${data.restaurantId}`);
  };

  const colProps = scrollX ? scrollXCol : scrollYCol;

  if (loading) {
    const arr = resizeArray(skeletonCardCount);
    return (
      <Row
        gutter={[8, 8]}
        style={{
          overflowX: "auto",
          flexFlow: scrollX ? "nowrap" : "wrap",
          paddingBottom: "16px",
        }}
      >
        {arr.map((item, index) => {
          return (
            <Col {...colProps} key={index}>
              <FoodCardSkeleton />
            </Col>
          );
        })}
      </Row>
    );
  }
  return (
    <Row
      gutter={[8, 8]}
      style={{
        overflowX: "auto",
        flexFlow: scrollX ? "nowrap" : "wrap",
        paddingBottom: "16px",
      }}
    >
      {food?.map((item) => {
        return (
          <Col {...colProps} key={item.id}>
            <FoodCard
              handleCardClick={() => handleCardClick(item)}
              handleRestaurantClick={(e) => handleRestaurantClick(item, e)}
              skeleton={loading}
              food={{
                id: item.id,
                name: item.name,
                image: item.image,
                price: item.price,
                rateData: item.rateData,
                phone: item.phone,
                restaurantName: item.restaurantName,
                restaurantIcon: item.restaurantIcon,
                currency: item.currency,
                insideCombo: item.insideCombo,
                isCombo: item.isCombo,
                whatsInside: item.whatsInside,
              }}
            />
          </Col>
        );
      })}
    </Row>
  );
};

FoodCardList.propTypes = {
  categories: PropTypes.array,
};

FoodCardList.defaultProps = {
  categories: [],
};

export default FoodCardList;
