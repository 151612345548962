import { memo } from "react";
import Layout from "layout";
import Wrapper from "components/Wrapper";
import MainBannerSection from "./Sections/MainBannerSection";
import MoreSuggestionsList from "./Sections/MoreSuggestionsList";

const Cuisine = memo(({ match }) => {
  return (
    <Layout type="sm">
      <MainBannerSection />
      <Wrapper mb={2}>
        <MoreSuggestionsList match={match} />
      </Wrapper>
    </Layout>
  );
});

export default Cuisine;
