import Icon from "@ant-design/icons";

const HomeSvg = () => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.7518 10.451L13.152 2.59665C12.9962 2.4694 12.8012 2.3999 12.6 2.3999C12.3988 2.3999 12.2038 2.4694 12.048 2.59665L2.44822 10.451C2.3594 10.5235 2.28572 10.6128 2.23141 10.7138C2.17709 10.8147 2.14319 10.9254 2.13164 11.0394C2.12009 11.1535 2.13112 11.2687 2.1641 11.3785C2.19708 11.4883 2.25137 11.5906 2.32385 11.6794C2.39634 11.7682 2.48562 11.8419 2.58658 11.8962C2.68754 11.9505 2.79821 11.9844 2.91227 11.996C3.02633 12.0075 3.14155 11.9965 3.25135 11.9635C3.36115 11.9305 3.46337 11.8762 3.55219 11.8037L4.74563 10.8263V18.9818C4.74563 19.6761 5.02146 20.3421 5.51246 20.8331C6.00345 21.3241 6.66938 21.5999 7.36376 21.5999H17.8363C18.5306 21.5999 19.1966 21.3241 19.6876 20.8331C20.1786 20.3421 20.4544 19.6761 20.4544 18.9818V10.8263L21.6478 11.8037C21.7367 11.8762 21.8389 11.9305 21.9487 11.9635C22.0585 11.9965 22.1737 12.0075 22.2878 11.996C22.4018 11.9844 22.5125 11.9505 22.6135 11.8962C22.7144 11.8419 22.8037 11.7682 22.8762 11.6794C22.9487 11.5906 23.003 11.4883 23.0359 11.3785C23.0689 11.2687 23.0799 11.1535 23.0684 11.0394C23.0568 10.9254 23.0229 10.8147 22.9686 10.7138C22.9143 10.6128 22.8406 10.5235 22.7518 10.451ZM10.8546 19.8545V15.4909C10.8546 15.2595 10.9465 15.0375 11.1102 14.8738C11.2739 14.7102 11.4958 14.6182 11.7273 14.6182H13.4727C13.7042 14.6182 13.9262 14.7102 14.0898 14.8738C14.2535 15.0375 14.3454 15.2595 14.3454 15.4909V19.8545H10.8546ZM18.709 18.9818C18.709 19.2132 18.617 19.4352 18.4534 19.5989C18.2897 19.7625 18.0677 19.8545 17.8363 19.8545H16.0909V15.4909C16.0909 14.7966 15.815 14.1306 15.324 13.6396C14.833 13.1486 14.1671 12.8728 13.4727 12.8728H11.7273C11.0329 12.8728 10.367 13.1486 9.87601 13.6396C9.38501 14.1306 9.10918 14.7966 9.10918 15.4909V19.8545H7.36376C7.1323 19.8545 6.91032 19.7625 6.74666 19.5989C6.58299 19.4352 6.49104 19.2132 6.49104 18.9818V9.39942L12.6 4.40097L18.709 9.39942V18.9818Z"
      fill="currentColor"
    />
  </svg>
);

export const HomeIcon = (props) => <Icon component={HomeSvg} {...props} />;
