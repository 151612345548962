import { useEffect, useState } from "react";
import FoodSubcategoryService from "services/FoodSubcategoryService";
import Wrapper from "components/Wrapper";
import Text from "components/Text";
import { Select } from "antd";
import { formatImgUrl } from "utils/helper";

import FoodSubcategoryForm from "./Form";
import Layout from "layout";

const { Option } = Select;

const UpdateSubcategory = () => {
  const [selected, setSelected] = useState();
  const [data, setData] = useState([]);
  useEffect(() => {
    FoodSubcategoryService.get().then((res) => {
      setData(res.subcategories);
    });
  }, []);

  const handleSubcategoryChange = (id) => {
    const item = data.find((item) => item._id === id);
    if (!item) {
      return;
    }
    setSelected({
      ...item,
      icon: [
        {
          uid: "-1",
          name: item.icon?.split("/")[1],
          status: "done",
          url: formatImgUrl(item.icon),
          response: item.icon,
        },
      ],
    });
  };

  const handleAfterSubmit = (updatedResult) => {
    setData(
      data.map((item) => {
        if (item._id === updatedResult._id) {
          return updatedResult;
        } else {
          return item;
        }
      })
    );
    setSelected(null);
  };
  return (
    <Layout type="xs">
      <Wrapper
        mb={5}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Select
          size="large"
          showSearch
          style={{ width: 200 }}
          placeholder="Select food subcategory  name"
          onChange={handleSubcategoryChange}
          value={selected?._id}
        >
          {data.map((item) => {
            return (
              <Option key={item._id} value={item._id}>
                {item.name}
              </Option>
            );
          })}
        </Select>
      </Wrapper>
      {selected && (
        <FoodSubcategoryForm
          initialValues={selected}
          afterSubmit={(updatedResult) => handleAfterSubmit(updatedResult)}
        />
      )}
    </Layout>
  );
};

export default UpdateSubcategory;
