import Icon from "@ant-design/icons";

const GreatRateEmojiSvg = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
      fill="#FFDC00"
    />
    <path
      d="M24.0004 11.9999C24.0004 18.6268 18.6273 23.9999 12.0004 23.9999C7.73773 23.9999 4.01236 21.7791 1.89893 18.4835C4.084 20.991 7.3437 22.5671 10.9258 22.5671C17.5527 22.5671 22.9258 17.194 22.9258 10.5671C22.9258 8.20294 22.2452 5.98204 21.0273 4.11935C22.89 6.19697 24.0004 8.991 24.0004 11.9999Z"
      fill="#FFB400"
    />
    <path
      d="M9.85086 8.20298L9.81504 8.2388L7.73742 11.2478C7.66578 11.3194 7.55832 11.3552 7.48668 11.2836L4.37026 9.34925C4.37026 9.34925 4.33444 9.34925 4.33444 9.31343C3.90459 9.02686 3.65384 8.66865 3.54638 8.27462C3.51056 8.20298 3.51056 8.09552 3.47474 8.02388C3.18817 6.34029 5.40907 5.37313 6.55534 6.66268L6.69862 6.80597L6.77026 6.59104C7.09265 5.55224 8.2031 5.19403 9.0628 5.44477C9.56429 5.58806 9.99414 5.98209 10.1374 6.59104C10.2449 7.2 10.2091 7.70149 9.85086 8.20298Z"
      fill="#F21B2D"
    />
    <path
      d="M9.85037 8.20296L9.81455 8.23878L7.73694 11.2477C7.6653 11.3194 7.55784 11.3552 7.4862 11.2836L4.36978 9.34923C4.36978 9.34923 4.33396 9.34922 4.33396 9.3134C3.90411 9.02684 3.65336 8.66863 3.5459 8.2746C4.33396 9.3134 6.48321 9.994 7.80858 9.27758C9.59963 8.31042 10.2086 6.51937 9.06231 5.51639C9.56381 5.65967 9.99366 6.0537 10.1369 6.66266C10.2444 7.19997 10.2086 7.70146 9.85037 8.20296Z"
      fill="#D91828"
    />
    <path
      d="M14.1492 8.27491L14.185 8.31073L16.2626 11.3197C16.3343 11.3913 16.4417 11.4272 16.5134 11.3555L19.6298 9.42118C19.6298 9.42118 19.6656 9.42118 19.6656 9.38536C20.0955 9.09879 20.3462 8.74058 20.4537 8.34655C20.4895 8.27491 20.4895 8.16745 20.5253 8.09581C20.8119 6.41223 18.591 5.44506 17.4447 6.73461L17.3014 6.8779L17.2298 6.66297C16.9074 5.62417 15.797 5.26596 14.9373 5.5167C14.4358 5.65999 14.0059 6.05402 13.8626 6.66297C13.7552 7.23611 13.791 7.77342 14.1492 8.27491Z"
      fill="#F21B2D"
    />
    <path
      d="M19.6298 9.42126L16.4776 11.3914C16.4059 11.4631 16.2626 11.4272 16.2268 11.3198C16.2268 11.3198 14.1492 8.275 14.1134 8.275C13.8268 7.84515 13.7552 7.41529 13.791 7.02126C14.1134 8.275 15.797 9.74365 17.3014 9.63619C19.3074 9.45709 20.597 8.06007 19.9522 6.66306C20.3462 7.02126 20.597 7.52276 20.4895 8.16753C20.4179 8.59738 20.1671 9.06306 19.6298 9.42126Z"
      fill="#D91828"
    />
    <path
      d="M7.70172 14.4001C7.70172 15.5822 6.77037 16.5136 5.58829 16.5136C4.4062 16.5136 3.47485 15.5822 3.47485 14.4001C3.47485 13.218 4.4062 12.2867 5.58829 12.2867C6.77037 12.2509 7.70172 13.218 7.70172 14.4001Z"
      fill="#FFAA00"
    />
    <path
      d="M18.4118 16.5134C19.579 16.5134 20.5252 15.5672 20.5252 14.4C20.5252 13.2328 19.579 12.2866 18.4118 12.2866C17.2446 12.2866 16.2983 13.2328 16.2983 14.4C16.2983 15.5672 17.2446 16.5134 18.4118 16.5134Z"
      fill="#FFAA00"
    />
    <path
      d="M15.9763 17.6597C14.8658 18.8777 13.5046 19.5941 12.036 19.5941C10.5673 19.5941 9.20612 18.8418 8.09567 17.6597C6.19716 15.6538 6.7703 14.5433 7.37925 14.7941C8.63298 15.9045 10.2449 16.9075 12.0001 16.9075C13.7554 16.9075 15.3315 15.9045 16.621 14.7941C16.621 14.7941 16.621 14.7941 16.6569 14.7582C17.23 14.2926 17.7315 15.5463 15.9763 17.6597Z"
      fill="#401800"
    />
  </svg>
);

export const GreatRateEmoji = (props) => (
  <Icon component={GreatRateEmojiSvg} {...props} />
);
