import Icon from "@ant-design/icons";
import { MessageOutlined } from "@ant-design/icons";

const HeartSvg = () => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.4001 20.1417C12.2218 20.1417 12.0438 20.0848 11.8941 19.9714C11.8553 19.942 7.96271 16.9688 4.44279 13.4485C2.25247 11.2583 2.25247 7.69417 4.44279 5.50385C5.50329 4.44293 6.91389 3.8584 8.41446 3.8584C9.91504 3.8584 11.3256 4.44272 12.3866 5.50385C12.3909 5.50824 12.3955 5.51283 12.3999 5.51721C12.4043 5.51283 12.4089 5.50824 12.4133 5.50385C13.4748 4.44272 14.8856 3.8584 16.3856 3.8584C17.8862 3.8584 19.2968 4.44272 20.3577 5.50385C22.5476 7.69417 22.5476 11.2581 20.3573 13.4485C16.8371 16.9688 12.9448 19.942 12.9062 19.9714C12.7565 20.0848 12.5782 20.1417 12.4001 20.1417ZM8.41446 5.52849C7.36001 5.52849 6.36923 5.93912 5.62396 6.6846C4.87785 7.4303 4.46763 8.42149 4.46763 9.47594C4.46763 10.5304 4.87785 11.5214 5.62354 12.2675C8.32282 14.9659 11.2482 17.3389 12.4001 18.2481C13.5517 17.3389 16.4774 14.9657 19.1767 12.2675C20.7159 10.7281 20.7159 8.2238 19.1771 6.6846C18.4314 5.93912 17.4402 5.52849 16.3858 5.52849C15.3313 5.52849 14.3401 5.93912 13.5944 6.68481C13.4097 6.86957 13.2419 7.07478 13.0953 7.2946C12.9408 7.52737 12.6799 7.66724 12.4001 7.66724C12.1208 7.66724 11.8599 7.52737 11.705 7.2946C11.5586 7.07478 11.391 6.86957 11.2058 6.6846C10.4605 5.93912 9.46892 5.52849 8.41446 5.52849Z"
      fill="currentColor"
    />
  </svg>
);

export const HeartIcon = (props) => <Icon component={HeartSvg} {...props} />;
