import Icon from "@ant-design/icons";

const AverageRateEmojiSvg = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#FFD93B"
      />
      <path
        d="M24.0001 12C24.0001 18.63 18.6263 24 12.0001 24C8.22756 24 4.86006 22.26 2.65881 19.5338C4.71756 21.1988 7.33881 22.1925 10.1926 22.1925C16.8188 22.1925 22.1926 16.8225 22.1926 10.1925C22.1926 7.33875 21.1988 4.7175 19.5338 2.65875C22.2563 4.86 24.0001 8.2275 24.0001 12Z"
        fill="#F4C534"
      />
      <path
        d="M10.2893 8.65948C10.8721 11.415 8.97681 14.034 6.95031 13.9987C4.92606 13.9867 3.55656 12.2955 3.29256 10.6725C3.06681 9.03523 3.97206 7.23148 5.67381 6.13048C7.34106 5.03923 9.70056 5.90023 10.2893 8.65948Z"
        fill="white"
      />
      <path
        d="M6.59697 8.02051C7.47972 7.55102 8.62646 8.23951 8.93171 9.73651C9.23771 11.2238 8.46296 12.669 7.46697 12.8153C6.46272 12.9683 5.66472 12.0368 5.46597 10.8735C5.27547 9.69976 5.70147 8.49526 6.59697 8.02051Z"
        fill="#3E4347"
      />
      <path
        d="M10.2907 8.66024C9.96374 7.11449 8.10824 6.87374 6.29849 7.73474C4.47599 8.60474 3.16124 9.75824 3.29174 10.6732C3.06674 9.03599 3.97199 7.23074 5.67299 6.12824C7.34024 5.03774 9.70199 5.90024 10.2907 8.66024Z"
        fill="#65B1EF"
      />
      <path
        d="M10.1295 8.20355C10.1325 8.2133 10.011 8.0573 9.81522 7.9403C9.61197 7.81205 9.31422 7.72955 8.96397 7.7138C8.26047 7.67705 7.34847 7.9433 6.46722 8.2928C6.24222 8.3843 6.01947 8.4818 5.81547 8.5748C5.60322 8.6738 5.39472 8.7728 5.19672 8.8778C4.79622 9.0773 4.44147 9.29705 4.14672 9.49731C3.85572 9.70206 3.62847 9.89706 3.49422 10.0651C3.35997 10.2308 3.30222 10.3516 3.30222 10.3531C3.30972 10.3546 3.28047 10.2226 3.33147 9.98331C3.36222 9.86556 3.40647 9.72456 3.48447 9.5723C3.56247 9.42155 3.65847 9.2513 3.78747 9.0863C4.03422 8.74505 4.37697 8.3903 4.77447 8.0588C4.97397 7.8938 5.18622 7.7333 5.41197 7.58855C5.64297 7.4333 5.87697 7.30955 6.11022 7.18655C7.06722 6.70955 8.16597 6.55055 8.99397 6.9383C9.19872 7.0208 9.38322 7.15205 9.53772 7.2833C9.69372 7.41455 9.81522 7.57355 9.90672 7.70855C10.0897 8.0093 10.119 8.2058 10.1295 8.20355Z"
        fill="#5A5F63"
      />
      <path
        d="M13.0619 8.66027C12.4792 11.4158 14.3744 14.0348 16.4009 13.9995C18.4252 13.9875 19.7947 12.2963 20.0587 10.6733C20.2844 9.03602 19.3792 7.23227 17.6774 6.13127C16.0094 5.04003 13.6507 5.90103 13.0619 8.66027Z"
        fill="white"
      />
      <path
        d="M16.5278 8.14197C15.645 7.67247 14.4983 8.36098 14.193 9.85798C13.8878 11.3452 14.6618 12.7905 15.6578 12.9367C16.662 13.0897 17.46 12.1582 17.6588 10.995C17.8485 9.82198 17.4233 8.61673 16.5278 8.14197Z"
        fill="#3E4347"
      />
      <path
        d="M16.1078 10.0418C16.1761 10.4715 16.4896 10.8165 16.7896 10.8038C17.0903 10.7925 17.2681 10.4513 17.1991 10.041C17.1301 9.6308 16.8428 9.29555 16.5413 9.2843C16.2406 9.2753 16.0388 9.61055 16.1078 10.0418Z"
        fill="#5A5F63"
      />
      <path
        d="M13.0598 8.66097C13.3868 7.11448 15.2423 6.87447 17.0521 7.73547C18.8746 8.60547 20.1893 9.75898 20.0588 10.674C20.2846 9.03673 19.3786 7.23147 17.6776 6.12897C16.0111 5.03847 13.6486 5.90097 13.0598 8.66097Z"
        fill="#65B1EF"
      />
      <path
        d="M13.2218 8.20422C13.2196 8.21397 13.3403 8.05797 13.5361 7.94097C13.7393 7.81272 14.0371 7.73022 14.3873 7.71447C15.0908 7.67772 16.0028 7.94397 16.8841 8.29347C17.1091 8.38497 17.3318 8.48247 17.5358 8.57547C17.7481 8.67447 17.9566 8.77347 18.1546 8.87847C18.5551 9.07797 18.9098 9.29772 19.2046 9.49797C19.4956 9.70272 19.7228 9.89772 19.8578 10.0657C19.9913 10.2315 20.0498 10.3522 20.0498 10.3537C20.0423 10.3552 20.0716 10.2232 20.0206 9.98397C19.9898 9.86622 19.9456 9.72522 19.8676 9.57297C19.7896 9.42222 19.6936 9.25197 19.5646 9.08697C19.3178 8.74572 18.9751 8.39097 18.5776 8.05947C18.3781 7.89447 18.1658 7.73397 17.9401 7.58922C17.7091 7.43397 17.4751 7.31022 17.2418 7.18722C16.2848 6.71022 15.1861 6.55122 14.3581 6.93897C14.1533 7.02147 13.9688 7.15272 13.8143 7.28397C13.6583 7.41522 13.5368 7.57422 13.4453 7.70922C13.2608 8.00997 13.2323 8.20647 13.2218 8.20422Z"
        fill="#5A5F63"
      />
      <path
        d="M20.5013 6.09074C19.5525 5.40599 18.516 4.83974 17.4443 4.47074C17.0438 4.35149 16.644 4.23749 16.2623 4.19774C15.7575 4.11149 14.727 4.14974 14.0978 3.92698C14.9438 2.96548 16.5128 3.27898 17.724 3.74173C18.9585 4.26299 20.0865 5.34074 20.5013 6.09074Z"
        fill="#3E4347"
      />
      <path
        d="M3.26172 5.33776C3.80322 4.63051 5.08497 3.75076 6.35547 3.42451C7.60347 3.15076 9.19422 3.02701 9.91497 4.14376C9.91497 4.14376 9.77847 4.17001 9.57222 4.17676C8.43297 4.23301 8.29047 3.93526 6.52497 4.18726C5.35122 4.40476 4.14222 4.88251 3.26172 5.33776Z"
        fill="#3E4347"
      />
      <path
        d="M16.386 19.9335C15.549 20.1383 13.7842 18.273 11.5035 18.432C9.24 18.264 7.4565 20.1293 6.621 19.9335C5.54475 19.8368 6.78825 15.1755 11.5035 15.1238C16.2495 15.189 17.4555 19.8435 16.386 19.9335Z"
        fill="#3E4347"
      />
      <path
        d="M7.2975 9.77624C7.36575 10.206 7.67925 10.551 7.97925 10.539C8.28 10.5277 8.45775 10.1865 8.38875 9.77624C8.31975 9.36599 8.0325 9.03074 7.731 9.01949C7.43025 9.00974 7.2285 9.34499 7.2975 9.77624Z"
        fill="#5A5F63"
      />
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#FFD93B"
      />
      <path
        d="M24.0001 12C24.0001 18.63 18.6263 24 12.0001 24C8.22756 24 4.86006 22.26 2.65881 19.5338C4.71756 21.1988 7.33881 22.1925 10.1926 22.1925C16.8188 22.1925 22.1926 16.8225 22.1926 10.1925C22.1926 7.33875 21.1988 4.7175 19.5338 2.65875C22.2563 4.86 24.0001 8.2275 24.0001 12Z"
        fill="#F4C534"
      />
      <path
        d="M10.7648 9.72524C10.7648 12.0742 9.28279 13.9845 7.43929 13.9845C5.60629 13.9845 4.11304 12.075 4.11304 9.72524C4.11304 7.37624 5.60554 5.46674 7.43929 5.46674C9.28354 5.46674 10.7648 7.37699 10.7648 9.72524Z"
        fill="#F4C534"
      />
      <path
        d="M10.0958 9.45079C10.0958 11.3168 8.91007 12.8423 7.43932 12.8423C5.97907 12.8423 4.79407 11.3168 4.79407 9.45079C4.79407 7.57429 5.97982 6.04803 7.43932 6.04803C8.91007 6.04878 10.0958 7.57429 10.0958 9.45079Z"
        fill="white"
      />
      <path
        d="M8.35031 8.48703C7.57648 8.32049 6.77947 8.97395 6.57014 9.94658C6.36081 10.9192 6.81843 11.8427 7.59226 12.0092C8.36609 12.1758 9.1631 11.5223 9.37243 10.5497C9.58176 9.57706 9.12415 8.65358 8.35031 8.48703Z"
        fill="#3E4347"
      />
      <path
        d="M8.87324 9.84525C8.75024 9.91125 8.55899 9.7845 8.44199 9.5655C8.32499 9.3465 8.32874 9.1065 8.44874 9.05175C8.57174 8.98575 8.76599 9.102 8.88299 9.321C8.99699 9.5505 8.99624 9.78 8.87324 9.84525Z"
        fill="#5A5F63"
      />
      <path
        d="M12.5437 9.72524C12.5437 12.0742 14.0257 13.9845 15.8692 13.9845C17.7022 13.9845 19.1954 12.075 19.1954 9.72524C19.1954 7.37624 17.703 5.46674 15.8692 5.46674C14.0257 5.46674 12.5437 7.37699 12.5437 9.72524Z"
        fill="#F4C534"
      />
      <path
        d="M13.2135 9.45079C13.2135 11.3168 14.3993 12.8423 15.87 12.8423C17.3303 12.8423 18.5153 11.3168 18.5153 9.45079C18.5153 7.57429 17.3295 6.04803 15.87 6.04803C14.3993 6.04878 13.2135 7.57429 13.2135 9.45079Z"
        fill="white"
      />
      <path
        d="M16.7385 9.94688C16.5291 8.97425 15.7321 8.32079 14.9583 8.48734C14.1844 8.65388 13.7268 9.57737 13.9362 10.55C14.1455 11.5226 14.9425 12.1761 15.7163 12.0095C16.4902 11.843 16.9478 10.9195 16.7385 9.94688Z"
        fill="#3E4347"
      />
      <path
        d="M14.436 9.84525C14.559 9.91125 14.7502 9.7845 14.8672 9.5655C14.9842 9.3465 14.9805 9.1065 14.8605 9.05175C14.7375 8.98575 14.5432 9.102 14.4262 9.321C14.3122 9.5505 14.3122 9.78 14.436 9.84525Z"
        fill="#5A5F63"
      />
      <path
        d="M14.9543 1.88774C14.9543 1.88774 18.0218 2.72624 19.4348 5.58449C19.4348 5.58374 18.0173 -0.223512 14.9543 1.88774Z"
        fill="#3E4347"
      />
      <path
        d="M9.32331 1.99346C9.32331 1.99346 6.19881 2.58746 4.56531 5.32421C4.56531 5.32496 6.43656 -0.352542 9.32331 1.99346Z"
        fill="#3E4347"
      />
      <path
        d="M17.1939 17.9534C17.2929 22.5022 13.8279 20.8582 9.75615 19.5262C6.83265 18.5699 5.39265 20.5207 5.4249 17.9527C5.39115 15.3877 6.8319 17.3392 9.75615 16.3807C13.8279 15.0494 17.2936 13.3964 17.1939 17.9534Z"
        fill="#3E4347"
      />
      <path
        d="M16.8548 15.9398C15.8985 14.0438 13.0245 15.3128 9.75605 16.3807C8.20805 16.8885 7.08005 16.5832 6.36155 16.5615C6.35405 16.8742 6.39905 17.3595 6.8198 17.5702C7.40255 17.862 12.8228 17.2957 14.5373 16.7812C15.639 16.4505 16.4348 16.3275 16.8548 15.9398Z"
        fill="white"
      />
      <path
        d="M16.9358 19.7753C16.3231 19.218 14.7796 19.5585 13.9546 19.3103C12.9908 19.02 7.67782 18.2895 7.05982 18.6503C6.79207 18.8063 6.68707 19.0748 6.64807 19.329C7.36732 19.2623 8.39932 19.0823 9.75532 19.5255C13.1266 20.628 16.0741 21.9338 16.9358 19.7753Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const AverageRateEmoji = (props) => (
  <Icon component={AverageRateEmojiSvg} {...props} />
);
