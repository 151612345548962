import { useEffect, useState } from "react";
import Cron from "services/CronService";
import { feedbackMessage } from "utils/helper";
import Wrapper from "components/Wrapper";
import Text from "components/Text";
import { Button, Switch, Form, Spin } from "antd";

const AutoShuffleSection = () => {
  const [sections, setSections] = useState({});
  const [loading, setLoading] = useState(true);
  const [allowSubmit, setAllowSubmit] = useState(false);

  const onFinish = (values) => {
    setAllowSubmit(true);
    Cron.changeCronStatus(values).then((res) => {
      const { message } = res;
      feedbackMessage({
        status: message.status,
        message: message.msgBody,
      });
      setAllowSubmit(false);
    });
  };

  useEffect(() => {
    const params = {
      where: JSON.stringify({
        category: "updateHomePageSections",
      }),
    };
    Cron.get(params)
      .then((result) => {
        const sections = {};
        for (const item of result.crons) {
          sections[item.name] = item.isActive;
        }
        setSections(sections);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <>
      <Wrapper mb={4}>
        <Text level={2}>Update home page sections automatically</Text>
      </Wrapper>
      {loading ? (
        <Spin spinning />
      ) : (
        <Form initialValues={{ ...sections }} onFinish={onFinish}>
          <Form.Item valuePropName="checked" label="Weekly" name="weekly">
            <Switch />
          </Form.Item>
          <Form.Item valuePropName="checked" label="new" name="new">
            <Switch />
          </Form.Item>
          <Form.Item valuePropName="checked" label="diet" name="diet">
            <Switch />
          </Form.Item>
          <Form.Item valuePropName="checked" label="isCombo" name="isCombo">
            <Switch />
          </Form.Item>
          <Form.Item>
            <Button disabled={allowSubmit} type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      )}
    </>
  );
};

export default AutoShuffleSection;
