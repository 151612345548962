import { useEffect, useState, useCallback } from "react";
import { formatImgUrl } from "utils/helper";
import { useFieldsTranslation } from "utils/hooks";
import Wrapper from "components/Wrapper";
import Title from "components/Title";
import FoodCardList from "components/FoodCardList";
import FoodService from "services/FoodService";
import InfiniteScroll from "react-infinite-scroll-component";
import { Spin } from "antd";

const TopRatedFoodList = () => {
  const { t, tField } = useFieldsTranslation();
  const [food, setFood] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);

  const fetchMoreData = useCallback(() => {
    const itemPerPage = 24;
    const conditionMoreSuggestions = {
      populate: JSON.stringify(["restaurantId"]),
      sort: JSON.stringify({
        rating: "-1",
        ratersCount: "-1",
        name: "1",
      }),
      skip: page * itemPerPage,
      limit: itemPerPage,
      total: true,
    };
    FoodService.get(conditionMoreSuggestions).then((data) => {
      setTotal(data.total);
      setFood((item) => [...item, ...data.food]);
    });
  }, [page]);

  useEffect(() => {
    fetchMoreData();
  }, [fetchMoreData]);

  return (
    <>
      <Wrapper mb={2}>
        <Wrapper
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          py={3}
        >
          <Title level={4} semiBold={true}>
            {t("top.rated.food.list.title")}
          </Title>
        </Wrapper>
        <InfiniteScroll
          dataLength={food.length}
          next={() => setPage(page + 1)}
          hasMore={food.length < total}
          loader={
            <Wrapper display="flex" py={4} justifyContent="center">
              <Spin />
            </Wrapper>
          }
          style={{ overflow: "initial" }}
        >
          <FoodCardList
            scrollX={false}
            loading={!food?.length}
            food={food?.map((item) => {
              return {
                id: item._id,
                name: tField(item, "name"),
                image: formatImgUrl(item.image),
                price: item.price,
                rateData: {
                  feedbackType: "food",
                  entityId: item._id,
                  rate: item.rating,
                  maxRate: 5,
                },
                phone: item.restaurantId.phone,
                restaurantName: tField(item.restaurantId, "name"),
                restaurantIcon: formatImgUrl(item.restaurantId.icon),
                currency: t(item.currency),
                insideCombo: item.insideCombo,
                isCombo: item.isCombo,
                whatsInside: item.whatsInside,
                location: item.restaurantId.address[0].name,
                restaurantId: item.restaurantId._id,
              };
            })}
          />
        </InfiniteScroll>
      </Wrapper>
    </>
  );
};

export default TopRatedFoodList;
