import { message as antdMessage } from "antd";

export function feedbackMessage({ status, message }) {
  if (status === 200 || status === 201) {
    return antdMessage.success(`${message}`);
  }
  if (status === 201) {
    return antdMessage.success(`${message}`);
  }
  if (status === 406) {
    return antdMessage.warning(`${message}`);
  }
  if (status === 400) {
    return antdMessage.warning(`${message}`);
  }
  if (status === 403) {
    return antdMessage.error(`${message}`);
  }
  if (status === 500) {
    return antdMessage.error(`${message}`);
  }
  if (status === 401) {
    return antdMessage.info(`${message}`);
  }
}

export function discountedPrice({ orginalPrice, percent, start, end }) {
  const dateNow = Date.now();
  const startDate = new Date(start);
  const endDate = new Date(end);
  if (startDate > endDate && endDate > dateNow) {
    return orginalPrice - (orginalPrice * percent) / 100;
  }
}

export function resizeArray(size, defaultValue = 1) {
  return Array(size).fill(defaultValue);
}

export function queryStringToJson(query) {
  try {
    return JSON.parse(
      '{"' +
        decodeURI(query.substring(1))
          .replace(/"/g, '\\"')
          .replace(/&/g, '","')
          .replace(/=/g, '":"') +
        '"}'
    );
  } catch (_err) {
    return {};
  }
}

export function formatImgUrl(name) {
  return `${process.env.REACT_APP_BACKEND_BASE_URL}/${name}`;
}

export function getInitials(string = '') {
  var names = string.split(" "),
    initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
}
