import Icon from "@ant-design/icons";

const HungryguysFooterIconSvg = () => (
  <svg
    width="242"
    height="43"
    viewBox="0 0 242 43"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.0794 7.87185C10.0313 7.87185 9.03319 8.01945 8.03505 8.26545V3.98512C8.03505 1.77116 6.23839 0 4.04248 0C1.79666 0 0 1.77116 0 3.98512V28.8307C0 30.9954 1.79666 32.7666 4.04248 32.7666C6.23839 32.7666 8.03505 30.9954 8.03505 28.8307V18.794C8.03505 17.1213 9.43245 15.7437 11.0794 15.7437C12.7762 15.7437 14.1736 17.1213 14.1736 18.794V28.8307C14.1736 30.9954 15.9703 32.7666 18.1662 32.7666C20.3621 32.7666 22.1588 30.9954 22.1588 28.8307V18.794C22.1588 12.7426 17.218 7.87185 11.0794 7.87185Z"
      fill="#FB5B4E"
    />
    <path
      d="M35.9343 33.1602C29.8456 33.1602 24.9048 28.2895 24.9048 22.2872V12.0538C24.9048 9.93821 26.6516 8.16705 28.8974 8.16705C31.0933 8.16705 32.8401 9.93821 32.8401 12.0538V22.2872C32.8401 23.96 34.2375 25.3375 35.9343 25.3375C37.6312 25.3375 39.0286 23.96 39.0286 22.2872V12.0538C39.0286 9.93821 40.7753 8.16705 42.9712 8.16705C45.217 8.16705 46.9638 9.93821 46.9638 12.0538V22.2872C46.9638 28.2895 42.023 33.1602 35.9343 33.1602Z"
      fill="#FB5B4E"
    />
    <path
      d="M61.4228 7.92105C59.6761 7.92105 58.0291 8.31464 56.5818 9.00343C55.933 8.51144 55.0846 8.16705 54.1863 8.16705C52.0902 8.16705 50.4433 9.83982 50.4433 11.9062V28.8799C50.4433 30.9954 52.19 32.7666 54.3859 32.7666C56.5818 32.7666 58.3286 30.9954 58.3286 28.8799V18.7449C58.3286 17.0721 59.726 15.7437 61.4228 15.7437C63.1197 15.7437 64.4672 17.0721 64.4672 18.7449V28.8799C64.4672 30.9954 66.2638 32.7666 68.4597 32.7666C70.6557 32.7666 72.4024 30.9954 72.4024 28.8799V18.7449C72.4024 12.7918 67.5115 7.92105 61.4228 7.92105Z"
      fill="#FB5B4E"
    />
    <path
      d="M97.0947 8.01945C95.897 8.01945 94.799 8.56064 94.1003 9.44622C92.3536 8.51144 90.3074 7.97025 88.1614 7.97025C81.0746 7.97025 75.3851 13.6281 75.3851 20.5652C75.3851 27.5023 81.0746 33.111 88.1614 33.1602C89.8582 33.1602 91.5051 32.8158 93.0024 32.2254C91.8046 35.2757 88.4608 36.1613 86.0653 35.0297C84.1688 34.1442 81.8731 34.9314 80.9747 36.8501C80.1263 38.7197 80.9248 40.9336 82.8213 41.8192C84.5182 42.6064 86.3148 43 88.2113 43C95.1983 43 100.938 37.3913 100.938 30.5034V11.7586C100.938 9.69222 99.2408 8.01945 97.0947 8.01945ZM88.1614 25.2391C85.5163 25.2391 83.3703 23.1728 83.3703 20.5652C83.3703 17.9577 85.5163 15.8421 88.1614 15.8421C90.7565 15.8421 92.9025 17.9577 92.9025 20.5652C92.9025 23.1728 90.7565 25.2391 88.1614 25.2391Z"
      fill="#FB5B4E"
    />
    <path
      d="M116.46 8.01945C115.462 8.01945 113.216 8.06865 111.17 9.10183C110.471 8.51144 109.623 8.16705 108.675 8.16705C106.579 8.16705 104.932 9.83982 104.932 11.857V28.8799C104.932 31.0446 106.678 32.7666 108.874 32.7666C111.07 32.7666 112.817 31.0446 112.817 28.8799V18.8432C112.817 17.1705 114.264 15.8913 116.011 15.8421C118.207 15.7929 120.104 14.2677 120.203 12.103C120.303 9.93822 118.656 8.06865 116.46 8.01945Z"
      fill="#FB5B4E"
    />
    <path
      d="M143.277 8.36384C141.181 7.47826 138.935 8.51144 138.037 10.4794L134.643 18.7449L131.199 10.4794C130.401 8.51144 128.055 7.52746 126.009 8.36384C124.013 9.20023 123.014 11.4634 123.813 13.4805L130.9 30.405L130.151 32.127C129.303 34.095 127.406 35.2757 125.21 35.2265C123.314 35.2265 121.717 36.7025 121.717 38.6213C121.667 41.1304 123.863 42.1144 125.36 42.1144C129.552 42.1144 133.345 39.9497 135.391 36.8993C136.34 35.4725 137.038 33.7998 137.737 32.0778L145.423 13.4805C146.271 11.4634 145.323 9.20023 143.277 8.36384Z"
      fill="#FB5B4E"
    />
    <path
      d="M168.934 8.01945C167.736 8.01945 166.638 8.56064 165.939 9.44622C164.193 8.51144 162.146 7.97025 160 7.97025C152.914 7.97025 147.224 13.6281 147.224 20.5652C147.224 27.5023 152.914 33.111 160 33.1602C161.697 33.1602 163.344 32.8158 164.841 32.2254C163.644 35.2757 160.3 36.1613 157.904 35.0297C156.008 34.1442 153.712 34.9314 152.814 36.8501C151.965 38.7197 152.764 40.9336 154.66 41.8192C156.357 42.6064 158.154 43 160.05 43C167.037 43 172.777 37.3913 172.777 30.5034V11.7586C172.777 9.69222 171.08 8.01945 168.934 8.01945ZM160 25.2391C157.355 25.2391 155.209 23.1728 155.209 20.5652C155.209 17.9577 157.355 15.8421 160 15.8421C162.596 15.8421 164.742 17.9577 164.742 20.5652C164.742 23.1728 162.596 25.2391 160 25.2391Z"
      fill="#FB5B4E"
    />
    <path
      d="M187.8 33.1602C181.712 33.1602 176.771 28.2895 176.771 22.2872V12.0538C176.771 9.93821 178.517 8.16705 180.763 8.16705C182.959 8.16705 184.706 9.93821 184.706 12.0538V22.2872C184.706 23.96 186.103 25.3375 187.8 25.3375C189.497 25.3375 190.894 23.96 190.894 22.2872V12.0538C190.894 9.93821 192.641 8.16705 194.837 8.16705C197.083 8.16705 198.83 9.93821 198.83 12.0538V22.2872C198.83 28.2895 193.889 33.1602 187.8 33.1602Z"
      fill="#FB5B4E"
    />
    <path
      d="M221.354 8.36384C219.258 7.47826 217.012 8.51144 216.114 10.4794L212.72 18.7449L209.277 10.4794C208.478 8.51144 206.133 7.52746 204.086 8.36384C202.09 9.20023 201.092 11.4634 201.89 13.4805L208.977 30.405L208.229 32.127C207.38 34.095 205.484 35.2757 203.288 35.2265C201.391 35.2265 199.794 36.7025 199.794 38.6213C199.744 41.1304 201.94 42.1144 203.438 42.1144C207.63 42.1144 211.423 39.9497 213.469 36.8993C214.417 35.4725 215.116 33.7998 215.814 32.0778L223.5 13.4805C224.349 11.4634 223.4 9.20023 221.354 8.36384Z"
      fill="#FB5B4E"
    />
    <path
      d="M236.107 14.6613C235.159 14.3661 233.761 14.0217 233.512 15.1041C233.262 16.0881 235.059 16.5801 235.807 16.7277C237.305 17.0229 238.752 17.8593 239.8 18.9908C242.096 21.3524 242.595 25.0915 241.297 28.0435C239.95 31.0938 236.855 32.9142 233.562 33.111C231.715 33.2094 227.872 32.7174 226.325 30.7002C224.379 28.1419 226.275 24.5503 229.419 24.6979C230.667 24.7471 232.014 25.6819 233.062 25.7311C234.859 25.8787 235.258 23.714 233.562 23.2712C230.417 22.3364 228.122 21.1064 226.674 18.2529C225.626 16.0881 225.876 13.3821 227.373 11.4142C229.769 8.21625 234.31 7.13387 238.053 8.56064C240.049 9.34783 241.696 11.365 240.449 13.5297C239.55 15.0057 238.003 15.3009 236.107 14.6613Z"
      fill="#FB5B4E"
    />
  </svg>
);

export const HungryguysFooterIcon = (props) => (
  <Icon component={HungryguysFooterIconSvg} {...props} />
);
