import FoodCategoryForm from "./Form";
import Layout from "layout";

const CreateCategory = () => {
  return (
    <Layout type="xs">
      <FoodCategoryForm />
    </Layout>
  );
};

export default CreateCategory;
