import { useContext, useState, useEffect } from "react";
import AuthService from "services/AuthService";
import { Form, Input, Button, Divider } from "antd";
import { feedbackMessage } from "utils/helper";
import LoginWithFacbook from "components/LoginWithFacebook";
import LoginWithGoogle from "components/LoginWithGoogle";
import Layout from "layout";
import { AuthContext } from "context/AuthContext";
import { useFieldsTranslation } from "utils/hooks";
import Wrapper from "components/Wrapper";
import Text from "components/Text";

const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};
const tailLayout = {
  style: {
    textAlign: "center",
  },
};

const Login = (props) => {
  const authContext = useContext(AuthContext);
  const { t } = useFieldsTranslation();
  const [loading, setLoading] = useState(false);

  const onFinish = (value) => {
    const localLoginLoadin =
      value.provider !== "facebook" && value.provider !== "google";
    setLoading(localLoginLoadin);
    AuthService.login(value).then((data) => {
      const { isAuthenticated, user, message } = data;
      if (isAuthenticated) {
        authContext.setUser(user);
        authContext.setIsAuthenticated(isAuthenticated);
        props.history.push("/");
        setLoading(false);
      } else
        feedbackMessage({ status: message.status, message: message.msgBody });
      setLoading(false);
    });
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onLoginFacebook = (user) => {
    onFinish(user);
  };
  const onLoginGoogle = (user) => {
    onFinish(user);
  };

  const onLocalFinish = (value) => {
    const user = {
      email: value.email,
      password: value.password,
      provider: "local",
    };

    onFinish(user);
  };

  return (
    <Layout type="xs">
      <div className="loginContainer">
        <Wrapper mb={3}>
          <Wrapper mb={7} mt={4}>
            {" "}
            <Text bold level={1}>
              Sign in
            </Text>
          </Wrapper>
          <Text level={4} rows={3}>
            You will be able to rate your favorite food & benefit from all the
            upcoming features!
          </Text>
        </Wrapper>
        <Form
          {...layout}
          name="basic"
          onFinish={onLocalFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label={
              <Text level={4} textColor="dark-90" bold>
                {t("login.email")}
              </Text>
            }
            name="email"
            rules={[
              {
                required: true,
                message: t("login.email.valid.message"),
              },
            ]}
          >
            <Input size="large" placeholder="Enter Email" />
          </Form.Item>
          <Form.Item
            label={
              <Text level={4} textColor="dark-90" bold>
                {t("login.password")}
              </Text>
            }
            name="password"
            rules={[
              {
                required: true,
                message: t("login.password.valid.message"),
              },
            ]}
          >
            <Input.Password
              size="large"
              placeholder={t("login.password.placeholder")}
              type="password"
            />
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Button
              loading={loading}
              size="large"
              type="primary"
              htmlType="submit"
              style={{ width: "100%" }}
            >
              {t("login.sign.in")}
            </Button>
          </Form.Item>
        </Form>
        <Divider />
        <Wrapper
          mb={5}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Text textColor="dark-90" level={4}>
            {t("login.sign.up.versions")}
          </Text>
          <Wrapper
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Wrapper mx={8}>
              <LoginWithFacbook onLogin={onLoginFacebook} />
            </Wrapper>
            <Wrapper>
              <LoginWithGoogle onLogin={onLoginGoogle} />
            </Wrapper>
          </Wrapper>
        </Wrapper>
      </div>
    </Layout>
  );
};

export default Login;
