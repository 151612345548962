import Icon from "@ant-design/icons";

const FacebookIconSvg = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="0.5" width="31" height="31" rx="7.5" stroke="#F2F2F4" />
    <g clipPath="url(#clip0_232:2358)">
      <path
        d="M18.5002 9.33332H21.0002C21.2303 9.33332 21.4169 9.14676 21.4169 8.91664V6.41664C21.4169 6.18656 21.2303 6 21.0002 6H18.5002C15.97 6.00277 13.9196 8.05316 13.9169 10.5833V13.5H11.0002C10.7701 13.5 10.5835 13.6866 10.5835 13.9167V16.4167C10.5835 16.6468 10.7701 16.8334 11.0002 16.8334H13.9169V25.5834C13.9169 25.8135 14.1034 26 14.3335 26H16.8335C17.0637 26 17.2502 25.8135 17.2502 25.5834V16.8334H20.1669C20.3462 16.8332 20.5053 16.7184 20.5619 16.5484L21.3952 14.0484C21.4679 13.83 21.3499 13.5941 21.1316 13.5214C21.0892 13.5073 21.0449 13.5 21.0002 13.5H17.2502V10.5833C17.2502 9.89297 17.8098 9.33332 18.5002 9.33332Z"
        fill="#2196F3"
      />
    </g>
    <defs>
      <clipPath id="clip0_232:2358">
        <rect width="20" height="20" fill="white" transform="translate(6 6)" />
      </clipPath>
    </defs>
  </svg>
);

export const FacebookIcon = (props) => (
  <Icon component={FacebookIconSvg} {...props} />
);
