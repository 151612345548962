export default {
  add: async (food) => {
    return fetch("/backend/food", {
      method: "POST",
      body: JSON.stringify(food),
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      if (response.status !== 401) {
        return response.json().then((data) => data);
      } else return { message: { msgBody: "UnAuthorized" }, msgError: true };
    });
  },
  get: async (params) => {
    return fetch("/backend/food?" + new URLSearchParams(params)).then(
      (response) => {
        if (response.status !== 401) {
          return response.json().then((data) => data);
        } else return { message: { msgBody: "UnAuthorized" }, msgError: true };
      }
    );
  },
  getById: async (id) => {
    return fetch(`/backend/food/${id}`).then((response) => {
      if (response.status !== 401) {
        return response.json().then((data) => data);
      } else return { message: { msgBody: "UnAuthorized" }, msgError: true };
    });
  },

  update: async ({ food, id }) => {
    return fetch(`/backend/food/${id}`, {
      method: "PATCH",
      body: JSON.stringify(food),
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      if (response.status !== 401) {
        return response.json().then((data) => data);
      } else return { message: { msgBody: "UnAuthorized" }, msgError: true };
    });
  },
  getNear: async (params) => {
    return fetch("/backend/food/get/near?" + new URLSearchParams(params)).then(
      (response) => {
        if (response.status !== 401) {
          return response.json().then((data) => data);
        } else return { message: { msgBody: "UnAuthorized" }, msgError: true };
      }
    );
  },
  getIngredients: async () => {
    return fetch("/backend/food/get/ingredients").then((response) => {
      if (response.status !== 401) {
        return response.json().then((data) => data);
      } else return { message: { msgBody: "UnAuthorized" }, msgError: true };
    });
  },
  getKeywords: async () => {
    return fetch("/backend/food/get/keywords").then((response) => {
      if (response.status !== 401) {
        return response.json().then((data) => data);
      } else return { message: { msgBody: "UnAuthorized" }, msgError: true };
    });
  },
  getCount: async () => {
    return fetch("/backend/food/get/count").then((response) => {
      if (response.status !== 401) {
        return response.json().then((data) => data);
      } else return { message: { msgBody: "UnAuthorized" }, msgError: true };
    });
  },

  shuffleSection: async (field) => {
    return fetch("/backend/food/shuffle-section", {
      method: "POST",
      body: JSON.stringify(field),
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      if (response.status !== 401) {
        return response.json().then((data) => data);
      } else return { message: { msgBody: "UnAuthorized" }, msgError: true };
    });
  },
};
