import { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import Loading from "../Loading";

const PrivateRoute = ({ component: Component, roles, ...rest }) => {
  const { isAuthenticated, user, loading } = useContext(AuthContext);
  if (loading) {
    return <Loading />;
  }
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isAuthenticated)
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          );
        // es pahy knayes hly inchvor bani hamar petqa exel vor grel
        //enq bayc inqy xndir er arajacnum ejy refresh aneluc tanum er
        // home pag dra hamar AuthContext-i mej ira initial valun dacrel em tru araj fals er
        if (!roles.includes(user.role))
          return (
            <Redirect to={{ pathname: "/", state: { from: props.location } }} />
          );
        return <Component {...props} />;
      }}
    />
  );
};

export default PrivateRoute;
