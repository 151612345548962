import { Skeleton } from "antd";
import "./styles.scss";

const AdsBannerSkeleton = () => {
  return (
    <div className={"hg-ads-banner-skeleton"}>
      <div className="hg-ads-banner-content-skeleton">
        <Skeleton active paragraph={{ rows: 3 }} />
      </div>
      <div className="hg-ads-banner-img-wrapper-skeleton">
        <Skeleton.Image className="hg-image-wrapper-skeleton" />
      </div>
    </div>
  );
};

export default AdsBannerSkeleton;
