import { useEffect, useState } from "react";
import CountryService from "services/CountryService";
import Wrapper from "components/Wrapper";
import { Select } from "antd";
import { formatImgUrl } from "utils/helper";
import CountryForm from "./Form";
import Layout from "layout";

const { Option } = Select;

const UpdateCountry = () => {
  const [selected, setSelected] = useState();
  const [data, setData] = useState([]);
  useEffect(() => {
    CountryService.get().then((res) => {
      setData(res.countries);
    });
  }, []);

  const handleCountryChange = (id) => {
    const item = data.find((item) => item._id === id);
    if (!item) {
      return;
    }
    setSelected({
      ...item,
      image: [
        {
          uid: "-1",
          name: item.image?.split("/")[1],
          status: "done",
          url: formatImgUrl(item.image),
          response: item.image,
        },
      ],
    });
  };

  const handleAfterSubmit = (updatedResult) => {
    setData(
      data.map((item) => {
        console.log(item, "item");
        if (item._id === updatedResult._id) {
          return updatedResult;
        } else {
          return item;
        }
      })
    );
    setSelected(null);
  };
  return (
    <Layout type="xs">
      <Wrapper
        mb={5}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Select
          size="large"
          showSearch
          style={{ width: 200 }}
          placeholder="Select country name"
          onChange={handleCountryChange}
          value={selected?._id}
        >
          {data.map((item) => {
            return (
              <Option key={item._id} value={item._id}>
                {item.name}
              </Option>
            );
          })}
        </Select>
      </Wrapper>
      {selected && (
        <CountryForm
          initialValues={selected}
          afterSubmit={(updatedResult) => handleAfterSubmit(updatedResult)}
        />
      )}
    </Layout>
  );
};

export default UpdateCountry;
