export default {
  add: async (favoriteData) => {
    return fetch("/backend/favorite", {
      method: "POST",
      body: JSON.stringify(favoriteData),
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      if (response.status !== 401) {
        return response.json().then((data) => data);
      } else return { message: { msgBody: "UnAuthorized" }, msgError: true };
    });
  },

  get: async (params) => {
    return fetch("/backend/favorite?" + new URLSearchParams(params)).then(
      (response) => {
        if (response.status !== 401) {
          return response.json().then((data) => data);
        } else return { message: { msgBody: "UnAuthorized" }, msgError: true };
      }
    );
  },

  update: async ({ favoriteData, id }) => {
    return fetch(`/backend/favorite/${id}`, {
      method: "PATCH",
      body: JSON.stringify(favoriteData),
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      if (response.status !== 401) {
        return response.json().then((data) => data);
      } else return { message: { msgBody: "UnAuthorized" }, msgError: true };
    });
  },

  remove: async ({ entityId, userId }) => {
    return fetch(`/backend/favorite`, {
      method: "DELETE",
      body: JSON.stringify({ userId, entityId }),
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      if (response.status !== 401) {
        return response.json().then((data) => data);
      } else return { message: { msgBody: "UnAuthorized" }, msgError: true };
    });
  },
};
