import { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useLocation,
} from "react-router-dom";
import Analytics from "../src/hocs/Analytics";
import Navbar from "../src/components/Navbar";
import Footer from "../src/components/Footer";
import Login from "pages/login/Login";
import Home from "pages/public/Home";
import SubCategory from "pages/public/SubCategory";
import Cuisine from "pages/public/Cuisine";
import MoreSuggestions from "pages/public/MoreSuggestions";
import TopRatedFood from "pages/public/TopRatedFood";
import Restaurant from "pages/public/Restaurant";
import FindNearMe from "pages/public/FindNearMe";
import Favorites from "pages/public/Favorites";
import About from "pages/public/About";
import BecomeAPartner from "pages/public/BecomeAPartner";
import Register from "pages/public/Register";
import Restaurants from "pages/public/Restaurants";
import CreateRestaurant from "pages/admin/Restaurant/CreateRestaurant";
import CreateRestaurantCategory from "pages/admin/RestCategory/Create";
import UpdateRestaurantCategory from "pages/admin/RestCategory/Update";
import CreateCountry from "pages/admin/Country/Create";
import UpdateCountry from "pages/admin/Country/Update";
import CreateFoodCategory from "pages/admin/FoodCategory/Create";
import UpdateFoodCategory from "pages/admin/FoodCategory/Update";
import CreateFoodSubcategory from "pages/admin/FoodSubcategory/Create";
import UpdateFoodSubcategory from "pages/admin/FoodSubcategory/Update";
import CreateFood from "pages/admin/Food/CreateFood";
import UpdateFood from "pages/admin/Food/UpdateFood";
import AdminDashboard from "pages/admin/AdminDashboard";
import WhatInsideModal from "./components/WhatInsideModal";
import PrivateRoute from "hocs/PrivateRoute";
import UnPrivateRoute from "hocs/UnPrivateRoute";
import Container from "../src/container";
import NavigationProvider from "context/NavigationContext";
import NavigationBarMobile from "./components/NavigationBarMobile";
import Layout from "../src/layout";
import "antd/dist/antd.css";
import "./App.less";

function App() {
  const hideFooter = ["/restaurants"].includes(window.location.pathname);
  const isMenuMode = window.location.pathname.startsWith("/menu");

  return (
    <div className="hg-page-container">
      <Router>
        {/* <Analytics> */}
        <NavigationProvider>
          <Navbar />
          {!isMenuMode && <NavigationBarMobile />}
        </NavigationProvider>
        <RoutesContainer />
        <WhatInsideModal />
        {!hideFooter && (
          <div className="footer">
            <Layout type="sm">
              <Footer />
            </Layout>
          </div>
        )}
        {/* </Analytics> */}
      </Router>
    </div>
  );
}

const RoutesContainer = (props) => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <Container>
      <Switch>
        <Route exact path="/" component={Home} />
        <UnPrivateRoute path="/login" component={Login} />
        <UnPrivateRoute path="/register" component={Register} />
        <Route path="/subcategory/:name/:id" component={SubCategory} />
        <Route path="/more-suggestions/:name" component={MoreSuggestions} />
        <Route path="/near-me/:lat/:lng" component={FindNearMe} />
        <Route path="/cuisine/:name/:id" component={Cuisine} />
        <Route path="/menu/:id" component={Restaurant} />
        <Route path="/restaurant/:name/:id" component={Restaurant} />
        {/* <Route path="/restaurants" component={Restaurants} /> */}
        <Route path="/top-rated-food" component={TopRatedFood} />
        <Route path="/favorites" component={Favorites} />
        <Route path="/about" component={About} />
        <Route path="/become-a-partner" component={BecomeAPartner} />

        <PrivateRoute
          path="/restaurants"
          roles={["admin"]}
          component={Restaurants}
        />
        <PrivateRoute
          path="/createRestaurant"
          roles={["admin"]}
          component={CreateRestaurant}
        />
        <PrivateRoute
          path="/updateRestaurant/:id"
          roles={["admin", "manager"]}
          component={CreateRestaurant}
        />
        <PrivateRoute
          path="/createFood"
          roles={["admin", "manager"]}
          component={CreateFood}
        />
        {/* erb petq lini karanq poxenq u urlic kardanq es karanq anenq en
        jamanal er cardi vra uzena adminy click ani u
        edit ani linkov miangamic
        (tes vonca editeRestoranty arac) */}
        {/* <PrivateRoute
          path="/editFood/:id"
          roles={["admin", "manager"]}
          component={UpdateFood}
        /> */}
        <PrivateRoute
          path="/updateFood"
          roles={["admin", "manager"]}
          component={UpdateFood}
        />

        <PrivateRoute
          path="/createRestaurantCategory"
          roles={["admin"]}
          component={CreateRestaurantCategory}
        />
        <PrivateRoute
          path="/updateRestaurantCategory"
          roles={["admin"]}
          component={UpdateRestaurantCategory}
        />
        <PrivateRoute
          path="/createCountry"
          roles={["admin"]}
          component={CreateCountry}
        />
        <PrivateRoute
          path="/updateCountry"
          roles={["admin"]}
          component={UpdateCountry}
        />
        <PrivateRoute
          path="/createFoodCategory"
          roles={["admin"]}
          component={CreateFoodCategory}
        />
        <PrivateRoute
          path="/updateFoodCategory"
          roles={["admin"]}
          component={UpdateFoodCategory}
        />
        <PrivateRoute
          path="/createFoodSubcategory"
          roles={["admin"]}
          component={CreateFoodSubcategory}
        />
        <PrivateRoute
          path="/updateFoodSubcategory"
          roles={["admin"]}
          component={UpdateFoodSubcategory}
        />
        <PrivateRoute
          path="/admin-dashboard"
          roles={["admin"]}
          component={AdminDashboard}
        />
      </Switch>
    </Container>
  );
};

export default App;
