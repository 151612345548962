import React, { useState, useContext, useRef, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Upload,
  Select,
  Switch,
  TimePicker,
  InputNumber,
} from "antd";
import { YMaps, Map, SearchControl, Placemark } from "react-yandex-maps";
import { formatImgUrl } from "utils/helper";
import { UploadOutlined } from "@ant-design/icons";
import TranslatableFormItem from "components/TranslatableFormItem";
import RestaurantService from "services/RestaurantService";
import FoodService from "services/FoodService";
import RemoveFileService from "services/RemoveFileService";
import RestaurantCategoryService from "services/RestaurantCategoryService";
import CountryService from "services/CountryService";
import { feedbackMessage } from "utils/helper";
import { AuthContext } from "context/AuthContext";
import { useHistory } from "react-router-dom";
import Layout from "layout";
import moment from "moment";
import Loading from "../../../Loading";

const { TextArea } = Input;
const { Option } = Select;
const prefix = <span>+374</span>;

const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};
const tailLayout = {
  style: {
    textAlign: "center",
  },
};
const generalParams = {
  sort: JSON.stringify({
    name: "asc",
  }),
};

const CreateRestaurant = ({ match }) => {
  const authContext = useContext(AuthContext);
  const [form] = Form.useForm();
  const formRef = React.createRef();
  const [loading, setLoading] = useState(true);
  const [countries, setCountries] = useState([]);
  const [categories, setCategories] = useState([]);
  const [currentLocation, setCurrentLocation] = useState([40.1772, 44.50349]);
  const [searchControl, setSearchControl] = useState(null);
  const [hasDelivery, setHasDelivery] = useState(true);
  const [isTwentyFourBySeven, setIsTwentyFourBySeven] = useState(false);
  const [keywords, setKeywords] = useState([]);
  const [locationLoading, setLocationLoading] = useState(false);
  const history = useHistory();

  const [updatedRest, setUpdatedRest] = useState({});

  useEffect(() => {
    if (!match.params.id) {
      setLoading(false);
    } else {
      RestaurantService.getById(match.params.id)
        .then((data) => {
          setIsTwentyFourBySeven(data.restaurant.twentyFourBySeven);
          data.restaurant.openHour = moment(data.restaurant.openingHours?.open);
          data.restaurant.phone = data.restaurant.phone.slice(4);
          data.restaurant.closedHour = moment(
            data.restaurant.openingHours?.closed
          );
          data.restaurant.profileImg = [
            {
              uid: "-1",
              name: data.restaurant.profileImg?.split("/")[1],
              status: "done",
              url: formatImgUrl(data.restaurant.profileImg),
              response: data.restaurant.profileImg,
            },
          ];
          data.restaurant.icon = [
            {
              uid: "-1",
              name: data.restaurant.icon?.split("/")[1],
              status: "done",
              url: formatImgUrl(data.restaurant.icon),
              response: data.restaurant.icon,
            },
          ];
          data.restaurant.menuImages = data.restaurant.menuImages.map(
            (imgName) => {
              return {
                name: imgName?.split("/")[1],
                status: "done",
                url: formatImgUrl(imgName),
                response: imgName,
              };
            }
          );
          setUpdatedRest(data.restaurant);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  function resetForm() {
    formRef.current && formRef.current.resetFields();
    setHasDelivery(false);
    setIsTwentyFourBySeven(false);
  }

  useEffect(() => {
    RestaurantCategoryService.get(generalParams).then((data) => {
      setCategories(data.categories);
    });
  }, []);

  useEffect(() => {
    CountryService.get(generalParams).then((data) => {
      setCountries(data.countries);
    });
  }, []);

  const onFinish = ({
    profileImg,
    icon,
    menuImages,
    phone,
    delivery,
    twentyFourBySeven,
    openingHours,
    openHour,
    closedHour,
    ...values
  }) => {
    form.setFieldsValue({
      delivery: delivery || hasDelivery,
    });
    const restaurantData = {
      profileImg: profileImg[0].response,
      icon: icon[0].response,
      menuImages: menuImages && menuImages.map((image) => image.response),
      phone: `+374${phone}`,
      delivery: delivery || hasDelivery,
      twentyFourBySeven: twentyFourBySeven || isTwentyFourBySeven,
      openingHours: !isTwentyFourBySeven
        ? {
            open: openHour,
            closed: closedHour,
          }
        : {
            open: "",
            closed: "",
          },
      ...values,
    };

    if (updatedRest._id) {
      restaurantData._id = updatedRest._id;
      RestaurantService.update({ id: updatedRest._id, restaurantData }).then(
        (data) => {
          const { message } = data;
          if (!message.msgError) {
            feedbackMessage({
              status: message.status,
              message: message.msgBody,
            });
            history.goBack();
            // resetForm();
          } else if (message.msgBody === "UnAuthorized") {
            feedbackMessage({
              status: message.status,
              message: message.msgBody,
            });
            authContext.setUser({ email: "", role: "" });
            authContext.setIsAuthenticated(false);
          } else {
            feedbackMessage({
              status: message.status,
              message: message.msgBody,
            });
            history.goBack();
          }
        }
      );
    } else {
      RestaurantService.add(restaurantData).then((data) => {
        const { message } = data;
        if (!message.msgError) {
          feedbackMessage({
            status: message.status,
            message: message.msgBody,
          });
          resetForm();
        } else if (message.msgBody === "UnAuthorized") {
          feedbackMessage({
            status: message.status,
            message: message.msgBody,
          });
          authContext.setUser({ email: "", role: "" });
          authContext.setIsAuthenticated(false);
        } else {
          feedbackMessage({
            status: message.status,
            message: message.msgBody,
          });
        }
      });
    }
  };
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onResultShow = () => {
    if (searchControl) {
      form.setFieldsValue({
        address: [
          {
            name: searchControl.getResultsArray()[0].properties._data.text,
            geometry: searchControl.getResultsArray()[0].properties._data
              .boundedBy[
              searchControl.getResultsArray()[0].properties._data.boundedBy
                .length - 1
            ],
          },
        ],
      });
    }
  };
  useEffect(() => {
    if (currentLocation.length) {
      searchControl?.search(currentLocation);
    }
  }, [currentLocation]);

  const getCurrentLocation = () => {
    setLocationLoading(true);
    navigator.geolocation.getCurrentPosition(
      (success) => {
        setCurrentLocation([success.coords.latitude, success.coords.longitude]);
        setLocationLoading(false);
      },
      () => {
        setLocationLoading(false);
      },
      {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      }
    );
    searchControl?.search(currentLocation);
  };

  const handleDeliveryChange = (value) => {
    setHasDelivery(value);
  };

  const handleTwentyFourBySevenChange = (value) => {
    setIsTwentyFourBySeven(value);
  };

  useEffect(() => {
    FoodService.getKeywords().then((data) => {
      setKeywords(data.keywords);
    });
  }, []);

  const keywordsChildren = [];
  for (let i = 0; i < keywords.length; i++) {
    keywordsChildren.push(<Option key={keywords[i]}>{keywords[i]}</Option>);
  }

  const handleRemoveImg = (data) => {
    RemoveFileService.remove(data.response).then((res) => {
      feedbackMessage({
        status: res.message.status,
        message: res.message.msgBody,
      });
    });
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <Layout type="xs">
      <Form
        form={form}
        {...layout}
        initialValues={{ ...updatedRest }}
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        ref={formRef}
      >
        <TranslatableFormItem
          label="Restaurant Name"
          name="name"
          field={Input}
          fieldProps={{ size: "large" }}
          rules={[
            {
              required: true,
              message: "Please input your restaurant name!",
            },
          ]}
        />
        <Form.Item
          label="Profile Image"
          name="profileImg"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          extra="text"
          rules={[
            {
              required: true,
              message: "Please upload restaurant profile image!",
            },
          ]}
        >
          <Upload
            multiple={false}
            name="sampleFile"
            action="/backend/upload"
            listType="picture"
            maxCount={1}
            onRemove={handleRemoveImg}
          >
            <Button size="large" size="large" icon={<UploadOutlined />}>
              Click to upload
            </Button>
          </Upload>
        </Form.Item>
        <Form.Item
          label="Icon"
          name="icon"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          extra="text"
          rules={[
            {
              required: true,
              message: "Please upload restaurant icon!",
            },
          ]}
        >
          <Upload
            multiple={false}
            name="sampleFile"
            action="/backend/upload"
            listType="picture"
            maxCount={1}
            onRemove={handleRemoveImg}
          >
            <Button size="large" size="large" icon={<UploadOutlined />}>
              Click to upload
            </Button>
          </Upload>
        </Form.Item>
        <Form.Item
          label="Menu Images"
          name="menuImages"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          extra="text"
        >
          <Upload
            multiple={false}
            name="sampleFile"
            action="/backend/upload"
            listType="picture"
            onRemove={handleRemoveImg}
          >
            <Button size="large" icon={<UploadOutlined />}>
              Click to upload
            </Button>
          </Upload>
        </Form.Item>
        <Form.Item
          label="Address"
          name="address"
          rules={[
            {
              required: true,
              message: "Please input your address!",
            },
          ]}
        >
          <YMaps
            enterprise
            query={{
              //if this not work try this my own apiKey 02747649-263b-4e25-93ff-9c480141f252
              apikey: "ac684a05-e3c8-4777-a071-fcc67be621b8",
              lang: "en_RU",
            }}
          >
            <Map
              defaultOptions
              defaultState={{
                center: currentLocation,
                zoom: 10,
              }}
            >
              <Button
                loading={locationLoading}
                size="large"
                onClick={getCurrentLocation}
              >
                Current Location
              </Button>
              <SearchControl
                defaultData={currentLocation}
                instanceRef={(ref) => setSearchControl(ref)}
                onClear={() => console.log("clear")}
                onResultShow={onResultShow}
                options={{
                  float: "none",
                }}
              />
            </Map>
          </YMaps>
        </Form.Item>
        <Form.Item
          label="Description of restaurant"
          name="description"
          rules={[
            {
              required: true,
              message: "Please input your description of restaurant!",
            },
          ]}
        >
          <TextArea />
        </Form.Item>
        <Form.Item label="Delivery" name="delivery" valuePropName="checked">
          <Switch onChange={handleDeliveryChange} />
        </Form.Item>
        <Form.Item
          label="24/7"
          name="twentyFourBySeven"
          valuePropName="checked"
        >
          <Switch onChange={handleTwentyFourBySevenChange} />
        </Form.Item>
        {!isTwentyFourBySeven && (
          <Form.Item
            rules={[
              {
                required: true,
                message: "Please input your restaurant  opening hours",
              },
            ]}
            label="Opening Hours"
            name="openHour"
          >
            <TimePicker format="hh:mm" size="large" />
          </Form.Item>
        )}
        {!isTwentyFourBySeven && (
          <Form.Item
            rules={[
              {
                required: true,
                message: "Please input your restaurant  closed hours",
              },
            ]}
            label="Closed Hours"
            name="closedHour"
          >
            <TimePicker format="hh:mm" size="large" />
          </Form.Item>
        )}
        <Form.Item
          label="Select Restaurant Category"
          name="categoryId"
          rules={[
            {
              required: true,
              message: "Please input your restaurant category",
            },
          ]}
        >
          <Select
            size="large"
            showSearch
            style={{ width: 200 }}
            placeholder="Select or search restaurant category"
          >
            {categories.map((category) => {
              return (
                <Option key={category["_id"]} value={category["_id"]}>
                  {category.name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item
          label="Select Restaurant Country"
          name="countryId"
          rules={[
            {
              required: true,
              message: "Please input your restaurant country",
            },
          ]}
        >
          <Select
            size="large"
            showSearch
            style={{ width: 200 }}
            placeholder="Select or search restaurant country"
          >
            {countries.map((country) => {
              return (
                <Option key={country["_id"]} value={country["_id"]}>
                  {country.name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item label="Website" name="website">
          <Input size="large" />
        </Form.Item>
        <Form.Item label="Istagram" name="instagram">
          <Input size="large" />
        </Form.Item>
        <Form.Item label="Facebook" name="facebook">
          <Input size="large" />
        </Form.Item>
        <Form.Item label="TripAdvisor" name="tripAdvisor">
          <Input size="large" />
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
              message: "Please input price range for 2",
            },
          ]}
          label="Price range for 2"
          name="priceRange"
        >
          <InputNumber size="large" />
        </Form.Item>
        <Form.Item
          label="Phone"
          name="phone"
          rules={[
            {
              required: true,
              message: "Please input your phone",
            },
          ]}
        >
          <Input size="large" maxLength={8} addonBefore={prefix} />
        </Form.Item>
        <Form.Item label="Keywords" name="keywords">
          <Select
            size="large"
            mode="tags"
            style={{ width: "100%" }}
            placeholder="Tags Mode"
          >
            {keywordsChildren}
          </Select>
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button size="large" type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Layout>
  );
};

export default CreateRestaurant;
