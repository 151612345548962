import { useFieldsTranslation } from "utils/hooks";
import Wrapper from "../../Wrapper";
import Text from "../../Text";
import { HeartIcon } from "../../Icons/HeartIcon";

const Favorites = ({ onClick = () => {}, active = false }) => {
  const { t } = useFieldsTranslation();

  return (
    <Wrapper
      onClick={onClick}
      display="flex"
      alignItems="center"
      flexDirection="column"
    >
      <HeartIcon style={{ color: !!active ? "#fb5b4e" : null }} />
      <Text rows={1} level={5} textColor={!!active ? "primary" : null}>
        {t("nav.favorites")}
      </Text>
    </Wrapper>
  );
};

export default Favorites;
