import { Skeleton } from "antd";
import "./styles.scss";

const CategorySelectorSkeleton = () => {
  return (
    <div className={"hg-category-selector-skeleton-wrapper"}>
      <Skeleton.Button
        className={"hg-category-selector-skeleton"}
        active={true}
      />
    </div>
  );
};

export default CategorySelectorSkeleton;
