import { useFieldsTranslation } from "utils/hooks";
import Text from "../Text";
import Wrapper from "../Wrapper";

const AboutFood = ({ insideData = [] }) => {
  const { t } = useFieldsTranslation("ingredients");

  return (
    <>
      {insideData.sort().map((item, index) => (
        <Wrapper pb={5} key={index}>
          <Text level={3} key={index}>
            {t(item)}
          </Text>
        </Wrapper>
      ))}
    </>
  );
};

export default AboutFood;
