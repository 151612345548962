import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import Wrapper from "../Wrapper";
import Text from "../Text";
import Title from "../Title";
import Calling from "../Calling";
import AvatarWithText from "../AvatarWithText";
import FoodRating from "../FoodRating";
import Favorite from "../Favorite";

import FoodCardSkeleton from "../../components/Skeletons/FoodCardSkeleton";
import "./styles.scss";

const FoodCard = ({
  food,
  skeleton,
  handleCardClick = () => {},
  handleRestaurantClick = () => {},
}) => {
  const location = useLocation();
  const isMenuMode = location.pathname.startsWith("/menu");
  const {
    name,
    image,
    price,
    restaurantName,
    restaurantIcon,
    phone,
    currency,
    id,
    rateData,
  } = food;
  if (skeleton) {
    return <FoodCardSkeleton />;
  }
  return (
    <div className="hg-food-card-container" onClick={handleCardClick}>
      <div className="hg-image-wrapper">
        <img src={image} alt={name} title={name} />
        {!!rateData && (
          <div className="hg-rating-wrapper">
            <FoodRating rateData={rateData} />
          </div>
        )}
      </div>
      <div className="hg-content-wrapper">
        <Wrapper display="flex" justifyContent="space-between">
          <div className="hg-food-title-wrapper">
            <Title title={name} rows={2} level={4}>
              {name}
            </Title>
          </div>
          <Text rows={1} bold level={3}>
            {currency} {price}
          </Text>
        </Wrapper>
        <Wrapper
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <div
            className="hg-restaurant-title-wrapper"
            onClick={handleRestaurantClick}
          >
            <AvatarWithText
              text={
                <Text
                  title={restaurantName}
                  level={3}
                  rows={1}
                  textColor="dark-90"
                >
                  {restaurantName}
                </Text>
              }
              src={restaurantIcon}
            />
          </div>
          <Wrapper display="flex" alignItems="center">
            {!isMenuMode && (
              <div className="hg-favorite-wrapper">
                <Favorite id={id} />
              </div>
            )}
            <Calling phone={phone} />
          </Wrapper>
        </Wrapper>
      </div>
    </div>
  );
};
FoodCard.propTypes = {
  food: PropTypes.object,
  skeleton: PropTypes.bool,
};

FoodCard.defaultProps = {
  food: {},
  skeleton: false,
};

export default FoodCard;
