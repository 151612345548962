import Layout from "layout";
import Text from "components/Text";
import Title from "components/Title";
import Wrapper from "components/Wrapper";
import YerevanImg from "./images/yerevan.png";
import CustomerImg from "./images/customer-pic.png";
import WeeklyFamous from "./images/weeklyfamous.png";
import GoodFood from "./images/good-food.png";
import DataDriven from "./images/data-driven.png";
import HappyCutomer from "./images/happy-customer.png";
import { ArrowRight } from "../../../components/Icons/ArrowRight";
import { Link } from "react-router-dom";

import { Row, Col } from "antd";

import "./styles.scss";

const colProps = {
  xs: 24,
  sm: 24,
  md: 24,
  lg: 12,
  xl: 12,
  xxl: 12,
  style: {
    marginBottom: "16px",
  },
};

const About = () => {
  return (
    <Layout type="sm">
      <div className="hg-about-section-1">
        <div className="hg-about-section-text-wrapper">
          <div className="hg-about-section-title">
            <Text level={3} rows={1} textColor="white">
              This is our business promise
            </Text>
          </div>
          <div className="hg-about-section-description">
            <Title bold level={2} titleColor="yellow">
              Suggesting good food anytime, anywhere!
            </Title>
          </div>
        </div>
      </div>
      <div className="hg-about-section-2">
        <div className="hg-about-section-text-wrapper">
          <Wrapper mb={2}>
            <Text level={1} rows={1} semiBold>
              About Hungryguys 🍕🍔🚀
            </Text>
          </Wrapper>

          <Wrapper mb={2}>
            <Text level={5}>
              Hungryguys is a food startup from Yerevan, Armenia. Founded in
              2021 by 3 friends that met randomly and shared their keeeness in
              food & technology.
            </Text>
          </Wrapper>
          <Wrapper mb={2}>
            <Text level={5}>
              Hungryguys believes that there’s a service beyond providing food
              to customers, people should get quality, service and a platform
              that helps them decide what they want the right, the right place.
            </Text>
          </Wrapper>
          <Wrapper mb={2}>
            <Text level={5}>
              The startup will always be in the center of customer service in
              the food industry, working as a service provider between
              restaurants and customers.
            </Text>
          </Wrapper>
        </div>
        <div className="hg-about-section-img-wrapper">
          <img alt={"yerevan"} title={"yerevan"} src={YerevanImg} />
        </div>
      </div>
      <div className="hg-about-section-3">
        <div className="hg-about-section-img-wrapper">
          <img alt={"customer"} title={"customer"} src={CustomerImg} />
        </div>
        <div className="hg-about-section-text-wrapper">
          <Wrapper mb={2}>
            <Text level={1} rows={1} semiBold>
              Customer is a king! 😎
            </Text>
          </Wrapper>

          <Wrapper mb={2}>
            <Text level={5}>
              Something heard a lot but never applied fully. Hungryguys will
              make sure to have this motto at the core of their objective.
            </Text>
          </Wrapper>
          <Wrapper mb={2}>
            <Text level={5}>
              They will always work on giving the customer the right thing at
              the right time and place and provide them a platform where they
              can express about food.
              <span className={"hg-about-section-citat-wrapper"}>
                “Every idea will be heard and taken into consideration”.
              </span>
            </Text>
          </Wrapper>
          <Row gutter={[16, 16]}>
            <Col {...colProps}>
              <Wrapper mb={9}>
                <Text bold level={3}>
                  Fast Ordering ⏰
                </Text>
              </Wrapper>
              <Text level={5}>
                The faster they decide what to eat the bigger is the probability
                is for them to come again.
              </Text>
            </Col>
            <Col {...colProps}>
              <Wrapper mb={9}>
                <Text bold level={3}>
                  Behaivoral Analytics 🎛
                </Text>
              </Wrapper>
              <Text level={5}>
                Our tools will track the user behaivor and will be transparent
                with providing you with reports.
              </Text>
            </Col>
            <Col {...colProps}>
              <Wrapper mb={9}>
                <Text bold level={3}>
                  Easy Navigation 🔍
                </Text>
              </Wrapper>
              <Text level={5}>
                Our data is structured and easily navigated. All the foods are
                categorized in a smart way and navigation friendly.
              </Text>
            </Col>
            <Col {...colProps}>
              <Wrapper mb={9}>
                <Text bold level={3}>
                  Fact based marketing 👩🏻‍🚀
                </Text>
              </Wrapper>
              <Text level={5}>
                We create the bridge between the restaurant that sells burger
                and john that loves to explore new burger.
              </Text>
            </Col>
          </Row>
          <Wrapper p={4} borderRadius="base" shadow>
            <Text level={4} semiBold>
              Release Note 1.0
            </Text>
            <Text level={5} textColor="dark-50">
              This release will last untill December 2021
            </Text>
          </Wrapper>
        </div>
      </div>
      <div className="hg-about-section-4">
        <div className="hg-about-section-text-wrapper">
          <Title titleColor="white" bold level={1}>
            The Weekly Famous
          </Title>
        </div>
        <div className="hg-about-section-link-wrapper">
          <Text level={3} textColor="white">
            <Link to={"/more-suggestions/new"}>
              Explore New Food <ArrowRight fill="white" />
            </Link>
          </Text>
        </div>
        <div className="hg-about-section-img-wrapper">
          <img
            alt={"weekly-famous"}
            title={"weekly-famous"}
            src={WeeklyFamous}
          />
        </div>
      </div>
      <div className="hg-about-section-5">
        <div className="hg-about-section-text-wrapper">
          <Wrapper mb={2}>
            <Text level={1} rows={1} semiBold>
              Food in hand 🍔
            </Text>
          </Wrapper>

          <Wrapper mb={2}>
            <Text level={5}>
              It is a known behaivor to ask the waiter “what’s inside” of a
              certain dish, it might be hesitating sometimes to ask lots of
              questions and get uncertain answers from a real communication.
            </Text>
          </Wrapper>
          <Wrapper mb={2}>
            <Text level={5}>
              While using our platform you will have all the needed info right
              in your hand. In the upcoming updates you will get detailed
              analytics on what are people loving in your menu!
            </Text>
          </Wrapper>
          <Row gutter={[16, 16]}>
            <Col {...colProps}>
              <Wrapper mb={9}>
                <Text bold level={3}>
                  Social channels 📺
                </Text>
              </Wrapper>
              <Text level={5}>
                You can find us almost on every social channel and we will be
                advertising food on a daily basis.
              </Text>
            </Col>
            <Col {...colProps}>
              <Wrapper mb={9}>
                <Text bold level={3}>
                  The right time, the right thing ⏰
                </Text>
              </Wrapper>
              <Text level={5}>
                We believe in customer satisfaction and that can be fulfilled by
                giving them the right thing at the right time.
              </Text>
            </Col>
          </Row>
        </div>
        <div className="hg-about-section-img-wrapper">
          <img alt={"good-food"} title={"good-food"} src={GoodFood} />
        </div>
      </div>
      <div className="hg-about-section-6">
        <div className="hg-about-section-text-wrapper">
          <Wrapper mb={2}>
            <Text level={1} rows={1} semiBold>
              Data driven food startup
            </Text>
          </Wrapper>

          <Wrapper mb={2}>
            <Text level={5}>
              The data in our website is always backed up with user research.
            </Text>
          </Wrapper>
          <Wrapper mb={2}>
            <Text level={5}>
              While using our platform you will have all the needed info right
              in your hand. In the upcoming updates you will get detailed
              analytics on what are people loving in your menu!
            </Text>
          </Wrapper>
          <Row gutter={[16, 16]}>
            <Col {...colProps}>
              <Wrapper mb={9}>
                <Text bold level={3}>
                  Dynamic Categories 🍔
                </Text>
              </Wrapper>
              <Text level={5}>
                Food categories are generated as a result of user experience so
                they are dynamic.
              </Text>
            </Col>
            <Col {...colProps}>
              <Wrapper mb={9}>
                <Text bold level={3}>
                  Review System 💡
                </Text>
              </Wrapper>
              <Text level={5}>
                Transparency is a real thing. The user & restaurant will both
                see food reviews and act upon.
              </Text>
            </Col>
            <Col span={24}>
              <Link to={"/"}>
                Check our food <ArrowRight />
              </Link>
            </Col>
          </Row>
        </div>
        <div className="hg-about-section-img-wrapper">
          <img alt={"driven"} title={"driven"} src={DataDriven} />
        </div>
      </div>
      <div className="hg-about-section-7">
        <div className="hg-about-section-img-wrapper">
          <img alt={"driven"} title={"driven"} src={HappyCutomer} />
        </div>
        <div className="hg-about-section-text-wrapper">
          <Wrapper mb={2}>
            <Title bold titleColor="yellow" level={2}>
              The most imporant is the
            </Title>
            <Title bold titleColor="primary" level={2}>
              happy customer.
            </Title>
          </Wrapper>

          <Wrapper mb={2}>
            <Text level={5}>
              The data in our website is always backed up with user research.
            </Text>
          </Wrapper>
          <Wrapper mb={2}>
            <Text level={5}>
              While using our platform you will have all the needed info right
              in your hand. In the upcoming updates you will get detailed
              analytics on what are people loving in your menu!
            </Text>
          </Wrapper>
          <Row gutter={[16, 16]}>
            <Col {...colProps}>
              <Wrapper mb={9}>
                <Text bold level={3}>
                  Dynamic Categories 🍔
                </Text>
              </Wrapper>
              <Text level={5}>
                Food categories are generated as a result of user experience so
                they are dynamic.
              </Text>
            </Col>
            <Col {...colProps}>
              <Wrapper mb={9}>
                <Text bold level={3}>
                  Review System 💡
                </Text>
              </Wrapper>
              <Text level={5}>
                Transparency is a real thing. The user & restaurant will both
                see food reviews and act upon.
              </Text>
            </Col>
            <Col span={24}>
              <Link to={"/become-a-partner"}>
                Become a partner <ArrowRight />
              </Link>
            </Col>
          </Row>
        </div>
      </div>
    </Layout>
  );
};

export default About;
