import { useContext } from "react";
import DrawerMenu from "./components/DrawerMenu";
import RightMenu from "./components/RightMenu";
import CenterMenu from "./components/CenterMenu";
import { useLocation } from "react-router-dom";
import { HungryguysIcon } from "../Icons/HungryguysIcon";
import { Link } from "react-router-dom";
import { NavigationContext } from "context/NavigationContext";
import { Drawer } from "antd";
import smoothscroll from "smoothscroll-polyfill";
import "./styles.scss";

smoothscroll.polyfill();

const Navbar = () => {
  const location = useLocation();
  const isMenuMode = location.pathname.startsWith("/menu");
  const { visible, setVisible } = useContext(NavigationContext);
  function onClose() {
    setVisible(false);
  }
  function handleDrawerClick() {
    setVisible(false);
  }

  function handleLogoClick() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
  return (
    <>
      <nav className="menuBar">
        <div className="logo">
          {isMenuMode ? (
            <div className="hg-hungryguys-logo-wrapper">
              <HungryguysIcon />
            </div>
          ) : (
            <Link
              className="hg-hungryguys-logo-wrapper"
              onClick={handleLogoClick}
              to={isMenuMode ? location.pathname : "/"}
            >
              <HungryguysIcon />
            </Link>
          )}
        </div>
        {!isMenuMode && (
          <div className="centerMenu">
            <CenterMenu />
          </div>
        )}
        {!isMenuMode && (
          <div className="rightMenu">
            <RightMenu modeType={"horizontal"} />
          </div>
        )}
      </nav>
      <div className="menuCon">
        <Drawer
          title={
            <Link
              className="hg-hungryguys-logo-wrapper"
              onClick={handleDrawerClick}
              to="/"
            >
              <HungryguysIcon />
            </Link>
          }
          placement="right"
          closable={false}
          onClose={onClose}
          visible={visible}
          className={"navbarDrawer"}
        >
          <DrawerMenu modeType={"vertical"} />
          {/* <RightMenu modeType={"vertical"} /> */}
        </Drawer>
      </div>
    </>
  );
};

export default Navbar;
