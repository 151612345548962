import { useEffect, useState, memo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useFieldsTranslation } from "utils/hooks";
import { formatImgUrl } from "utils/helper";
import { Modal } from "antd";
import Calling from "../Calling";
import { CloseIcon } from "../Icons/CloseIcon";
import FoodService from "services/FoodService";
import AboutFood from "./AboutFood";
import Footer from "./Footer";
import Header from "./Header";
import "./styles.scss";
import Wrapper from "../Wrapper";
import Favorite from "../Favorite";

function queryStringToJson(query) {
  try {
    return JSON.parse(
      '{"' +
        decodeURI(query.substring(1))
          .replace(/"/g, '\\"')
          .replace(/&/g, '","')
          .replace(/=/g, '":"') +
        '"}'
    );
  } catch (_err) {
    return {};
  }
}
const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(
  navigator.userAgent
);

const WhatsInside = memo(({}) => {
  const [modal, setModal] = useState(false);
  const [food, setFood] = useState();
  const { t } = useFieldsTranslation();
  const history = useHistory();
  const location = useLocation();

  const classNames = ["hg-whats-inside-wrapp-modal"];
  const queryJson = queryStringToJson(location.search);
  const isMenuMode = location.pathname.startsWith("/menu");

  useEffect(() => {
    if (!queryJson.id) {
      // chgitem arji te che
      setModal(false);
      return;
    }
    if (location.state && location.state.food) {
      setFood(location.state.food);
      setModal(true);
    } else {
      FoodService.getById(queryJson.id).then((data) => {
        setModal(true);
        const {
          name,
          currency,
          price,
          image,
          restaurantId,
          whatsInside,
          isCombo,
          insideCombo,
        } = data.food;
        setFood({
          name,
          whatsInside,
          currency,
          price,
          isCombo,
          insideCombo,
          image: formatImgUrl(image),
          phone: restaurantId.phone,
          location: restaurantId.address[0].name,
          restaurantName: restaurantId.name,
          restaurantIcon: formatImgUrl(restaurantId.icon),
          restaurantId: restaurantId._id,
        });
      });
    }
  }, [location.search]);

  function handleClose(e) {
    e.stopPropagation();
    setModal(false);
    if (location.state && location.state.food) {
      setModal(false);
      history.goBack();
    } else {
      history.push(location.pathname);
    }
  }

  function handleRestaurantClick() {
    const restaurantPath = `/restaurant/${food?.restaurantName}/${food?.restaurantId}`;
    if (history.location.pathname !== restaurantPath && !isMenuMode) {
      history.push(restaurantPath);
    }
  }
  return (
    <div className="hg-whats-inside-container">
      <Modal
        centered={!isMobile}
        wrapClassName={classNames.join(" ")}
        onCancel={handleClose}
        closeIcon={
          <div style={{ color: "#fff" }}>
            <CloseIcon />
          </div>
        }
        closable={isMobile}
        zIndex={9999}
        footer={
          <Footer
            handleClickAvatar={handleRestaurantClick}
            avatarTitle={food?.restaurantName}
            avatar={food?.restaurantIcon}
            location={food && food.location}
            extraContent={
              <Wrapper display="flex">
                {!isMenuMode && (
                  <Wrapper mx={9}>
                    <Favorite id={food?.id || queryJson?.id} />
                  </Wrapper>
                )}
                <Calling phone={food && food.phone} />
              </Wrapper>
            }
          />
        }
        visible={modal}
        title={
          <Header
            name={food && food.name}
            title={t("whats.inside")}
            price={food && `${food.currency} ${food.price}`}
            image={food && food.image}
          />
        }
      >
        <AboutFood
          insideData={
            food && (food.isCombo ? food.insideCombo : food.whatsInside)
          }
        />
      </Modal>
    </div>
  );
});

export default WhatsInside;
