import { createContext, useState, useEffect } from "react";
import Layout from "../layout/index";
import AuthService from "../services/AuthService";
import { setUser as setSentryUser } from "@sentry/react";

export const AuthContext = createContext();

export default ({ children }) => {
  const [user, setUser] = useState({});
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    AuthService.isAuthenticated()
      .then((data) => {
        setUser(data.user);
        setSentryUser({
          id: data.user.id,
          email: data.user.email,
          username: data.user.fullName,
        });
        setIsAuthenticated(data.isAuthenticated);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <Layout type="none">
      <AuthContext.Provider
        value={{ user, setUser, loading, isAuthenticated, setIsAuthenticated }}
      >
        {children}
      </AuthContext.Provider>
    </Layout>
  );
};
