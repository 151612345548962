import { useCallback } from "react";
import { useTranslation } from "react-i18next";

export const useFieldsTranslation = (ns = "common") => {
  const [t, i18n] = useTranslation(ns);

  const tField = useCallback(
    (data = {}, fieldName) => {
      return data[`${fieldName}_${i18n.language}`] || data[fieldName] || "";
    },
    [i18n.language]
  );

  return { t, tField };
};

// /**
//  * {[latitude: number, longitude: number]} defaultLocation
//  * @param {{
//  *  onLocationGranted?: (geolocation: [latitude: number, longitude: number]) => void;
//  *  defaultLocation?: [latitude: number, longitude: number]
//  * }} params
//  */
// export const useGeolocation = ({
//   defaultLocation = [],
//   onLocationGranted = () => {},
// }) => {
//   const [geolocation, setGeolocation] = useState(defaultLocation);
//   const [waitForAccess, setWaitForAccess] = useState(false);
//   const [statusState, setStatusState] = useState();
//   const watchIdRef = useRef();

//   const geolocationWatcher = useCallback(() => {
//     watchIdRef.current = navigator.geolocation.watchPosition(
//       (position) => {
//         setStatusState("granted");
//         setGeolocation([position.coords.latitude, position.coords.longitude]);
//       },
//       () => {
//         setWaitForAccess(false);
//         setStatusState("denied");
//         alert("Your location service blocked pleace unblock and try again");
//       }
//     );
//   }, []);

//   useEffect(() => {
//     navigator.permissions.query({ name: "geolocation" }).then((status) => {
//       setStatusState(status.state);
//       if (status.state === "granted") {
//         geolocationWatcher();
//       }
//     });

//     return () => {
//       navigator.geolocation.clearWatch(watchIdRef.current);
//     };
//   }, []);

//   useEffect(() => {
//     if (!waitForAccess || !geolocation.length) {
//       return;
//     }
//     setWaitForAccess(false);
//     onLocationGranted(geolocation);
//   }, [waitForAccess, geolocation]);

//   const grantlocation = useCallback(
//     (force = false) => {
//       if (geolocation.length && !force) {
//         onLocationGranted(geolocation);
//       } else {
//         const allow =
//           statusState === "granted" ||
//           confirm("We need your location to find nearest food");
//         if (allow) {
//           setWaitForAccess(true);
//           geolocationWatcher();
//         }
//       }
//     },
//     [geolocation, onLocationGranted]
//   );

//   return {
//     grantlocation,
//     waitForAccess,
//     geolocation,
//   };
// };
