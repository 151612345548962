import Text from "../Text";
import Title from "../Title";
import CuisineCardSkeleton from "../../components/Skeletons/CuisineCardSkeleton";
import "./styles.scss";

const CuisineCard = ({ data, skeleton }) => {
  const { title, text, image, extraContent, originName } = data;

  if (skeleton) {
    return <CuisineCardSkeleton />;
  }
  return (
    <div className="hg-cuisine-card-container">
      <div className="hg-cuisine-card-img-mask"></div>
      <img
        alt={originName}
        title={originName}
        src={image}
        className="hg-cuisine-card-img"
      />
      <div className="hg-cuisine-card-content-wrapper">
        <Title bold={true} level={3} rows={1} titleColor="white">
          {title}
        </Title>
        <Text level={3} rows={1} textColor="white">
          {text}
        </Text>
      </div>
      <div className="hg-extra-content-wrapper">{extraContent}</div>
    </div>
  );
};

export default CuisineCard;
