import { memo, useCallback, useEffect, useState } from "react";
import { useFieldsTranslation } from "utils/hooks";
import { formatImgUrl } from "utils/helper";
import { Tabs } from "antd";
import FoodCardList from "components/FoodCardList";
import RestaurantService from "services/RestaurantService";
import FoodService from "services/FoodService";
import TabBarSkeleton from "components/Skeletons/TabBarSkeleton";
import { StickyContainer, Sticky } from "react-sticky";
import { queryStringToJson } from "utils/helper";
import smoothscroll from "smoothscroll-polyfill";
import { useHistory } from "react-router";
import "./styles.scss";

const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(
  navigator.userAgent
);
const { TabPane } = Tabs;
smoothscroll.polyfill();

const RestaurantMenu = memo(({ match, location }) => {
  const history = useHistory();
  const { params } = match;
  const [subcategories, setSubcategories] = useState([]);
  const [food, setFood] = useState([]);
  const [isSticky, setIsSticky] = useState(false);
  const [subcategoryId, setSubcategoryId] = useState(() => {
    const queryJson = queryStringToJson(location.search);
    return queryJson.tab;
  });
  const [loading, setLoading] = useState(true);
  const { t, tField } = useFieldsTranslation();

  const renderTabBar = useCallback(
    (props, DefaultTabBar) => (
      <Sticky topOffset={!isMobile ? -52 : -64}>
        {({ style, isSticky }) => {
          setIsSticky(isSticky);
          return (
            <DefaultTabBar
              {...props}
              style={{
                ...style,
                top: isSticky ? (!isMobile ? "52px" : "64px") : 0,
                zIndex: 1,
                background: "#fff",
              }}
            />
          );
        }}
      </Sticky>
    ),
    [isSticky]
  );

  useEffect(() => {
    if (isMobile) {
      window.scroll({ top: 320, behavior: "auto" });
    } else {
      window.scroll({ top: 0, behavior: "auto" });
    }
  }, [food]);

  useEffect(() => {
    RestaurantService.getRestaurantFoodSubcategories(params.id).then((data) => {
      setSubcategoryId(subcategoryId || data.subcategories[0]?._id);
      setSubcategories(data.subcategories);
    });
  }, []);

  useEffect(() => {
    setLoading(true);
    if (!subcategoryId) {
      return;
    }
    const condition = {
      limit: 100,
      populate: JSON.stringify(["restaurantId"]),
      where: JSON.stringify({
        restaurantId: params.id,
        subcategoryId: subcategoryId,
      }),
      sort: JSON.stringify({
        foodOrder: "asc",
        name: "asc",
      }),
    };
    FoodService.get(condition).then((data) => {
      setFood(data.food);
      setLoading(false);
    });
  }, [subcategoryId]);

  function handleTabClick(subcategoryId) {
    history.replace(`${location.pathname}?tab=${subcategoryId}`);
    setSubcategoryId(subcategoryId);
  }

  if ((!subcategories.length && !food.length) || !subcategoryId) {
    return (
      <TabBarSkeleton
        withContentCard={true}
        contentCardCol={{
          xs: 24,
          sm: 12,
          md: 8,
          lg: 12,
          xl: 12,
          xxl: 12,
        }}
      />
    );
  }
  return (
    <StickyContainer>
      <Tabs
        className={"hg-restaurant-menu-tab"}
        activeKey={subcategoryId}
        onChange={handleTabClick}
        renderTabBar={renderTabBar}
      >
        {subcategories?.map((item, index) => {
          return (
            <TabPane
              style={{ margin: isSticky ? "18px 0 16px 0" : "2px 0 18px 0" }}
              tab={item.name}
              key={item._id}
            >
              <FoodCardList
                skeletonCardCount={8}
                scrollX={false}
                scrollYCol={{
                  xs: 24,
                  sm: 12,
                  md: 8,
                  lg: 12,
                  xl: 12,
                  xxl: 12,
                }}
                loading={loading}
                food={food?.map((item) => {
                  return {
                    id: item._id,
                    name: tField(item, "name"),
                    image: formatImgUrl(item.image),
                    price: item.price,
                    rateData: {
                      feedbackType: "food",
                      entityId: item._id,
                      rate: item.rating,
                      maxRate: 5,
                    },
                    phone: item.restaurantId.phone,
                    restaurantName: tField(item.restaurantId, "name"),
                    restaurantIcon: formatImgUrl(item.restaurantId.icon),
                    currency: t(item.currency),
                    insideCombo: item.insideCombo,
                    isCombo: item.isCombo,
                    whatsInside: item.whatsInside,
                    location: item.restaurantId.address[0].name,
                    restaurantId: item.restaurantId._id,
                  };
                })}
              />
            </TabPane>
          );
        })}
      </Tabs>
    </StickyContainer>
  );
});

export default RestaurantMenu;
