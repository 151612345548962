import React, { useState, useContext, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Upload,
  Select,
  InputNumber,
  Switch,
  DatePicker,
  Row,
  Col,
} from "antd";
import TranslatableFormItem from "components/TranslatableFormItem";
import { feedbackMessage } from "utils/helper";
import { UploadOutlined } from "@ant-design/icons";
import FoodService from "services/FoodService";
import FoodCategoryService from "services/FoodCategoryService";
import FoodSubcategoryService from "services/FoodSubcategoryService";
import RestaurantService from "services/RestaurantService";
import RemoveFileService from "services/RemoveFileService";
import CountryService from "services/CountryService";
import { AuthContext } from "context/AuthContext";
import Layout from "layout";

const { Option } = Select;
const { TextArea } = Input;
const { RangePicker } = DatePicker;

const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};
const tailLayout = {
  style: {
    textAlign: "center",
  },
};

const CreateFood = () => {
  const { user = {} } = useContext(AuthContext);
  const authContext = useContext(AuthContext);
  const [restaurants, setRestaurants] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [food, setFood] = useState([]);
  const [restaurantId, setRestaurantId] = useState();
  const [isNew, setIsNew] = useState(false);
  const [isFeatured, setIsFeatured] = useState(false);
  const [isWeekly, setIsWeekly] = useState(false);
  const [isDiet, setIsDiet] = useState(false);
  const [isForKids, setIsForKids] = useState(false);
  const [isVegetarian, setIsVegetarian] = useState(false);
  const [isDiscountAvailable, setIsDiscountAvailable] = useState(false);
  const [isCombo, setIsCombo] = useState(false);
  const [countries, setCountries] = useState([]);
  const [ingredients, setIngredients] = useState([]);
  const [keywords, setKeywords] = useState([]);
  const [isManager, setIsManager] = useState(false);

  const [form] = Form.useForm();
  const formRef = React.createRef();

  const handleRemoveImg = (data) => {
    RemoveFileService.remove(data.response).then((res) => {
      feedbackMessage({
        status: res.message.status,
        message: res.message.msgBody,
      });
    });
  };

  const generalParams = {
    sort: JSON.stringify({
      name: "asc",
    }),
  };
  const paramsCountry = {
    where: JSON.stringify({
      restaurantId: restaurantId,
    }),
    sort: JSON.stringify({
      name: "asc",
    }),
  };

  const getRestaurantByUserType = () => {
    if (user.role === "manager") {
      const paramsUserManager = {
        where: JSON.stringify({
          _id: user.restaurantId,
        }),
        sort: JSON.stringify({
          name: "asc",
        }),
      };
      RestaurantService.get(paramsUserManager).then((data) => {
        setRestaurants(data.restaurants);
      });
    } else {
      const params = {
        sort: JSON.stringify({
          name: "asc",
        }),
      };
      RestaurantService.get(params).then((data) => {
        setRestaurants(data.restaurants);
      });
    }
  };

  useEffect(() => {
    setIsManager(user.role === "manager");
  }, []);

  useEffect(() => {
    getRestaurantByUserType();

    FoodCategoryService.get(generalParams).then((data) => {
      setCategories(data.categories);
    });
    FoodSubcategoryService.get(generalParams).then((data) => {
      setSubcategories(data.subcategories);
    });
    CountryService.get(paramsCountry).then((data) => {
      setCountries(data.countries);
    });
  }, []);

  useEffect(() => {
    const paramsFood = {
      where: JSON.stringify({
        restaurantId: restaurantId,
      }),
      sort: JSON.stringify({
        name: "asc",
      }),
    };
    FoodService.get(paramsFood).then((data) => {
      setFood(data.food);
    });
  }, [restaurantId]);

  useEffect(() => {
    FoodService.getIngredients().then((data) => {
      setIngredients(data.ingredients);
    });
    FoodService.getKeywords().then((data) => {
      setKeywords(data.keywords);
    });
  }, []);

  const ingredientsChildren = [];
  for (let i = 0; i < ingredients.length; i++) {
    ingredientsChildren.push(
      <Option key={ingredients[i]}>{ingredients[i]}</Option>
    );
  }

  const keywordsChildren = [];
  for (let i = 0; i < keywords.length; i++) {
    keywordsChildren.push(<Option key={keywords[i]}>{keywords[i]}</Option>);
  }

  function resetForm() {
    formRef.current.resetFields();
    setIsNew(false);
    setIsWeekly(false);
    setIsDiet(false);
    setIsForKids(false);
    setIsVegetarian(false);
    setIsDiscountAvailable(false);
    setIsCombo(false);
    setIsFeatured(false);
  }

  const onFinish = ({ image, discountDuration, ...values }) => {
    const foodData = {
      image: image[0].response,
      discountStart: discountDuration && discountDuration[0],
      discountEnd: discountDuration && discountDuration[1],
      ...values,
    };
    FoodService.add(foodData).then((data) => {
      const { message } = data;
      if (!message.msgError) {
        feedbackMessage({ status: message.status, message: message.msgBody });
        resetForm();
      } else if (message.msgBody === "UnAuthorized") {
        feedbackMessage({ status: message.status, message: message.msgBody });
        authContext.setUser({ email: "", role: "" });
        authContext.setIsAuthenticated(false);
      } else {
        feedbackMessage({ status: message.status, message: message.msgBody });
      }
    });
  };
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleNewChange = (value) => {
    setIsNew(value);
  };

  const handleFeaturedChange = (value) => {
    setIsFeatured(value);
  };

  const handleWeeklyChange = (value) => {
    setIsWeekly(value);
  };

  const handleRestaourantChange = (value) => {
    setRestaurantId(value);
  };

  const handleDietChange = (value) => {
    setIsDiet(value);
  };
  const handleForKidsChange = (value) => {
    setIsForKids(value);
  };

  const handleVegetarianChange = (value) => {
    setIsVegetarian(value);
  };

  const handleDiscountAvailableChange = (value) => {
    setIsDiscountAvailable(value);
  };
  const handleComboChange = (value) => {
    setIsCombo(value);
  };
  return (
    <Layout type="xs">
      <Form
        form={form}
        {...layout}
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        ref={formRef}
      >
        <Form.Item
          label="Select Restaurant Name"
          name="restaurantId"
          rules={[
            {
              required: true,
              message: "Please input restaurant name!",
            },
          ]}
        >
          <Select
            size="large"
            showSearch
            style={{ width: 200 }}
            placeholder="Select or search restaurant name"
            onChange={handleRestaourantChange}
          >
            {restaurants.map((restaurant) => {
              return (
                <Option key={restaurant["_id"]} value={restaurant["_id"]}>
                  {restaurant.name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <TranslatableFormItem
          label="Food Name"
          name="name"
          field={Input}
          fieldProps={{ size: "large" }}
          rules={[
            {
              required: true,
              message: "Please input food name!",
            },
          ]}
        />
        <Form.Item label="Combo" name="isCombo">
          <Switch checked={isCombo} onChange={handleComboChange} />
        </Form.Item>
        {!!isCombo && (
          <>
            <Form.Item label="Whats inside Combo" name="insideCombo">
              <Select
                size="large"
                mode="tags"
                style={{ width: "100%" }}
                placeholder="Tags Mode"
              >
                {food.map((food) => {
                  return (
                    <Option key={food._id} value={food.name}>
                      {food.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </>
        )}
        <Form.Item
          label="Food Price"
          name="price"
          rules={[
            {
              required: true,
              message: "Please input food price!",
            },
          ]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item label="Is Discount Available" name="isDiscountAvailable">
          <Switch
            checked={isDiscountAvailable}
            onChange={handleDiscountAvailableChange}
          />
        </Form.Item>
        {!!isDiscountAvailable && (
          <>
            <Form.Item label="Discount Percent %" name="discountPercent">
              <InputNumber size="large" max={100} min={0} />
            </Form.Item>
            <Form.Item
              label="Start/End Date for Discount"
              name="discountDuration"
            >
              <RangePicker size="large" />
            </Form.Item>
          </>
        )}
        <Row>
          <Col span={8}>
            <Form.Item label="Featured" name="featured">
              <Switch checked={isFeatured} onChange={handleFeaturedChange} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="For Kids" name="forKids">
              <Switch checked={isForKids} onChange={handleForKidsChange} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Diet" name="diet">
              <Switch checked={isDiet} onChange={handleDietChange} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="New" name="new">
              <Switch checked={isNew} onChange={handleNewChange} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Vegetarian" name="vegetarian">
              <Switch
                checked={isVegetarian}
                onChange={handleVegetarianChange}
              />
            </Form.Item>
          </Col>
          {!isManager && (
            <Col span={8}>
              <Form.Item label="Weekly" name="weekly">
                <Switch checked={isWeekly} onChange={handleWeeklyChange} />
              </Form.Item>
            </Col>
          )}
        </Row>
        <Form.Item
          label="Select Food Category"
          name="categoryId"
          rules={[
            {
              required: true,
              message: "Please select category!",
            },
          ]}
        >
          <Select
            size="large"
            showSearch
            style={{ width: 200 }}
            placeholder="Select or search food category"
          >
            {categories.map((category) => {
              return (
                <Option key={category["_id"]} value={category["_id"]}>
                  {category.name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item
          label="Select Food Subcategory"
          name="subcategoryId"
          rules={[
            {
              required: true,
              message: "Please select subcategory!",
            },
          ]}
        >
          <Select
            size="large"
            showSearch
            style={{ width: 200 }}
            placeholder="Select or search food subcategory"
          >
            {subcategories.map((subcategory) => {
              return (
                <Option key={subcategory["_id"]} value={subcategory["_id"]}>
                  {subcategory.name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item
          label="Select Food Country"
          name="countryId"
          rules={[
            {
              required: true,
              message: "Please input food country",
            },
          ]}
        >
          <Select
            size="large"
            showSearch
            style={{ width: 200 }}
            placeholder="Select or search food country"
          >
            {countries.map((country) => {
              return (
                <Option key={country["_id"]} value={country["_id"]}>
                  {country.name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item
          label="Image"
          name="image"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          extra="text"
          rules={[
            {
              required: true,
              message: "Please upload food image!",
            },
          ]}
        >
          <Upload
            multiple={false}
            name="sampleFile"
            action="/backend/upload"
            listType="picture"
            maxCount={1}
            onRemove={handleRemoveImg}
          >
            <Button size="large" icon={<UploadOutlined />}>
              Click to upload
            </Button>
          </Upload>
        </Form.Item>
        <TranslatableFormItem
          label="Description of food"
          name="description"
          field={TextArea}
        />
        <Form.Item label="Whats Inside" name="whatsInside">
          <Select
            size="large"
            mode="tags"
            style={{ width: "100%" }}
            placeholder="Tags Mode"
          >
            {ingredientsChildren}
          </Select>
        </Form.Item>
        <Form.Item label="Keywords" name="keywords">
          <Select
            size="large"
            mode="tags"
            style={{ width: "100%" }}
            placeholder="Tags Mode"
          >
            {keywordsChildren}
          </Select>
        </Form.Item>
        {!isManager && (
          <Form.Item
            label="Food Order(change only for necessity)"
            name="foodOrder"
            initialValue={100}
          >
            <InputNumber />
          </Form.Item>
        )}
        <Form.Item {...tailLayout}>
          <Button size="large" type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Layout>
  );
};

export default CreateFood;
