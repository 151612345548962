import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./App";
// import HttpsRedirect from "react-https-redirect";
// import * as serviceWorker from "./serviceWorker";
import { I18nextProvider } from "react-i18next";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import i18next from "i18next";
import UserRateContextProvider from "./context/UserRateContext";
import FavoritesContextProvider from "./context/FavoritesContext";
import AuthProvider from "./context/AuthContext";
import common_hy from "./translations/hy/common.json";
import common_en from "./translations/en/common.json";
import common_ru from "./translations/ru/common.json";
import ingradients_hy from "./translations/hy/ingredients.json";
import ingradients_en from "./translations/en/ingredients.json";
import ingradients_ru from "./translations/ru/ingredients.json";

import "antd/dist/antd.css";
import "./App.less";

const lng = localStorage.getItem("lng") || "en";

i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: lng, // language to use
  resources: {
    en: {
      common: common_en, // 'common' is our custom namespace
      ingredients: ingradients_en, // 'common' is our custom namespace
    },
    hy: {
      common: common_hy,
      ingredients: ingradients_hy,
    },
    ru: {
      common: common_ru,
      ingredients: ingradients_ru,
    },
  },
});

Sentry.init({
  dsn:
    "https://36a9fc9a435c49998fce2bc0b3636000@o661338.ingest.sentry.io/5764686",
  integrations: [new Integrations.BrowserTracing()],
  environment: process.env.NODE_ENV,
  autoSessionTracking: false,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <AuthProvider>
    <I18nextProvider i18n={i18next}>
      {/* <HttpsRedirect> */}
      <FavoritesContextProvider>
        <UserRateContextProvider>
          <App />
        </UserRateContextProvider>
      </FavoritesContextProvider>
      {/* </HttpsRedirect> */}
    </I18nextProvider>
  </AuthProvider>,
  document.getElementById("root")
);

// serviceWorker.unregister();
