import Wrapper from "components/Wrapper";
import CategorySelector from "../CategorySelector";
import { resizeArray } from "utils/helper";
import CategorySelectorSkeleton from "../Skeletons/CategorySelectorSkeleton";
import PropTypes from "prop-types";
import "./styles.scss";

const CategorySelectorList = ({ categories, loading }) => {
  if (loading) {
    const arr = resizeArray(5);
    return (
      <Wrapper pb={3} display="flex" scrollX={true}>
        {arr.map((item, index) => {
          return (
            <Wrapper px={9} key={index}>
              <CategorySelectorSkeleton />
            </Wrapper>
          );
        })}
      </Wrapper>
    );
  }
  return (
    <Wrapper display="flex" pb={3} scrollX={true}>
      {categories?.map((category) => {
        return (
          <Wrapper px={9} key={category.key}>
            <CategorySelector
              title={category.title}
              icon={category.icon}
              link={category.link}
            />
          </Wrapper>
        );
      })}
    </Wrapper>
  );
};

CategorySelectorList.propTypes = {
  categories: PropTypes.array,
};

CategorySelectorList.defaultProps = {
  categories: [],
};

export default CategorySelectorList;
