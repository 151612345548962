import { useRef, memo } from "react";
import { useFieldsTranslation } from "utils/hooks";
import AdsBanner from "components/AdsBanner";
import { useHistory } from "react-router-dom";
import RegisterModal from "components/RegisterModal";
import AdsBanner2 from "images/adsBanner2.jpeg";
import { Button } from "antd";

const AdsBannerSection = memo(({ isLogin }) => {
  const registerModalRef = useRef(null);
  const history = useHistory();
  const { t } = useFieldsTranslation();

  const handleShowRegistrModal = () => {
    if (isLogin) {
      history.push(`/become-a-partner`);
    } else {
      registerModalRef.current.open();
    }
  };

  return (
    <>
      <AdsBanner
        skeleton={false}
        data={{
          title: t("ads.rate.the.dishes"),
          text: t("ads.dont.forget.to"),
          description: t("ads.banner.desc2"),
          extraContent: (
            <Button
              size="large"
              onClick={handleShowRegistrModal}
              type="primary"
            >
              {t("ads.banner.become.a.member")}
            </Button>
          ),
          image: AdsBanner2,
        }}
      />
      <RegisterModal history={history} ref={registerModalRef} />
    </>
  );
});

export default AdsBannerSection;
