import { useContext } from "react";
import Layout from "layout";
import Wrapper from "components/Wrapper";
import WeeklyFoodSection from "./Sections/WeeklyFoodSection";
import NewFoodSection from "./Sections/NewFoodSection";
import HealthyFoodSection from "./Sections/HealthyFoodSection";
import ComboSection from "./Sections/ComboSection";
import TopRatedSection from "./Sections/TopRatedSection";
import CuisineSection from "./Sections/CuisineSection";
import SubCategoriesSection from "./Sections/SubcategoriesSection";
import { AuthContext } from "context/AuthContext";
import MainBannerSection from "./Sections/MainBannerSection";
import AdsBannerSection from "./Sections/AdsBannerSection";
import AdsBanner2Section from "./Sections/AdsBanner2Section";

const Home = () => {
  const { isAuthenticated } = useContext(AuthContext);

  return (
    <Layout type="sm">
      <MainBannerSection />
      <Wrapper py={3} display="flex" justifyContent="center" scrollX={true}>
        <SubCategoriesSection />
      </Wrapper>
      <Wrapper mb={2}>
        <WeeklyFoodSection />
      </Wrapper>
      <Wrapper mb={2}>
        <CuisineSection />
      </Wrapper>
      <Wrapper my={2}>
        <NewFoodSection />
      </Wrapper>
      <Wrapper my={2}>
        <ComboSection />
      </Wrapper>
      <Wrapper my={2}>
        <AdsBanner2Section isLogin={isAuthenticated} />
      </Wrapper>
      <Wrapper my={2}>
        <TopRatedSection />
      </Wrapper>
      <Wrapper my={2}>
        <HealthyFoodSection />
      </Wrapper>
      <Wrapper my={2}>
        <AdsBannerSection isLogin={isAuthenticated} />
      </Wrapper>
    </Layout>
  );
};

export default Home;
