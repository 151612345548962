import { Row, Col } from "antd";
import { resizeArray } from "utils/helper";
import CuisineCardSkeleton from "../../components/Skeletons/CuisineCardSkeleton";
import CuisineCard from "../CuisineCard";
import PropTypes from "prop-types";

const CuisineCardList = ({ data,handleCardClick =()=>{}, loading, scrollX }) => {
  const colProps = scrollX
    ? {
        xs: 22,
        sm: 14,
        md: 13,
        lg: 11,
        xl: 7,
        xxl: 7,
      }
    : {
        xs: 24,
        sm: 12,
        md: 8,
        lg: 8,
        xl: 6,
        xxl: 4,
      };

  if (loading) {
    const arr = resizeArray(10);
    return (
      <Row
        gutter={8}
        style={{
          overflowX: "auto",
          flexFlow: scrollX ? "nowrap" : "wrap",
          paddingBottom: "16px",
        }}
      >
        {arr.map((item, index) => {
          return (
            <Col {...colProps} key={index}>
              <CuisineCardSkeleton />
            </Col>
          );
        })}
      </Row>
    );
  }
  return (
    <Row
      gutter={8}
      style={{
        overflowX: "auto",
        flexFlow: scrollX ? "nowrap" : "wrap",
        paddingBottom: "16px",
      }}
    >
      {data?.map((item) => {
        return (
          <Col onClick={()=>item.handleCardClick()} {...colProps} key={item.key}>
            <CuisineCard
              skeleton={loading}
              data={{
                originName: item.originName,
                title: item.title,
                text: item.text,
                image: item.image,
                extraContent: item.extraContent,
              }}
            />
          </Col>
        );
      })}
    </Row>
  );
};

CuisineCardList.propTypes = {
  categories: PropTypes.array,
};

CuisineCardList.defaultProps = {
  categories: [],
};

export default CuisineCardList;
