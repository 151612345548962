import BannerSkeleton from "../Skeletons/BannerSkeleton";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import "./styles.scss";

export const Banner = ({ content, skeleton, ...props }) => {
  if (skeleton) {
    return <BannerSkeleton />;
  }
  return (
    <Slide
      autoplay={true}
      transitionDuration={500}
      className={"hg-banner-carousel"}
      // pauseOnHover={true}
    >
      {content.map((item, index) => {
        return (
          <div className="hg-banner-wrapper" key={index}>
            <div className="hg-banner-img-mask"></div>
            <div
              className={"hg-banner-content-wrapper"}
              style={{ backgroundImage: `url(${item.image})` }}
            >
              <div className={"hg-banner-content"}>
                <p>{item.title}</p>
                <h1>{item.text}</h1>
              </div>
            </div>
          </div>
        );
      })}
    </Slide>
  );
};
