import Text from "../Text";
import Wrapper from "../Wrapper";
import AvatarWithText from "../AvatarWithText";

const Footer = ({
  location = "",
  avatarTitle = "",
  avatar = "",
  handleClickAvatar = () => {},
  extraContent,
}) => {
  return (
    <Wrapper
      py={7}
      display={"flex"}
      justifyContent={"space-between"}
      alignItems="center"
    >
      <Wrapper
        display={"flex"}
        textAlign="left"
        flexDirection="column"
        width="80%"
        cursorPointer
        onClick={handleClickAvatar}
      >
        <AvatarWithText
          text={
            <Wrapper>
              <Text title={avatarTitle} level={3} rows={1} textColor="dark-100">
                {avatarTitle}
              </Text>
              <Text title={location} rows={2} level={4} textColor="dark-90">
                {location?.split(",").splice(2).join(" ")}
              </Text>
            </Wrapper>
          }
          src={avatar}
        />
      </Wrapper>
      <Wrapper>{extraContent}</Wrapper>
    </Wrapper>
  );
};

export default Footer;
