import { useContext, useCallback } from "react";
import { useFieldsTranslation } from "utils/hooks";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import { feedbackMessage } from "utils/helper";
import { AuthContext } from "context/AuthContext";
import { NavigationContext } from "context/NavigationContext";
import AuthService from "services/AuthService";

const SubMenu = Menu.SubMenu;

const DrawerMenu = ({ modeType = "horizontal" }) => {
  const { user = {}, setIsAuthenticated, setUser } = useContext(AuthContext);
  const { current, setCurrent, setVisible } = useContext(NavigationContext);
  const { t } = useFieldsTranslation();

  const handleClick = (e) => {
    setVisible(false);
    setCurrent({ current: e.key });
  };

  const onClickLogoutHandler = () => {
    AuthService.logout().then((data) => {
      if (data.success) {
        setUser(data.user);
        setIsAuthenticated(false);
        setCurrent({ current: "/" });
      } else {
        feedbackMessage({ status: 500, message: "try again!" });
      }
    });
  };

  const authenticatedNavBar = useCallback(() => {
    switch (user.role) {
      case "user": {
        return (
          <Menu.Item key="/logout">
            <span onClick={onClickLogoutHandler}>Logout</span>
          </Menu.Item>
        );
      }
      case "admin": {
        return (
          <>
            <SubMenu key="/food" title={t("nav.food")}>
              <Menu.Item key="/createFood">
                <Link to="/createFood">{t("nav.create.food")}</Link>
              </Menu.Item>
              <Menu.Item key="/updateFood">
                <Link to="/updateFood">{t("nav.update.food")}</Link>
              </Menu.Item>
              <Menu.Item key="/createFoodCategory">
                <Link to="/createFoodCategory">
                  {t("nav.create.food.category")}
                </Link>
              </Menu.Item>
              <Menu.Item key="/updateFoodCategory">
                <Link to="/updateFoodCategory">
                  {t("nav.update.food.category")}
                </Link>
              </Menu.Item>
              <Menu.Item key="/createFoodSubcategory">
                <Link to="/createFoodSubcategory">
                  {t("nav.create.food.subcategory")}
                </Link>
              </Menu.Item>
              <Menu.Item key="/updateFoodSubcategory">
                <Link to="/updateFoodSubcategory">
                  {t("nav.update.food.subcategory")}
                </Link>
              </Menu.Item>
            </SubMenu>
            <SubMenu key="/restaurant" title={t("nav.restaurant")}>
              <Menu.Item key="/createRestaurantCategory">
                <Link to="/createRestaurantCategory">
                  {t("nav.create.restaurant.category")}
                </Link>
              </Menu.Item>
              <Menu.Item key="/updateRestaurantCategory">
                <Link to="/updateRestaurantCategory">
                  {t("nav.update.restaurant.category")}
                </Link>
              </Menu.Item>
              <Menu.Item key="/createCountry">
                <Link to="/createCountry">{t("nav.create.country")}</Link>
              </Menu.Item>
              <Menu.Item key="/updateCountry">
                <Link to="/updateCountry">{t("nav.update.country")}</Link>
              </Menu.Item>
              <Menu.Item key="/createRestaurant">
                <Link to="/createRestaurant">{t("nav.create.restaurant")}</Link>
              </Menu.Item>
              <Menu.Item key="/restaurants">
                <Link to="/restaurants">{t("nav.restaurants")}</Link>
              </Menu.Item>
            </SubMenu>
            <Menu.Item key="/admin-dashboard">
              <Link to="/admin-dashboard">{t("nav.admin.dashboard")}</Link>
            </Menu.Item>
            <Menu.Item key="/logout">
              <span onClick={onClickLogoutHandler}>{t("nav.logout")}</span>
            </Menu.Item>
          </>
        );
      }
      case "manager": {
        return (
          <>
            <Menu.Item key="/createFood">
              <Link to="/createFood">{t("nav.create.food")}</Link>
            </Menu.Item>
            <Menu.Item key="/updateFood">
              <Link to={`/updateFood`}>{t("nav.update.food")}</Link>
            </Menu.Item>
            <Menu.Item key="/updateRestaurant">
              <Link to={`/updateRestaurant/${user.restaurantId}`}>
                {t("Update Restaurant")}
              </Link>
            </Menu.Item>
            <Menu.Item key="/logout">
              <span onClick={onClickLogoutHandler}>{t("nav.logout")}</span>
            </Menu.Item>
          </>
        );
      }
      default: {
        return (
          <>
            <Menu.Item key="/login">
              <Link to="/login">{t("nav.sign.in")}</Link>
            </Menu.Item>
            <Menu.Item key="/register">
              <Link to="/register">{t("nav.sign.up")}</Link>
            </Menu.Item>
          </>
        );
      }
    }
  }, [user.role]);

  return (
    <Menu
      onClick={handleClick}
      selectedKeys={[current.current]}
      mode={modeType}
    >
      <Menu.Item key="/">
        <Link to="/">{t("nav.home")}</Link>
      </Menu.Item>
      <Menu.Item key="/more-suggestions/weekly">
        <Link to="/more-suggestions/weekly">{t("nav.food.of.week")}</Link>
      </Menu.Item>
      <Menu.Item key="/more-suggestions/new">
        <Link to="/more-suggestions/new">{t("nav.explore.new.food")}</Link>
      </Menu.Item>
      <Menu.Item key="/more-suggestions/isCombo">
        <Link to="/more-suggestions/isCombo">{t("nav.great.deals")}</Link>
      </Menu.Item>
      <Menu.Item key="/top-rated-food">
        <Link to="/top-rated-food">{t("nav.top.rated.food")}</Link>
      </Menu.Item>
      <Menu.Item key="/more-suggestions/diet">
        <Link to="/more-suggestions/diet">{t("nav.healthy.food")}</Link>
      </Menu.Item>

      {authenticatedNavBar()}
    </Menu>
  );
};

export default DrawerMenu;
