import CountryForm from "./Form";
import Layout from "layout";

const CreateCountry = () => {
  return (
    <Layout type="xs">
      <CountryForm />
    </Layout>
  );
};

export default CreateCountry;
