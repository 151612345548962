import { useState, useEffect, memo } from "react";
import { formatImgUrl } from "utils/helper";
import { useFieldsTranslation } from "utils/hooks";
import FoodSubcategoryService from "services/FoodSubcategoryService";
import CategorySelectorList from "components/CategorySelectorList";

const queryParams = {
  sort: JSON.stringify({
    order: "asc",
    name: "asc",
  }),
};

const SubcategoriesSection = memo(() => {
  const [foodSubcategories, setFoodSubCategories] = useState([]);
  const { tField } = useFieldsTranslation();

  useEffect(() => {
    FoodSubcategoryService.get(queryParams).then((data) => {
      setFoodSubCategories(data.subcategories);
    });
  }, []);
  return (
    <>
      <CategorySelectorList
        loading={!foodSubcategories?.length}
        categories={foodSubcategories?.map((item) => {
          return {
            title: tField(item, "name"),
            icon: formatImgUrl(item.icon),
            link: `subcategory/${item.name}/${item._id}`,
            key: item._id,
          };
        })}
      />
    </>
  );
});

export default SubcategoriesSection;
