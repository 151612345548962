import React, { useContext, useEffect } from "react";
import { Form, Input, Button, Upload } from "antd";
import TranslatableFormItem from "components/TranslatableFormItem";
import { UploadOutlined } from "@ant-design/icons";
import CountryService from "services/CountryService";
import RemoveFileService from "services/RemoveFileService";
import { feedbackMessage } from "utils/helper";
import { AuthContext } from "context/AuthContext";
import Layout from "layout";

const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};
const tailLayout = {
  style: {
    textAlign: "center",
  },
};

const CountryForm = ({ initialValues = {}, afterSubmit = () => {} }) => {
  const authContext = useContext(AuthContext);
  const [form] = Form.useForm();
  const formRef = React.createRef();

  function resetForm() {
    formRef.current.resetFields();
  }

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    if (!initialValues._id) {
      return;
    }
    form.setFieldsValue(initialValues);
  }, [initialValues._id]);

  const onFinish = async ({ image, ...values }) => {
    const countryData = {
      ...values,
      image: image && image[0].response,
    };
    const requestPromise = initialValues._id
      ? CountryService.update({
          id: initialValues._id,
          updatedData: countryData,
        })
      : CountryService.add(countryData);
    const result = await requestPromise;
    const { message } = result;
    if (!message.msgError) {
      feedbackMessage({ status: message.status, message: message.msgBody });
      resetForm();
    } else if (message.msgBody === "UnAuthorized") {
      feedbackMessage({ status: message.status, message: message.msgBody });
      authContext.setUser({ email: "", role: "" });
      authContext.setIsAuthenticated(false);
    } else {
      feedbackMessage({ status: message.status, message: message.msgBody });
    }
    afterSubmit(result.data);
  };
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const handleRemoveImg = (data) => {
    RemoveFileService.remove(data.response).then((res) => {
      feedbackMessage({
        status: res.message.status,
        message: res.message.msgBody,
      });
    });
  };

  return (
    <Form
      form={form}
      {...layout}
      name="basic"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      ref={formRef}
    >
      <TranslatableFormItem
        label="Country (for example Armenian)"
        name="name"
        field={Input}
        fieldProps={{ size: "large" }}
        rules={[
          {
            required: true,
            message: "Please input country!",
          },
        ]}
      />
      <Form.Item
        label="Icon/image"
        name="image"
        valuePropName="fileList"
        getValueFromEvent={normFile}
        extra="text"
        rules={[
          {
            required: true,
            message: "Please upload country image or icon",
          },
        ]}
      >
        <Upload
          multiple={false}
          name="sampleFile"
          action="/backend/upload"
          listType="picture"
          maxCount={1}
          onRemove={handleRemoveImg}
        >
          <Button size="large" icon={<UploadOutlined />}>
            Click to upload
          </Button>
        </Upload>
      </Form.Item>
      <Form.Item {...tailLayout}>
        <Button size="large" type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CountryForm;
