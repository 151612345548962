import AdsBannerSkeleton from "../Skeletons/AdsBannerSkeleton";
import Wrapper from "../Wrapper";
import Text from "../Text";
import Title from "../Title";
import "./styles.scss";

const AdsBanner = ({ data, skeleton }) => {
  const { title, text, description, image, extraContent } = data;
  if (skeleton) {
    return <AdsBannerSkeleton />;
  }
  return (
    <div className="hg-ads-banner-container">
      <div className="hg-ads-banner-content">
        <Text level={2}>{text}</Text>
        <Wrapper>
          <Title level={2} rows={4} bold titleColor={"primary"}>
            {title}
          </Title>
        </Wrapper>
        <div className="hg-ads-banner-desc-wrapper">
          <Text level={2} rows={3} textColor="dark-90">
            {description}
          </Text>
        </div>
        <Wrapper pt={3}>{extraContent}</Wrapper>
      </div>
      <div className="hg-ads-banner-img-wrapper">
        <img src={image} />
      </div>
    </div>
  );
};

export default AdsBanner;
