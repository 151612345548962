import Wrapper from "../Wrapper";
import { Avatar } from "antd";
import "./styles.scss";

const AvatarWithText = ({ src, icon, size, text, pl = 7 }) => {
  const classNames = [];
  if(size){
    classNames.push(`hg-avatar-${size}`)
  }
  return (
    <Wrapper display="flex" alignItems="center">
      <div className="hg-avatar-wrapper">
        <Avatar className={classNames.join("")} size={size} src={src} icon={icon} />
      </div>
      {text ? <Wrapper pl={pl}>{text}</Wrapper> : null}
    </Wrapper>
  );
};
export default AvatarWithText;
