import { useState, useRef, useEffect, useContext, memo } from "react";
import { Form, Input, Button, Select, Divider } from "antd";
import { feedbackMessage } from "utils/helper";
import { useFieldsTranslation } from "utils/hooks";
import { AuthContext } from "context/AuthContext";
import AuthService from "services/AuthService";
import Layout from "layout";
import LoginWithFacbook from "components/LoginWithFacebook";
import LoginWithGoogle from "components/LoginWithGoogle";
import Wrapper from "components/Wrapper";
import Text from "components/Text";

const { Option } = Select;

const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};
const tailLayout = {
  style: {
    textAlign: "center",
  },
};

const Register = memo((props) => {
  const [form] = Form.useForm();
  const { t } = useFieldsTranslation();
  const authContext = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  let timerID = useRef(null);

  useEffect(() => {
    return () => {
      clearTimeout(timerID);
    };
  }, []);

  const resetForm = () => {
    form.resetFields();
  };

  const onFinish = (value) => {
    const localLoginLoadin =
      value.provider !== "facebook" && value.provider !== "google";
    setLoading(localLoginLoadin);
    const registerUser = {
      email: value.email,
      firstName: value.firstName,
      lastName: value.lastName,
      phoneNumber: value.phoneNumber,
      password: value.password,
    };
    AuthService.register(registerUser).then((data) => {
      const { message } = data;
      feedbackMessage({ status: message.status, message: message.msgBody });
      resetForm();
      if (!message.msgError) {
        timerID = setTimeout(() => {
          props.history.push("/login");
        }, 2000);
      }
      setLoading(false);
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onLoginFacebook = (value) => {
    AuthService.login(value).then((data) => {
      const { isAuthenticated, user, message } = data;
      if (isAuthenticated) {
        authContext.setUser(user);
        authContext.setIsAuthenticated(isAuthenticated);
        props.history.push("/");
        close();
        setLoading(false);
      } else
        feedbackMessage({ status: message.status, message: message.msgBody });
      setLoading(false);
    });
  };

  const onLoginGoogle = (value) => {
    AuthService.login(value).then((data) => {
      const { isAuthenticated, user, message } = data;
      if (isAuthenticated) {
        authContext.setUser(user);
        authContext.setIsAuthenticated(isAuthenticated);
        props.history.push("/");
        close();
        setLoading(false);
      } else
        feedbackMessage({ status: message.status, message: message.msgBody });
      setLoading(false);
    });
  };

  return (
    <Layout type="xs">
      <Wrapper mb={3}>
        <Wrapper mb={7} mt={4}>
          {" "}
          <Text bold level={1}>
            {t("reg.sign.up")}
          </Text>
        </Wrapper>
        <Text level={4} textColor={"dark-90"} rows={3}>
          {t("reg.desc")}
        </Text>
      </Wrapper>
      <Form
        form={form}
        {...layout}
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label={
            <Text level={4} textColor={"dark-90"} bold>
              {t("reg.email")}
            </Text>
          }
          name="email"
          rules={[
            {
              required: true,
              message: t("reg.valid.email.message"),
            },
          ]}
        >
          <Input size="large" placeholder={t("reg.email.placeholder")} />
        </Form.Item>

        <Form.Item
          label={
            <Text level={4} textColor={"dark-90"} bold>
              {t("reg.first.name")}
            </Text>
          }
          name="firstName"
          rules={[
            {
              required: true,
              message: t("reg.valid.firstname.message"),
            },
          ]}
        >
          <Input size="large" placeholder="Enter First Name" />
        </Form.Item>
        <Form.Item
          label={
            <Text level={4} textColor={"dark-90"} bold>
              {t("Last Name")}
            </Text>
          }
          name="lastName"
          rules={[
            {
              required: true,
              message: t("reg.valid.lastname.message"),
            },
          ]}
        >
          <Input size="large" placeholder={t("reg.lastname.placeholder")} />
        </Form.Item>
        <Form.Item
          label={
            <Text level={4} textColor={"dark-90"} bold>
              {t("reg.phone.number")}
            </Text>
          }
          name="phoneNumber"
          rules={[
            {
              required: true,
              message: t("reg.phone.number.valid.message"),
            },
          ]}
        >
          <Input size="large" placeholder={t("reg.phone.number.placeholder")} />
        </Form.Item>
        <Form.Item
          label={
            <Text level={4} textColor={"dark-90"} bold>
              {t("reg.password")}
            </Text>
          }
          name="password"
          rules={[
            {
              required: true,
              message: t("reg.password.valid.message"),
            },
          ]}
        >
          <Input
            size="large"
            placeholder={t("reg.password.placeholder")}
            type="password"
          />
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Button
            loading={loading}
            size="large"
            type="primary"
            htmlType="submit"
            style={{ width: "100%" }}
          >
            {t("reg.sign.up")}
          </Button>
        </Form.Item>
      </Form>
      <Divider />
      <Wrapper
        mb={5}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Text textColor="dark-90" level={4}>
          {t("reg.sign.up.versions")}
        </Text>
        <Wrapper
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Wrapper mx={8}>
            <LoginWithFacbook onLogin={onLoginFacebook} />
          </Wrapper>
          <Wrapper>
            <LoginWithGoogle onLogin={onLoginGoogle} />
          </Wrapper>
        </Wrapper>
      </Wrapper>
    </Layout>
  );
});

export default Register;
