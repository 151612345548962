import { Row, Col } from "antd";
import { useFieldsTranslation } from "utils/hooks";
import LngDropDown from "components/lngDropDown";
import { languages } from "utils/lng";
import Text from "../Text";
import { HungryguysFooterIcon } from "../Icons/HungryguysFooterIcon";
import { TwitterIcon } from "../Icons/TwitterIcon";
import { InstaIcon } from "../Icons/InstaIcon";
import { YoutubeIcon } from "../Icons/YoutubeIcon";
import { TikTokIcon } from "../Icons/TikTokIcon";
import { FBIcon } from "../Icons/FBIcon";
import { Link } from "react-router-dom";
import Wrapper from "../Wrapper";
import "./styles.scss";

const Footer = () => {
  const { t } = useFieldsTranslation();

  const colProps = {
    xs: 24,
    sm: 24,
    md: 24,
    lg: 4,
  };
  const colLogoProps = {
    xs: 24,
    sm: 24,
    md: 24,
    lg: 16,
  };
  return (
    <div className="hg-footer-container">
      <Row gutter={[26, 26]}>
        <Col {...colLogoProps}>
          <Wrapper pr={1}>
            <HungryguysFooterIcon />
            <Wrapper pt={4} pb={2}>
              <div className="hg-footer-description-wrapper">
                <Text level={3} rows={5}>
                  {t("footer.desc")}
                </Text>
              </div>
            </Wrapper>
            <Wrapper pb={4}>
              <Row gutter={16}>
                <Col>
                  <a
                    target="_blank"
                    href="https://www.facebook.com/hungryguys.am"
                  >
                    <FBIcon />
                  </a>
                </Col>
                <Col>
                  <a
                    target="_blank"
                    href="https://www.tiktok.com/@hungryguys.am"
                  >
                    <TikTokIcon />
                  </a>
                </Col>

                <Col>
                  <a
                    target="_blank"
                    href="https://www.instagram.com/hungryguys.am/"
                  >
                    <InstaIcon />
                  </a>
                </Col>
              </Row>
            </Wrapper>
          </Wrapper>
        </Col>
        <Col {...colProps}>
          <Wrapper pb={2}>
            <Text semiBold level={3}>
              {t("footer.about")}
            </Text>
          </Wrapper>

          <ul>
            <li>
              <Text level={4}>
                <Link to="/about">{t("footer.about.us")}</Link>
              </Text>
            </li>
            <li>
              <Text level={4}>
                <Link to={"/become-a-partner"}>
                  {t("footer.become.a.partner")}
                </Link>
              </Text>
            </li>
          </ul>
        </Col>
        <Col {...colProps}>
          <Wrapper pb={2}>
            <Text semiBold level={3}>
              {t("footer.contact.us")}
            </Text>
          </Wrapper>

          <ul>
            <li>
              <Text level={4}>hello@hungryguys.am</Text>
            </li>
            <li>
              <LngDropDown languages={languages} />
            </li>
            <li></li>
            <li></li>
            {/* <li>
              <Text level={4}>
                <a href={`tel:${+37441744467}`}>+374 41 744 467</a>
              </Text>
            </li> */}
          </ul>
        </Col>
      </Row>
      <Wrapper display="flex" justifyContent="flex-start">
        <Row gutter={24}>
          <Col>
            <Text textColor="dark-10" level={4}>
              <a>Copyright 2021 hungryguys</a>
            </Text>
          </Col>
          <Col>
            <Text textColor="dark-10" level={4}>
              <Link to="/">{t("footer.home")}</Link>
            </Text>
          </Col>
          <Col>
            <Text textColor="dark-10" level={4}>
              <Link to="/about">{t("footer.about.us")}</Link>
            </Text>
          </Col>
          <Col>
            <Text textColor="dark-10" level={4}>
              <Link to="/become-a-partner">{t("footer.become.a.partner")}</Link>
            </Text>
          </Col>
          <Col>
            <Text textColor="dark-10" level={4}>
              <Link to="/more-suggestions/new">
                {t("footer.explore.new.food")}
              </Link>
            </Text>
          </Col>
        </Row>
      </Wrapper>
    </div>
  );
};

export default Footer;
