import { useState } from "react";
import { Button } from "antd";
import "./styles.scss";

const Text = ({
  children,
  level = 1,
  bold = false,
  semiBold = false,
  rows,
  readMore = false,
  textColor = "dark-100",
  title,
  innerText,
  underline = false,
}) => {
  const [moreText, setMoreText] = useState(false);

  const names = {
    1: "lg",
    2: "md",
    3: "sm",
    4: "xs",
    5: "xxs",
  };

  const classNames = ["hg-text", `hg-${names[level]}`];

  if (bold) classNames.push("hg-bold");
  if (semiBold) classNames.push("hg-semiBold");
  if (textColor) classNames.push(`hg-text-color-${textColor}`);
  if (moreText) classNames.push("hg-readMore");
  if (rows && !moreText) classNames.push(`hg-text-row hg-text-row-${rows}`);
  if (underline) classNames.push("hg-underline");

  function buttonText() {
    if (moreText) {
      return "less";
    }
    return "more";
  }

  return (
    <>
      {innerText ? (
        <p
          className={classNames.join(" ")}
          title={title}
          dangerouslySetInnerHTML={{ __html: innerText }}
        />
      ) : (
        <p className={classNames.join(" ")} title={title}>
          {children}
        </p>
      )}
      {readMore && (
        <Button onClick={() => setMoreText(!moreText)} size="small" type="link">
          {buttonText()}
        </Button>
      )}
    </>
  );
};
export default Text;

// Text.propTypes = {
//   level: PropTypes.oneOf([1, 2, 3, 4]),
//   bold: PropTypes.bool,
//   semiBold: PropTypes.bool,
//   rows: PropTypes.oneOf([1, 2, 3, null]),
//   readMore: PropTypes.bool,
//   innerText: PropTypes.string,
//   textColor: PropTypes.oneOf([
//     "dark-100",
//     "dark-50",
//     "dark-30",
//     "dark-20",
//     "dark-10",
//     "indicator-good",
//     "indicator-caution",
//     "indicator-bad",
//     "indicator-neutral",
//     "white",
//     "light",
//     "primary",
//   ]),
//   title: PropTypes.string,
//   underline: PropTypes.bool,
// };
