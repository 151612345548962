import { useRef, memo, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useFieldsTranslation } from "utils/hooks";
import RegisterModal from "components/RegisterModal";
import FoodService from "services/FoodService";
import AdsBanner from "components/AdsBanner";
import AdsBanner1 from "images/adsBanner1.jpeg";
import { Button } from "antd";

const AdsBanner2Section = memo(({ isLogin }) => {
  const [count, setCount] = useState(0);
  const registerModalRef = useRef(null);
  const history = useHistory();
  const { t } = useFieldsTranslation();

  const handleShowRegistrModal = () => {
    if (isLogin) {
      history.push(`/become-a-partner`);
    } else {
      registerModalRef.current.open();
    }
  };
  useEffect(() => {
    FoodService.getCount().then((data) => {
      setCount(data.count);
    });
  }, []);

  return (
    <>
      <AdsBanner
        skeleton={false}
        data={{
          title: `${count} ${t("ads.banner.dishes")}`,
          text: t("ads.banner.more.than"),
          description: t("ads.banner.desc"),
          extraContent: (
            <Button
              size="large"
              onClick={handleShowRegistrModal}
              type="primary"
            >
              {t("ads.banner.become.a.member")}
            </Button>
          ),
          image: AdsBanner1,
        }}
      />
      <RegisterModal history={history} ref={registerModalRef} />
    </>
  );
});

export default AdsBanner2Section;
