import Icon from "@ant-design/icons";

const CloseSvg = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.0742 2.926C13.1724 -0.975008 6.82694 -0.975008 2.92511 2.926C-0.975037 6.82701 -0.975037 13.1741 2.92511 17.0751C4.87603 19.0252 7.43805 19.9998 10.0001 19.9998C12.5622 19.9998 15.1233 19.0252 17.0742 17.0751C20.9753 13.1742 20.9753 6.82701 17.0742 2.926ZM14.1262 12.9482C14.4522 13.2742 14.4522 13.8011 14.1262 14.1271C13.9636 14.2896 13.7502 14.3714 13.5367 14.3714C13.3233 14.3714 13.1098 14.2896 12.9473 14.1271L10.0001 11.179L7.05369 14.1262C6.89029 14.2888 6.67683 14.3705 6.46423 14.3705C6.25081 14.3705 6.03735 14.2888 5.87478 14.1262C5.5488 13.8002 5.5488 13.2725 5.87478 12.9473L8.82116 10.0001L5.87395 7.05293C5.54798 6.72696 5.54798 6.19921 5.87395 5.87406C6.19911 5.54809 6.72685 5.54809 7.05283 5.87406L10 8.82126L12.9472 5.87406C13.2732 5.54809 13.8001 5.54809 14.1261 5.87406C14.4521 6.19921 14.4521 6.72696 14.1261 7.05293L11.1789 10.0001L14.1262 12.9482Z"
      fill="currentColor"
    />
  </svg>
);

export const CloseIcon = (props) => (
  <Icon className="close-icon" component={CloseSvg} {...props} />
);
