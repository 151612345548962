import { useEffect } from "react";
import ReactGA from "react-ga";
import { useHistory } from "react-router-dom";

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);

const Analytics = ({ children }) => {
  const history = useHistory();
  const { location } = history;
  useEffect(() => {
    return history.listen((location) => {
      const pageView = () => {
        ReactGA.pageview(location.pathname + location.search);
      };
      pageView();
    });
  }, [location.hash, location.search, location.pathname, location.state]);

  return children;
};

Analytics.getInitialProps = async () => ({});

export default Analytics;
