import { Skeleton } from "antd";
import Wrapper from "../../Wrapper";
import "./styles.scss";

const RestaurantInfoCardSkeleton = () => {
  return (
    <Wrapper borderRadius="base" shadow={true} p={3}>
      <div className="hg-extra-content-skeleton">
        <Skeleton
          className="hg-rest-info-skl-avatar"
          avatar
          round
          active
          paragraph={{ rows: 1 }}
        />
        <Wrapper pt={8}>
          <Skeleton round active paragraph={{ rows: 3 }} />
        </Wrapper>
      </div>
    </Wrapper>
  );
};

export default RestaurantInfoCardSkeleton;
