import { memo, useEffect, useState } from "react";
import { useFieldsTranslation } from "utils/hooks";
import { formatImgUrl } from "utils/helper";
import RestaurantInfoCard from "components/RestaurantInfoCard";
import RestaurantService from "services/RestaurantService";

const RestaurantInfo = memo(({ match }) => {
  const { tField, t } = useFieldsTranslation();
  const { params } = match;
  const [restaurant, setRestaurant] = useState({});
  const [loading, setLoading] = useState(true);
  const {
    openingHours,
    countryId,
    priceRange,
    profileImg,
    address,
    phone,
    twentyFourBySeven,
  } = restaurant;

  useEffect(() => {
    setLoading(true);
    const conditionRestaurant = {
      where: JSON.stringify({
        _id: params.id,
      }),
      populate: JSON.stringify(["categoryId", "countryId"]),
    };
    RestaurantService.get(conditionRestaurant).then((data) => {
      setRestaurant(data.restaurants[0]);
      setLoading(false);
    });
  }, []);

  const openHour = new Date(openingHours?.open).toTimeString().split(" ")[0];
  const closedHour = new Date(openingHours?.closed)
    .toTimeString()
    .split(" ")[0];

  return (
    <>
      <RestaurantInfoCard
        skeleton={loading}
        data={{
          name: tField(restaurant, "name"),
          openingHours: twentyFourBySeven
            ? "24/7"
            : `${openHour} - ${closedHour}`,
          phone,
          priceRange,
          currency: t("AMD"),
          country: tField(countryId, "name"),
          icon: formatImgUrl(profileImg),
          location: address && address[0].name?.split(",").splice(2).join(" "),
        }}
      />
    </>
  );
});

export default RestaurantInfo;
