import { Tag } from "antd";
import Text from "../Text";
import Wrapper from "../Wrapper";
import "./styles.scss";

const Header = ({ title, name, price, image }) => {
  return (
    <Wrapper>
      <div className="hg-inside-food-img-wrapper">
        <img src={image} />
      </div>
      <Text rows={1} level={4} textColor="dark-20">
        {title}
      </Text>
      <Wrapper maxWidth="80%">
        <Text rows={2} semiBold={true} level={2}>
          {name}
        </Text>
      </Wrapper>
      <Wrapper pt={9}>
        <Text textColor="primary" rows={1} level={3}>
          {price}
        </Text>
      </Wrapper>
    </Wrapper>
  );
};

export default Header;
