import { useFieldsTranslation } from "utils/hooks";
import Wrapper from "../Wrapper";
import Text from "../Text";
import Calling from "../Calling";
import AvatarWithText from "../AvatarWithText";
import RestaurantInfoCardSkeleton from "../../components/Skeletons/RestaurantInfoCardSkeleton";
import { LocationIconOutline } from "../Icons/LocationIconOutline";

const RestaurantInfoCard = ({ data, skeleton, cursorPointer = false }) => {
  const { t } = useFieldsTranslation();

  const {
    name,
    openingHours,
    phone,
    priceRange,
    country,
    icon,
    currency,
    location,
  } = data;

  if (skeleton) {
    return <RestaurantInfoCardSkeleton />;
  }
  return (
    <Wrapper
      cursorPointer={cursorPointer}
      hover={false}
      borderRadius="base"
      shadow={true}
      p={3}
    >
      <Wrapper py={8}>
        <AvatarWithText
          size="large"
          src={icon}
          text={
            <>
              <Text rows={1}>{name}</Text>
              <Text rows={1} textColor="dark-50" level={4}>
                {t("rest.info.card.restaurant")} . {country}
              </Text>
            </>
          }
        />
      </Wrapper>
      <Wrapper
        py={8}
        alignItems="center"
        justifyContent="space-between"
        display="flex"
      >
        <Wrapper pr={8}>
          <Text rows={1} textColor="dark-50" level={4}>
            {t("rest.info.card.opening.hours")}
          </Text>
        </Wrapper>
        <Text rows={1} textColor="dark-100" level={4}>
          {openingHours}
        </Text>
      </Wrapper>
      <Wrapper
        py={8}
        alignItems="center"
        justifyContent="space-between"
        display="flex"
      >
        <Wrapper pr={8}>
          <Text rows={1} textColor="dark-50" level={4}>
            {location}
          </Text>
        </Wrapper>
        <LocationIconOutline />
      </Wrapper>
      <Wrapper
        py={8}
        alignItems="center"
        justifyContent="space-between"
        display="flex"
      >
        <Wrapper pr={8}>
          <Text rows={1} textColor="dark-50" level={3}>
            {phone}
          </Text>
        </Wrapper>
        <Calling phone={phone} />
      </Wrapper>
      <Wrapper
        py={8}
        alignItems="center"
        justifyContent="space-between"
        display="flex"
      >
        <Wrapper pr={8}>
          <Text rows={1} textColor="dark-50" level={4}>
            {t("rest.info.card.price.for.2")}
          </Text>
        </Wrapper>
        <Text rows={1} textColor="dark-100" level={4}>
          {currency} {priceRange}
        </Text>
      </Wrapper>
    </Wrapper>
  );
};

export default RestaurantInfoCard;
