import { useContext } from "react";
import { useFieldsTranslation } from "utils/hooks";
import { useHistory, Link } from "react-router-dom";
import { getInitials } from "utils/helper";
import { AuthContext } from "context/AuthContext";
import { NavigationContext } from "context/NavigationContext";
import { Menu, Button, Avatar } from "antd";
import Wrapper from "components/Wrapper";
import Text from "components/Text";
import "./styles.scss";

const RightMenu = ({ modeType = "horizontal" }) => {
  const history = useHistory();
  const { isAuthenticated, user } = useContext(AuthContext);
  const { current, setCurrent, setVisible } = useContext(NavigationContext);
  const { t } = useFieldsTranslation();

  const handleClick = (e) => {
    setVisible(false);
    setCurrent({ current: e.key });
  };

  function handleSignInUp(pathName) {
    history.push(pathName);
  }

  const unauthenticatedNavBar = () => {
    return (
      <>
        {modeType === "vertical" ? (
          <>
            <Menu.Item key="/login">
              <Link to="/login">{t("nav.sign.up")}</Link>
            </Menu.Item>
            <Menu.Item key="/register">
              <Link to="/register">{t("nav.sign.in")}</Link>
            </Menu.Item>
          </>
        ) : (
          <Wrapper>
            <Button onClick={() => handleSignInUp("/login")} type="text">
              <Text rows={1} level={4}>
                {t("nav.sign.in")}
              </Text>
            </Button>
            <Button onClick={() => handleSignInUp("/register")} type="primary">
              <Text textColor={"white"} rows={1} level={4}>
                {t("nav.sign.up")}
              </Text>
            </Button>
          </Wrapper>
        )}
      </>
    );
  };

  const authenticatedNavBar = () => {
    return (
      <>
        <Avatar
          className={"hg-navbar-user-avatar"}
          onClick={() => setVisible(true)}
        >
          {getInitials(user?.fullName || user?.email)}
        </Avatar>
      </>
    );
  };

  return (
    <>
      <Menu
        onClick={handleClick}
        selectedKeys={[current.current]}
        mode={modeType}
        className={"hg-right-menu"}
      >
        {!isAuthenticated && unauthenticatedNavBar()}
      </Menu>
      <div className="hg-navbar-user-avatar-wrapper">
        {!!isAuthenticated && authenticatedNavBar()}
      </div>
    </>
  );
};

export default RightMenu;
