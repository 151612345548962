import { useContext, useRef, useMemo } from "react";
import { NavigationContext } from "context/NavigationContext";
import { useFieldsTranslation } from "utils/hooks";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import FindFoodNearMe from "components/FindFoodNearMe";
import { HomeIcon } from "components/Icons/HomeIcon";
import { HeartIcon } from "components/Icons/HeartIcon";
import Wrapper from "components/Wrapper";
import Text from "components/Text";
import smoothscroll from "smoothscroll-polyfill";

smoothscroll.polyfill();

const CenterMenu = () => {
  const { current, setCurrent, setVisible } = useContext(NavigationContext);
  const { t } = useFieldsTranslation();
  const findNearMeRef = useRef();

  const handleClick = (e) => {
    if (e.key === "/near-me") {
      findNearMeRef.current && findNearMeRef.current.geoFindMe();
    }
    setVisible(false);
    setCurrent({ current: e.key });
  };

  const currentKey = useMemo(() => {
    if (current.current.startsWith("/near-me")) {
      return "/near-me";
    }
    return current.current;
  }, [current.current]);

  function scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  return (
    <>
      <Menu
        onClick={handleClick}
        selectedKeys={[currentKey]}
        mode={"horizontal"}
      >
        <Menu.Item key="/">
          <Wrapper
            alignItems="center"
            justifyContent="space-between"
            display="flex"
            onClick={() => scrollToTop()}
          >
            <Wrapper
              alignItems="center"
              justifyContent="space-between"
              display="flex"
              mr={6}
            >
              <HomeIcon />
            </Wrapper>

            <Link to="/">{t("nav.home")}</Link>
          </Wrapper>
        </Menu.Item>
        <Menu.Item key="/favorites">
          <Wrapper
            alignItems="center"
            justifyContent="space-between"
            display="flex"
          >
            <Wrapper
              alignItems="center"
              justifyContent="space-between"
              display="flex"
              mr={6}
            >
              <HeartIcon />
            </Wrapper>

            <Link to="/favorites">{t("nav.favorites")}</Link>
          </Wrapper>
        </Menu.Item>
        <Menu.Item key="/near-me">
          <Wrapper
            alignItems="center"
            justifyContent="space-between"
            display="flex"
          >
            <Wrapper
              alignItems="center"
              justifyContent="space-between"
              display="flex"
              mr={6}
            >
              <FindFoodNearMe ref={findNearMeRef} />
            </Wrapper>
            <Text textColor="currentColor" level={4}>
              {t("nav.near.me")}
            </Text>
          </Wrapper>
        </Menu.Item>
      </Menu>
    </>
  );
};

export default CenterMenu;
