import { useFieldsTranslation } from "utils/hooks";
import Wrapper from "../../Wrapper";
import Text from "../../Text";
import { MoreIcon } from "../../Icons/MoreIcon";

const More = ({ onClick = () => {}, active = false }) => {
  const { t } = useFieldsTranslation();

  return (
    <Wrapper
      onClick={onClick}
      display="flex"
      alignItems="center"
      flexDirection="column"
    >
      <MoreIcon style={{ color: !!active ? "#fb5b4e" : null }} />
      <Text level={5} textColor={!!active ? "primary" : null}>
        {t("nav.more")}
      </Text>
    </Wrapper>
  );
};

export default More;
