import { useTranslation } from "react-i18next";
import { Select } from "antd";
const { Option } = Select;

const LngDropDown = ({ languages }) => {
  const defaultLng = localStorage.getItem("lng") || "en";
  const [, i18n] = useTranslation();

  function handleLngChange(lng) {
    localStorage.setItem("lng", lng);
    i18n.changeLanguage(lng);
  }

  return (
    <Select defaultValue={defaultLng} onChange={handleLngChange}>
      {languages.map((language) => {
        return (
          <Option key={language.name} value={language.name}>
            <img src={language.icon} />
          </Option>
        );
      })}
    </Select>
  );
};
export default LngDropDown;
