import Icon from "@ant-design/icons";

const TikTokIconSvg = () => (
  <svg
    width="22"
    height="24"
    viewBox="0 0 22 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.905 6.01831C20.5353 6.01831 19.2716 5.56456 18.2567 4.79909C17.0928 3.92159 16.2566 2.6344 15.9613 1.15081C15.8881 0.784248 15.8488 0.405967 15.845 0.0183105H11.9324V10.7096L11.9277 16.5657C11.9277 18.1313 10.9081 19.4588 9.49485 19.9257C9.0847 20.0611 8.64173 20.1253 8.18048 20.1C7.59173 20.0677 7.04001 19.89 6.56048 19.6032C5.54001 18.9928 4.84813 17.8857 4.82938 16.6191C4.79985 14.6396 6.40016 13.0257 8.37829 13.0257C8.76876 13.0257 9.14376 13.0894 9.49485 13.2052V10.283V9.23253C9.12454 9.17769 8.74767 9.14909 8.36657 9.14909C6.20141 9.14909 4.17641 10.0491 2.72891 11.6705C1.63485 12.8958 0.9786 14.4591 0.87735 16.0983C0.744694 18.2517 1.53266 20.2988 3.06079 21.8091C3.28532 22.0308 3.5211 22.2366 3.76766 22.4264C5.07782 23.4347 6.67954 23.9813 8.36657 23.9813C8.74767 23.9813 9.12454 23.9532 9.49485 23.8983C11.0708 23.6649 12.5249 22.9435 13.6724 21.8091C15.0824 20.4155 15.8614 18.5653 15.8699 16.5961L15.8497 7.85112C16.5224 8.37003 17.2578 8.7994 18.0472 9.13269C19.2749 9.65065 20.5766 9.91315 21.9163 9.91269V7.07159V6.01737C21.9172 6.01831 21.906 6.01831 21.905 6.01831V6.01831Z"
      fill="#585766"
    />
  </svg>
);

export const TikTokIcon = (props) => (
  <Icon component={TikTokIconSvg} {...props} />
);
