import Loading from "../Loading";
import "./styles.scss";

const Container = ({ loading, children }) => {
  if (loading) {
    return <Loading />;
  }
  return <div className="mainContainer">{children}</div>;
};

export default Container;
