import { useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { NavigationContext } from "context/NavigationContext";
import Home from "./components/Home";
import Favorites from "./components/Favorites";
import More from "./components/More";
import NearMe from "./components/NearMe";
import smoothscroll from "smoothscroll-polyfill";
import "./styles.scss";

smoothscroll.polyfill();

const NavigationBarMobile = () => {
  const location = useLocation();
  const history = useHistory();
  const { visible, setVisible } = useContext(NavigationContext);

  function scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  const handleMenuItemClick = (name) => {
    switch (name) {
      case "home":
        return scrollToTop(), history.push("/");
      case "favorites":
        return history.push("/favorites"), scrollToTop();
      case "more":
        return setVisible(true);
      default:
        null;
    }
  };
  return (
    <div className="hg-navigation-bar-mobile-container">
      <div className="hg-navigation-bar-mobile-wrapper">
        <Home
          active={location.pathname === "/"}
          onClick={() => handleMenuItemClick("home")}
        />

        <Favorites
          active={location.pathname === "/favorites"}
          onClick={() => handleMenuItemClick("favorites")}
        />

        <NearMe
          active={location.pathname.includes("near-me")}
          onClick={() => handleMenuItemClick("near-me")}
        />

        <More active={visible} onClick={() => handleMenuItemClick("more")} />
      </div>
    </div>
  );
};

export default NavigationBarMobile;
