import FoodSubcategoryForm from "./Form";
import Layout from "layout";

const CreateSubcategory = () => {
  return (
    <Layout type="xs">
      <FoodSubcategoryForm />
    </Layout>
  );
};

export default CreateSubcategory;
