export default {
  add: (type) => {
    return fetch("/backend/restaurant-category", {
      method: "POST",
      body: JSON.stringify(type),
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      if (response.status !== 401) {
        return response.json().then((data) => data);
      } else return { message: { msgBody: "UnAuthorized" }, msgError: true };
    });
  },
  get: (params) => {
    return fetch(
      "/backend/restaurant-category?" + new URLSearchParams(params)
    ).then((response) => {
      if (response.status !== 401) {
        return response.json().then((data) => data);
      } else return { message: { msgBody: "UnAuthorized" }, msgError: true };
    });
  },
  update: async ({ id, updatedData }) => {
    return fetch(`/backend/restaurant-category/${id}`, {
      method: "PATCH",
      body: JSON.stringify(updatedData),
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      if (response.status !== 401) {
        return response.json().then((data) => data);
      } else return { message: { msgBody: "UnAuthorized" }, msgError: true };
    });
  },
};
