import RestaurantCategoryForm from "./Form";
import Layout from "layout";

const CreateCategory = () => {
  return (
    <Layout type="xs">
      <RestaurantCategoryForm />
    </Layout>
  );
};

export default CreateCategory;
