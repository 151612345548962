import { Skeleton, Row, Col } from "antd";
import FoodCardList from "../../FoodCardList";
import Wrapper from "../../Wrapper";
import { resizeArray } from "utils/helper";
import "./styles.scss";

const TabBarSkeleton = ({ withContentCard = false, contentCardCol }) => {
  const arr = resizeArray(4);

  return (
    <>
      <div className="hg-tab-bar-skeleton-wrapper">
        {arr.map((item, index) => {
          return <span key={index}></span>;
        })}
      </div>
      {!!withContentCard && (
        <Wrapper py={4}>
          <FoodCardList
            skeletonCardCount={4}
            scrollX={false}
            scrollYCol={contentCardCol}
            loading={true}
            scrollX={false}
          />
        </Wrapper>
      )}
    </>
  );
};

export default TabBarSkeleton;
