import { memo } from "react";
import { useFieldsTranslation } from "utils/hooks";
import { FoodInPlateIcon } from "../../../components/Icons/FoodInPlateIcon";
import { Button } from "antd";
import Wrapper from "components/Wrapper";
import Text from "components/Text";
import { useHistory } from "react-router-dom";
import "./styles.scss";

const EmptyMessage = memo(({ match }) => {
  const { t } = useFieldsTranslation();
  const history = useHistory();

  function handleClick() {
    history.push("/more-suggestions/weekly");
  }
  return (
    <>
      <FoodInPlateIcon />
      <Wrapper mt={2} mb={3} textAlign="center">
        <Text bold level={2} rows={1}>
          {t("fav.empty.message.fill.your.plate")}
        </Text>
        <Text bold level={2} rows={1} textColor="primary">
          {t("fav.empty.message.tasty.food")}
        </Text>
      </Wrapper>
      <Button onClick={handleClick} size="large" type="primary">
        {t("fav.empty.message.check.food.of.day")}
      </Button>
    </>
  );
});

export default EmptyMessage;
