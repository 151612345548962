export default {
  get: async (params) => {
    return fetch("/backend/user?" + new URLSearchParams(params)).then(
      (response) => {
        if (response.status !== 401) {
          return response.json().then((data) => data);
        } else return { message: { msgBody: "UnAuthorized" }, msgError: true };
      }
    );
  },

  getById: async (id) => {
    return fetch(`/backend/user/${id}`).then((response) => {
      if (response.status !== 401) {
        return response.json().then((data) => data);
      } else return { message: { msgBody: "UnAuthorized" }, msgError: true };
    });
  },

  getCount: async () => {
    return fetch("/backend/user/get/count").then((response) => {
      if (response.status !== 401) {
        return response.json().then((data) => data);
      } else return { message: { msgBody: "UnAuthorized" }, msgError: true };
    });
  },
};
