import Icon from "@ant-design/icons";

const HungryguysIconSvg = () => (
  <svg width="135" height="24" viewBox="0 0 135 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.18065 4.39359C5.59599 4.39359 5.03918 4.47597 4.48236 4.61327V2.22426C4.48236 0.988557 3.4801 0 2.2551 0C1.00227 0 0 0.988557 0 2.22426V16.0915C0 17.2998 1.00227 18.2883 2.2551 18.2883C3.4801 18.2883 4.48236 17.2998 4.48236 16.0915V10.4897C4.48236 9.55606 5.2619 8.78718 6.18065 8.78718C7.12723 8.78718 7.90678 9.55606 7.90678 10.4897V16.0915C7.90678 17.2998 8.90904 18.2883 10.134 18.2883C11.359 18.2883 12.3613 17.2998 12.3613 16.0915V10.4897C12.3613 7.11213 9.60506 4.39359 6.18065 4.39359Z" fill="#FB5B4E"/>
    <path d="M20.046 18.508C16.6494 18.508 13.8932 15.7895 13.8932 12.4394V6.72769C13.8932 5.54691 14.8676 4.55835 16.1205 4.55835C17.3454 4.55835 18.3199 5.54691 18.3199 6.72769V12.4394C18.3199 13.373 19.0994 14.1419 20.046 14.1419C20.9926 14.1419 21.7721 13.373 21.7721 12.4394V6.72769C21.7721 5.54691 22.7466 4.55835 23.9715 4.55835C25.2244 4.55835 26.1988 5.54691 26.1988 6.72769V12.4394C26.1988 15.7895 23.4426 18.508 20.046 18.508Z" fill="#FB5B4E"/>
    <path d="M34.2648 4.42105C33.2904 4.42105 32.3716 4.64073 31.5642 5.02517C31.2023 4.75057 30.729 4.55835 30.2279 4.55835C29.0586 4.55835 28.1398 5.49199 28.1398 6.64531V16.119C28.1398 17.2998 29.1143 18.2883 30.3393 18.2883C31.5642 18.2883 32.5387 17.2998 32.5387 16.119V10.4622C32.5387 9.5286 33.3182 8.78718 34.2648 8.78718C35.2114 8.78718 35.9631 9.5286 35.9631 10.4622V16.119C35.9631 17.2998 36.9654 18.2883 38.1903 18.2883C39.4153 18.2883 40.3898 17.2998 40.3898 16.119V10.4622C40.3898 7.13959 37.6614 4.42105 34.2648 4.42105Z" fill="#FB5B4E"/>
    <path d="M54.1644 4.47597C53.4962 4.47597 52.8837 4.77803 52.494 5.27231C51.5196 4.75057 50.3781 4.44851 49.1809 4.44851C45.2275 4.44851 42.0537 7.60641 42.0537 11.4783C42.0537 15.3501 45.2275 18.4806 49.1809 18.508C50.1275 18.508 51.0463 18.3158 51.8815 17.9863C51.2133 19.6888 49.348 20.1831 48.0116 19.5515C46.9537 19.0572 45.673 19.4966 45.1719 20.5675C44.6986 21.611 45.144 22.8467 46.202 23.341C47.1486 23.7803 48.1508 24 49.2088 24C53.1065 24 56.3082 20.8696 56.3082 17.0252V6.56293C56.3082 5.40961 55.3616 4.47597 54.1644 4.47597ZM49.1809 14.087C47.7054 14.087 46.5082 12.9336 46.5082 11.4783C46.5082 10.0229 47.7054 8.84211 49.1809 8.84211C50.6286 8.84211 51.8258 10.0229 51.8258 11.4783C51.8258 12.9336 50.6286 14.087 49.1809 14.087Z" fill="#FB5B4E"/>
    <path d="M64.9675 4.47597C64.4107 4.47597 63.1579 4.50343 62.0164 5.08009C61.6266 4.75057 61.1533 4.55835 60.6244 4.55835C59.455 4.55835 58.5363 5.49199 58.5363 6.61785V16.119C58.5363 17.3272 59.5107 18.2883 60.7357 18.2883C61.9607 18.2883 62.9351 17.3272 62.9351 16.119V10.5172C62.9351 9.58352 63.7425 8.86957 64.7169 8.84211C65.9419 8.81465 66.9999 7.96339 67.0556 6.75515C67.1112 5.54691 66.1925 4.50343 64.9675 4.47597Z" fill="#FB5B4E"/>
    <path d="M79.9271 4.66819C78.7578 4.17391 77.505 4.75057 77.0039 5.84897L75.1107 10.4622L73.1897 5.84897C72.7442 4.75057 71.4357 4.20137 70.2942 4.66819C69.1806 5.13501 68.6238 6.39817 69.0692 7.52403L73.0226 16.9703L72.605 17.9314C72.1317 19.0297 71.0738 19.6888 69.8488 19.6613C68.7908 19.6613 67.8999 20.4851 67.8999 21.5561C67.8721 22.9565 69.0971 23.5057 69.9323 23.5057C72.2709 23.5057 74.3868 22.2975 75.5283 20.595C76.0573 19.7986 76.447 18.865 76.8368 17.9039L81.1243 7.52403C81.5976 6.39817 81.0686 5.13501 79.9271 4.66819Z" fill="#FB5B4E"/>
    <path d="M94.2399 4.47597C93.5717 4.47597 92.9592 4.77803 92.5695 5.27231C91.595 4.75057 90.4536 4.44851 89.2564 4.44851C85.303 4.44851 82.1292 7.60641 82.1292 11.4783C82.1292 15.3501 85.303 18.4806 89.2564 18.508C90.203 18.508 91.1217 18.3158 91.9569 17.9863C91.2888 19.6888 89.4234 20.1831 88.0871 19.5515C87.0291 19.0572 85.7485 19.4966 85.2473 20.5675C84.774 21.611 85.2195 22.8467 86.2774 23.341C87.224 23.7803 88.2263 24 89.2842 24C93.1819 24 96.3836 20.8696 96.3836 17.0252V6.56293C96.3836 5.40961 95.437 4.47597 94.2399 4.47597ZM89.2564 14.087C87.7808 14.087 86.5837 12.9336 86.5837 11.4783C86.5837 10.0229 87.7808 8.84211 89.2564 8.84211C90.7041 8.84211 91.9013 10.0229 91.9013 11.4783C91.9013 12.9336 90.7041 14.087 89.2564 14.087Z" fill="#FB5B4E"/>
    <path d="M104.765 18.508C101.368 18.508 98.6118 15.7895 98.6118 12.4394V6.72769C98.6118 5.54691 99.5862 4.55835 100.839 4.55835C102.064 4.55835 103.038 5.54691 103.038 6.72769V12.4394C103.038 13.373 103.818 14.1419 104.765 14.1419C105.711 14.1419 106.491 13.373 106.491 12.4394V6.72769C106.491 5.54691 107.465 4.55835 108.69 4.55835C109.943 4.55835 110.917 5.54691 110.917 6.72769V12.4394C110.917 15.7895 108.161 18.508 104.765 18.508Z" fill="#FB5B4E"/>
    <path d="M123.483 4.66819C122.313 4.17391 121.061 4.75057 120.559 5.84897L118.666 10.4622L116.745 5.84897C116.3 4.75057 114.991 4.20137 113.85 4.66819C112.736 5.13501 112.179 6.39817 112.625 7.52403L116.578 16.9703L116.161 17.9314C115.687 19.0297 114.629 19.6888 113.404 19.6613C112.346 19.6613 111.455 20.4851 111.455 21.5561C111.428 22.9565 112.653 23.5057 113.488 23.5057C115.826 23.5057 117.942 22.2975 119.084 20.595C119.613 19.7986 120.003 18.865 120.392 17.9039L124.68 7.52403C125.153 6.39817 124.624 5.13501 123.483 4.66819Z" fill="#FB5B4E"/>
    <path d="M131.712 8.18307C131.184 8.01831 130.404 7.82609 130.265 8.4302C130.126 8.9794 131.128 9.254 131.545 9.33638C132.381 9.50114 133.188 9.96796 133.773 10.5995C135.053 11.9176 135.332 14.0046 134.608 15.6522C133.856 17.3547 132.13 18.3707 130.293 18.4805C129.262 18.5355 127.119 18.2609 126.256 17.135C125.17 15.7071 126.228 13.7025 127.982 13.7849C128.678 13.8124 129.43 14.3341 130.014 14.3616C131.016 14.4439 131.239 13.2357 130.293 12.9886C128.539 12.4668 127.258 11.7803 126.451 10.1876C125.866 8.97941 126.005 7.46911 126.84 6.37071C128.177 4.58581 130.71 3.98169 132.798 4.77803C133.912 5.21739 134.831 6.34325 134.135 7.55149C133.633 8.37529 132.77 8.54005 131.712 8.18307Z" fill="#FB5B4E"/>
  </svg>

);

export const HungryguysIcon = (props) => (
  <Icon component={HungryguysIconSvg} {...props} />
);
