import { memo } from "react";
import Layout from "layout";
import { useFieldsTranslation } from "utils/hooks";
import Wrapper from "components/Wrapper";
import MainBannerSection from "./Sections/MainBannerSection";
import MoreSuggestionsList from "./Sections/MoreSuggestionsList";

const MoreSuggestions = memo(({ match }) => {
  const { t } = useFieldsTranslation();

  return (
    <Layout type="sm">
      <MainBannerSection />
      <Wrapper mb={2}>
        <MoreSuggestionsList match={match} />
      </Wrapper>
    </Layout>
  );
});

export default MoreSuggestions;
