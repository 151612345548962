import {
  useContext,
  useState,
  useCallback,
  useImperativeHandle,
  forwardRef,
} from "react";
import AuthService from "../../services/AuthService";
import { Form, Input, Button, Modal, Divider } from "antd";
import { feedbackMessage } from "../../utils/helper";
import LoginWithFacbook from "../LoginWithFacebook";
import LoginWithGoogle from "../LoginWithGoogle";
import { CloseIcon } from "../Icons/CloseIcon";
import { AuthContext } from "../../context/AuthContext";
import { useFieldsTranslation } from "utils/hooks";
import Wrapper from "components/Wrapper";
import Text from "components/Text";
import "./styles.scss";

const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};
const tailLayout = {
  style: {
    textAlign: "center",
  },
};

const LoginModal = forwardRef(({ onLogin, onFailure }, ref) => {
  const authContext = useContext(AuthContext);
  const { t } = useFieldsTranslation();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  useImperativeHandle(
    ref,
    () => ({
      open: () => {
        setVisible(true);
      },
    }),
    []
  );

  const close = useCallback(() => {
    setVisible(false);
  }, []);

  const onFinish = (value) => {
    const localLoginLoadin =
      value.provider !== "facebook" && value.provider !== "google";
    setLoading(localLoginLoadin);
    AuthService.login(value).then((data) => {
      const { isAuthenticated, user, message } = data;
      if (isAuthenticated) {
        authContext.setUser(user);
        authContext.setIsAuthenticated(isAuthenticated);
        close();
        onLogin && onLogin(user);
        setLoading(false);
      } else {
        feedbackMessage({ status: message.status, message: message.msgBody });
      }
      setLoading(false);
    });
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onLoginFacebook = (user) => {
    onFinish(user);
  };

  const onLoginGoogle = (user) => {
    onFinish(user);
  };

  const onLocalFinish = (value) => {
    const user = {
      email: value.email,
      password: value.password,
      provider: "local",
    };
    onFinish(user);
  };

  return (
    <Modal
      className="hg-signin-modal"
      closable={true}
      closeIcon={<CloseIcon />}
      visible={visible}
      onCancel={() => {
        onFailure();
        close();
      }}
      zIndex={9999}
      footer={null}
    >
      <div className="hg-signin-container">
        <Wrapper mb={3}>
          <Wrapper mb={7} mt={4}>
            <Text bold level={1}>
              {t("login.modal.sign.in")}
            </Text>
          </Wrapper>
          <Text level={4} rows={3}>
            {t("login.modal.fav.text")}
          </Text>
        </Wrapper>
        <Form
          {...layout}
          name="basic"
          onFinish={onLocalFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label={
              <Text level={4} textColor="dark-90" bold>
                {t("login.modal.email")}
              </Text>
            }
            name="email"
            rules={[
              {
                required: true,
                message: "Please input email!",
              },
            ]}
          >
            <Input size="large" placeholder="Enter Email" />
          </Form.Item>
          <Form.Item
            label={
              <Text level={4} textColor="dark-90" bold>
                {t("login.modal.password")}
              </Text>
            }
            name="password"
            rules={[
              {
                required: true,
                message: t("login.modal.password.valid.message"),
              },
            ]}
          >
            <Input.Password
              size="large"
              placeholder={t("login.modal.password.placeholder")}
              type="password"
            />
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Button
              loading={loading}
              size="large"
              type="primary"
              htmlType="submit"
              style={{ width: "100%" }}
            >
              {t("login.modal.sign.in")}
            </Button>
          </Form.Item>
        </Form>
        <Divider />
        <Wrapper
          mb={8}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Text textColor="dark-90" level={4}>
            {t("login.modal.sign.up.versions")}
          </Text>
          <Wrapper
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Wrapper mx={8}>
              <LoginWithFacbook onLogin={onLoginFacebook} />
            </Wrapper>
            <Wrapper>
              <LoginWithGoogle onLogin={onLoginGoogle} />
            </Wrapper>
          </Wrapper>
        </Wrapper>
      </div>
    </Modal>
  );
});

export default LoginModal;
