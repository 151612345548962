import { Popover } from "antd";
import { CallingIcon } from "../Icons/CallingIcon";
import "./styles.scss";

const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(
  navigator.userAgent
);

const Calling = ({ text, phone }) => {
  return (
    <div className="hg-calling-container">
      {!!isMobile ? (
        <a onClick={(e) => e.stopPropagation()} href={`tel:${phone}`}>
          <CallingIcon />
          <span className="hg-calling-text-wrapper">{text}</span>
        </a>
      ) : (
        <Popover
          zIndex={9999}
          className={"hg-phone-popover"}
          content={phone}
          trigger="hover"
          onClick={(e) => e.stopPropagation()}
        >
          <CallingIcon />
        </Popover>
      )}
    </div>
  );
};

export default Calling;
