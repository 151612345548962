import { useState, useEffect, memo } from "react";
import { useHistory } from "react-router-dom";
import { useFieldsTranslation } from "utils/hooks";
import { formatImgUrl } from "utils/helper";
import FoodCardList from "components/FoodCardList";
import FoodService from "services/FoodService";
import { ArrowRight } from "components/Icons/ArrowRight";
import Wrapper from "components/Wrapper";
import Text from "components/Text";

const HealthyFoodSection = memo(() => {
  const [dietFood, setDietFood] = useState([]);
  const history = useHistory();
  const { t, tField } = useFieldsTranslation();

  const conditionDietFood = {
    populate: JSON.stringify(["restaurantId"]),
    where: JSON.stringify({
      diet: true,
    }),
    sort: JSON.stringify({
      foodOrder: "asc",
    }),
    limit: 10,
  };

  useEffect(() => {
    FoodService.get(conditionDietFood).then((data) => {
      setDietFood(data.food);
    });
  }, []);

  function seeAll() {
    history.push("more-suggestions/diet");
  }
  return (
    <>
      <Wrapper pb={3}>
        <Wrapper
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Text level={2} semiBold={true}>
            {t("healthy.section.healthy.food")}
          </Text>
          <Wrapper
            cursorPointer
            backgroundColor="light-50"
            py={7}
            px={5}
            display="flex"
            alignItems="center"
            borderRadius="base"
            onClick={() => seeAll()}
          >
            <Wrapper mr={7}>
              <Text rows={1} level={3} textColor="dark-90">
                {t("more")}
              </Text>
            </Wrapper>
            <ArrowRight />
          </Wrapper>
        </Wrapper>
      </Wrapper>
      <FoodCardList
        scrollX={true}
        loading={!dietFood?.length}
        food={dietFood?.map((item) => {
          return {
            id: item._id,
            name: tField(item, "name"),
            image: formatImgUrl(item.image),
            price: item.price,
            rateData: {
              feedbackType: "food",
              entityId: item._id,
              rate: item.rating,
              maxRate: 5,
            },
            phone: item.restaurantId.phone,
            restaurantName: tField(item.restaurantId, "name"),
            restaurantIcon: formatImgUrl(item.restaurantId.icon),
            currency: t(item.currency),
            insideCombo: item.insideCombo,
            isCombo: item.isCombo,
            whatsInside: item.whatsInside,
            location: item.restaurantId.address[0].name,
            restaurantId: item.restaurantId._id,
          };
        })}
      />
    </>
  );
});

export default HealthyFoodSection;
