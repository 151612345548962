import { memo } from "react";
import Layout from "layout";
import { useFieldsTranslation } from "utils/hooks";
import Wrapper from "components/Wrapper";
import MainBannerSection from "./Sections/MainBannerSection";
import NearFoodList from "./Sections/NearFoodList";

const FindNearMe = memo(({ match }) => {
  const { t } = useFieldsTranslation();

  return (
    <Layout type="sm">
      <MainBannerSection />
      <Wrapper mb={2}>
        <NearFoodList match={match} />
      </Wrapper>
    </Layout>
  );
});

export default FindNearMe;
