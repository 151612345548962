import { memo } from "react";
import MainBannerSection from "./MainBannerSection";
import Layout from "layout";
import Wrapper from "components/Wrapper";
import TopRatedFoodList from "./TopRatedFoodList";

export default memo(() => {
  return (
    <Layout type="sm">
      <MainBannerSection />
      <Wrapper mb={2}>
        <TopRatedFoodList />
      </Wrapper>
    </Layout>
  );
});
