import Icon from "@ant-design/icons";

const BadRateEmojiSvg = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#FFD93B"
      />
      <path
        d="M23.9999 12C23.9999 18.63 18.6262 24 11.9999 24C8.22744 24 4.85994 22.26 2.65869 19.5338C4.71744 21.1988 7.33869 22.1925 10.1924 22.1925C16.8187 22.1925 22.1924 16.8225 22.1924 10.1925C22.1924 7.33875 21.1987 4.7175 19.5337 2.65875C22.2562 4.86 23.9999 8.2275 23.9999 12Z"
        fill="#F4C534"
      />
      <path
        d="M15.583 18.0375C15.583 18.3877 15.3032 18.6622 14.9582 18.6622H9.04296C8.69796 18.6622 8.41821 18.387 8.41821 18.0375C8.41821 17.6925 8.69796 17.4127 9.04296 17.4127H14.9582C15.3025 17.4127 15.583 17.6925 15.583 18.0375Z"
        fill="#3E4347"
      />
      <path
        d="M5.24112 12.2662C5.53437 12.0937 6.40212 13.0237 7.76487 13.0747C9.17562 13.1272 9.99537 12.0937 10.2886 12.2662C10.6539 12.3915 10.1559 14.7765 7.76487 14.7855C5.37462 14.7765 4.87587 12.3915 5.24112 12.2662Z"
        fill="#3E4347"
      />
      <path
        d="M4.67857 11.2178C4.40632 11.1743 4.22032 10.9178 4.26457 10.6448C4.30882 10.3725 4.56082 10.1858 4.83757 10.2308C7.64857 10.6823 8.50282 8.6745 8.51107 8.65425C8.61457 8.3985 8.90557 8.2755 9.16282 8.37975C9.41857 8.484 9.54156 8.77575 9.43731 9.0315C8.87332 10.413 7.10182 11.607 4.67857 11.2178Z"
        fill="#E9B02C"
      />
      <path
        d="M18.7589 12.2662C18.4656 12.0937 17.5979 13.0237 16.2351 13.0747C14.8244 13.1272 14.0046 12.0937 13.7114 12.2662C13.3461 12.3915 13.8441 14.7765 16.2351 14.7855C18.6254 14.7765 19.1241 12.3915 18.7589 12.2662Z"
        fill="#3E4347"
      />
      <path
        d="M14.5627 9.03072C14.4585 8.77497 14.5815 8.48322 14.8372 8.37897C15.0922 8.27472 15.3832 8.39697 15.4882 8.65122C15.5257 8.74122 16.3845 10.677 19.1625 10.2307C19.4377 10.1857 19.692 10.3725 19.7355 10.6447C19.779 10.9177 19.5937 11.1742 19.3215 11.2177C16.9155 11.6047 15.1312 10.425 14.5627 9.03072Z"
        fill="#E9B02C"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const BadRateEmoji = (props) => (
  <Icon component={BadRateEmojiSvg} {...props} />
);
