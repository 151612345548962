import PropTypes from "prop-types";
import CategorySelectorSkeleton from "../Skeletons/CategorySelectorSkeleton";
import { Link } from "react-router-dom";
import Text from "../Text";
import "./styles.scss";

const CategorySelector = ({ title, icon, link, skeleton }) => {
  if (skeleton) {
    return <CategorySelectorSkeleton />;
  }
  return (
    <>
      <Link to={link}>
        <div className={"hg-category-selector-container"}>
          <img alt={title} title={title} src={icon} />
          <Text level={3}>{title}</Text>
        </div>
      </Link>
    </>
  );
};
CategorySelector.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  link: PropTypes.string,
  skeleton: PropTypes.bool,
};

CategorySelector.defaultProps = {
  icon: "",
  title: "",
  propTypes: "",
  skeleton: false,
};

export default CategorySelector;
