import {
  useState,
  useRef,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useCallback,
  useContext,
} from "react";
import { Form, Input, Button, Upload, Select, Modal, Divider } from "antd";
import { useFieldsTranslation } from "utils/hooks";
import { roles } from "utils/roles";
import { feedbackMessage } from "utils/helper";
import LoginWithFacbook from "components/LoginWithFacebook";
import LoginWithGoogle from "components/LoginWithGoogle";
import { AuthContext } from "context/AuthContext";
import AuthService from "services/AuthService";
import Wrapper from "../Wrapper";
import Text from "components/Text";
import { CloseIcon } from "../Icons/CloseIcon";
import "./styles.scss";

const { Option } = Select;

const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};
const tailLayout = {
  style: {
    textAlign: "center",
  },
};

const Register = forwardRef(({ history }, ref) => {
  const [form] = Form.useForm();
  const { t } = useFieldsTranslation();
  const authContext = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  let timerID = useRef(null);

  useImperativeHandle(
    ref,
    () => ({
      open: () => {
        setVisible(true);
      },
    }),
    []
  );

  const close = useCallback(() => {
    setVisible(false);
  }, []);

  useEffect(() => {
    return () => {
      clearTimeout(timerID);
    };
  }, []);

  const resetForm = () => {
    form.resetFields();
  };

  const onFinish = (value) => {
    const localLoginLoadin =
      value.provider !== "facebook" && value.provider !== "google";
    setLoading(localLoginLoadin);
    const registerUser = {
      email: value.email,
      firstName: value.firstName,
      lastName: value.lastName,
      phoneNumber: value.phoneNumber,
      password: value.password,
    };
    AuthService.register(registerUser).then((data) => {
      const { message } = data;
      feedbackMessage({ status: message.status, message: message.msgBody });
      resetForm();
      if (!message.msgError) {
        timerID = setTimeout(() => {
          history.push("/login");
        }, 2000);
      }
      setLoading(false);
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onLoginFacebook = (value) => {
    AuthService.login(value).then((data) => {
      const { isAuthenticated, user, message } = data;
      if (isAuthenticated) {
        authContext.setUser(user);
        authContext.setIsAuthenticated(isAuthenticated);
        history.push("/");
        close();
        setLoading(false);
      } else
        feedbackMessage({ status: message.status, message: message.msgBody });
      setLoading(false);
    });
  };
  const onLoginGoogle = (value) => {
    AuthService.login(value).then((data) => {
      const { isAuthenticated, user, message } = data;
      if (isAuthenticated) {
        authContext.setUser(user);
        authContext.setIsAuthenticated(isAuthenticated);
        history.push("/");
        close();
        setLoading(false);
      } else
        feedbackMessage({ status: message.status, message: message.msgBody });
      setLoading(false);
    });
  };

  return (
    <Modal
      closable={true}
      visible={visible}
      onCancel={close}
      footer={null}
      closeIcon={<CloseIcon />}
      className={"hg-signup-modal"}
    >
      <Form
        form={form}
        {...layout}
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label={t("reg.email")}
          name="email"
          rules={[
            {
              required: true,
              message: t("reg.valid.email.message"),
            },
          ]}
        >
          <Input size="large" placeholder="Enter Email" />
        </Form.Item>
        <Form.Item
          label={t("First Name")}
          name="firstName"
          rules={[
            {
              required: true,
              message: t("reg.valid.firstname.message"),
            },
          ]}
        >
          <Input size="large" placeholder={t("reg.firstname.placeholder")} />
        </Form.Item>
        <Form.Item
          label={t("reg.lastname")}
          name="lastName"
          rules={[
            {
              required: true,
              message: t("reg.lastname.valid.message"),
            },
          ]}
        >
          <Input size="large" placeholder={t("reg.lastname.placheholder")} />
        </Form.Item>
        <Form.Item
          label={t("reg.phone.number")}
          name="phoneNumber"
          rules={[
            {
              required: true,
              message: t("reg.phone.number.valid.message"),
            },
          ]}
        >
          <Input size="large" placeholder={t("reg.phone.number.placeholder")} />
        </Form.Item>

        <Form.Item
          label={t("reg.password")}
          name="password"
          rules={[
            {
              required: true,
              message: t("reg.password.valid.message"),
            },
          ]}
        >
          <Input
            size="large"
            placeholder={t("reg.password.placeholder")}
            type="password"
          />
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Button
            loading={loading}
            size="large"
            type="primary"
            htmlType="submit"
            style={{ width: "100%" }}
          >
            {t("reg.sign.up")}
          </Button>
        </Form.Item>
      </Form>
      <Divider />
      <Wrapper
        mb={5}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Text textColor="dark-90" level={4}>
          {t("reg.sign.up.versions")}
        </Text>
        <Wrapper
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Wrapper mx={8}>
            <LoginWithFacbook onLogin={onLoginFacebook} />
          </Wrapper>
          <Wrapper>
            <LoginWithGoogle onLogin={onLoginGoogle} />
          </Wrapper>
        </Wrapper>
      </Wrapper>
    </Modal>
  );
});

export default Register;
