import { useContext, useEffect, useState } from "react";
import Food from "services/FoodService";
import { feedbackMessage } from "utils/helper";
import Wrapper from "components/Wrapper";
import Text from "components/Text";
import { Button, Select, Row, Col, Switch } from "antd";

const { Option } = Select;
const sections = ["weekly", "new", "diet", "isCombo"];

const SectionShuffleButton = () => {
  const [sectionName, setSectionName] = useState();

  const updateSection = () => {
    if (sectionName) {
      Food.shuffleSection({ section: sectionName }).then((res) => {
        feedbackMessage({
          status: res.message.status,
          message: res.message.msgBody,
        });
        setSectionName();
      });
    }
  };

  const handleSectionChange = (name) => {
    setSectionName(name);
  };

  return (
    <>
      <Wrapper mb={4}>
        <Text level={2}>Update home page sections</Text>
      </Wrapper>
      <Row gutter={[16, 16]}>
        <Col>
          <Select
            value={sectionName}
            onChange={handleSectionChange}
            placeholder="select updated section name"
            style={{ width: 200 }}
          >
            {sections.map((section) => (
              <Option key={section}>{section}</Option>
            ))}
          </Select>
        </Col>
        <Col>
          <Button onClick={updateSection} type="primary">
            {`update ${!!sectionName ? sectionName : " "} section`}
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default SectionShuffleButton;
