import {
  createContext,
  useContext,
  useState,
  useEffect,
} from "react";
import smoothscroll from "smoothscroll-polyfill";
import { AuthContext } from "context/AuthContext";
import FavoriteService from "../services/FavoriteService";

smoothscroll.polyfill();

export const FavoritesContext = createContext();
export default ({ children }) => {
  const [favorites, setFavorites] = useState([]);
  const { isAuthenticated, user = {} } = useContext(AuthContext);

  useEffect(() => {
    updateUserFavorites();
  }, [user]);

  function updateUserFavorites() {
    if (user?.id) {
      const queryParams = {
        where: JSON.stringify({
          userId: user.id,
        }),
      };
      FavoriteService.get(queryParams).then((item) =>
        setFavorites(item.favorites)
      );
    } else {
      setFavorites([]);
    }
  }


  return (
    <FavoritesContext.Provider
      value={{
        favorites,
        user,
        isAuthenticated,
        setFavorites,
      }}
    >
      {children}
    </FavoritesContext.Provider>
  );
};
