export default {
  add: async (restaurant) => {
    return fetch("/backend/restaurants", {
      method: "POST",
      body: JSON.stringify(restaurant),
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      if (response.status !== 401) {
        return response.json().then((data) => data);
      } else return { message: { msgBody: "UnAuthorized" }, msgError: true };
    });
  },

  getById: async (id) => {
    return fetch(`/backend/restaurants/${id}`).then((response) => {
      if (response.status !== 401) {
        return response.json().then((data) => data);
      } else return { message: { msgBody: "UnAuthorized" }, msgError: true };
    });
  },

  update: async ({ id, restaurantData }) => {
    return fetch(`/backend/restaurants/${id}`, {
      method: "PATCH",
      body: JSON.stringify(restaurantData),
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      if (response.status !== 401) {
        return response.json().then((data) => data);
      } else return { message: { msgBody: "UnAuthorized" }, msgError: true };
    });
  },
  get: async (params) => {
    return fetch("/backend/restaurants?" + new URLSearchParams(params)).then(
      (response) => {
        if (response.status !== 401) {
          return response.json().then((data) => data);
        } else return { message: { msgBody: "UnAuthorized" }, msgError: true };
      }
    );
  },
  getRestaurantsGroupByCountry: async (params) => {
    return fetch(
      "/backend/restaurants/by/country?" + new URLSearchParams(params)
    ).then((response) => {
      if (response.status !== 401) {
        return response.json().then((data) => data);
      } else return { message: { msgBody: "UnAuthorized" }, msgError: true };
    });
  },

  getRestaurantFoodSubcategories: async (id) => {
    return fetch(`/backend/restaurants/food/subcategories/${id}`).then(
      (response) => {
        if (response.status !== 401) {
          return response.json().then((data) => data);
        } else return { message: { msgBody: "UnAuthorized" }, msgError: true };
      }
    );
  },
  getCount: async () => {
    return fetch("/backend/restaurants/get/count").then((response) => {
      if (response.status !== 401) {
        return response.json().then((data) => data);
      } else return { message: { msgBody: "UnAuthorized" }, msgError: true };
    });
  },
};
