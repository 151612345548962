import { useContext, useState, useRef } from "react";
import { message, Spin } from "antd";
import LoginModal from "components/LoginModal";
import FavoriteService from "services/FavoriteService";
import { FavoriteIcon } from "../Icons/FavoriteIcon";
import { FavoritesContext } from "context/FavoritesContext";
import "./styles.scss";

const Favorite = ({ id }) => {
  const [loading, setLoading] = useState(false);
  const loginModalRef = useRef(null);
  const { favorites, setFavorites, user, isAuthenticated } = useContext(
    FavoritesContext
  );

  const isFavorite = favorites?.some(
    (item) => item.entityId === id && item.userId === user.id
  );

  function handleShowLoginModal() {
    if (!isAuthenticated) {
      loginModalRef.current.open();
    }
  }

  async function addToFavoritesList(id, userId) {
    setLoading(true);
    const resData = await FavoriteService.add({
      userId: user.id || userId,
      entityId: id,
      favoriteType: "food",
    });
    if (resData.favorite) {
      setFavorites([...favorites, resData.favorite]);
    }
    if (resData?.message.msgError) {
      message.warning(resData.message.msgBody);
    }
    setLoading(false);
  }

  async function removeFromFavoritesList(id) {
    setLoading(true);
    const removedData = await FavoriteService.remove({
      entityId: id,
      userId: user.id,
    });
    const newFavorites = favorites.filter(function (item) {
      return item.entityId !== id;
    });
    if (removedData?.message.msgError) {
      message.warning(resData.message.msgBody);
    }
    setFavorites([...newFavorites]);
    setLoading(false);
  }

  async function handleFavoriteClick(id, userId) {
    if (!isFavorite && !loading) {
      addToFavoritesList(id, userId);
    } else {
      if (!loading) {
        removeFromFavoritesList(id);
      }
    }
  }
  async function handleClick() {
    if (!isAuthenticated) {
      handleShowLoginModal();
    } else {
      handleFavoriteClick(id, user.id);
    }
  }

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <div
        onClick={(e) => {
          e.stopPropagation();
          handleClick();
        }}
        className="hg-favorite-icon-wrapper"
      >
        <FavoriteIcon loading={loading} isFavorite={isFavorite} />
      </div>
      <LoginModal
        ref={loginModalRef}
        onLogin={(user) => handleFavoriteClick(id, user.id)}
        onFailure={() => {
          setFavorites([]);
        }}
      />
    </div>
  );
};

export default Favorite;
