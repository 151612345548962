import Icon from "@ant-design/icons";

const HeartSvg = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.8864 24.591C15.8864 24.591 5.89125 20.5021 5.89125 13.047C5.87059 9.92863 8.26614 7.40918 11.2193 7.40918C13.305 7.40918 15.1223 8.68956 15.9897 10.5482C16.857 8.68956 18.6744 7.40918 20.7601 7.40918C23.7133 7.40918 26.1088 9.92863 26.1088 13.047C26.1088 20.4608 15.8864 24.591 15.8864 24.591Z"
      fill="#FB5B4E"
    />
  </svg>
);

const HeartOutlinedSvg = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 34 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.0304 24.2584C16.8575 24.2584 16.6848 24.2032 16.5397 24.0932C16.502 24.0647 12.7274 21.1816 9.31415 17.768C7.1902 15.6442 7.1902 12.188 9.31415 10.0641C10.3425 9.03532 11.7104 8.46851 13.1655 8.46851C14.6206 8.46851 15.9884 9.03512 17.0172 10.0641C17.0215 10.0683 17.0259 10.0728 17.0302 10.0771C17.0344 10.0728 17.0389 10.0683 17.0431 10.0641C18.0725 9.03512 19.4405 8.46851 20.895 8.46851C22.3501 8.46851 23.718 9.03512 24.7468 10.0641C26.8703 12.188 26.8703 15.644 24.7464 17.768C21.3329 21.1816 17.5585 24.0647 17.5211 24.0932C17.3759 24.2032 17.203 24.2584 17.0304 24.2584ZM13.1655 10.088C12.143 10.088 11.1822 10.4862 10.4595 11.2091C9.73602 11.9322 9.33824 12.8933 9.33824 13.9158C9.33824 14.9383 9.73602 15.8993 10.4591 16.6228C13.0766 19.2395 15.9133 21.5405 17.0304 22.4221C18.147 21.5405 20.9841 19.2393 23.6016 16.6228C25.0941 15.13 25.0941 12.7016 23.602 11.2091C22.8789 10.4862 21.9177 10.088 20.8952 10.088C19.8727 10.088 18.9116 10.4862 18.1885 11.2093C18.0093 11.3884 17.8466 11.5874 17.7045 11.8006C17.5547 12.0263 17.3016 12.1619 17.0304 12.1619C16.7595 12.1619 16.5065 12.0263 16.3562 11.8006C16.2143 11.5874 16.0518 11.3884 15.8722 11.2091C15.1495 10.4862 14.188 10.088 13.1655 10.088Z"
      fill="#FB5B4E"
    />
  </svg>
);

export const FavoriteIcon = ({ isFavorite = false }, props) => (
  <>
    {isFavorite ? (
      <Icon component={HeartSvg} {...props} />
    ) : (
      <Icon component={HeartOutlinedSvg} {...props} />
    )}
  </>
);
