import { Skeleton } from "antd";
import "./styles.scss";

const CuisineCardSkeleton = () => {
  return (
    <div className="hg-cuisine-card-skeleton">
      <div className="hg-extra-content-skeleton">
        <div style={{ width: "50%", margin: "0 auto" }}>
          <Skeleton avatar round active paragraph={{ rows: 1 }} />
        </div>
      </div>
    </div>
  );
};

export default CuisineCardSkeleton;
