import Icon from "@ant-design/icons";

const MoreSvg = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.97445 9.42529C3.57808 9.42529 2.3999 10.6035 2.3999 11.9998C2.3999 13.4398 3.57808 14.5744 4.97445 14.5744C6.41445 14.5744 7.54899 13.3962 7.54899 11.9998C7.54899 10.6035 6.41445 9.42529 4.97445 9.42529Z"
      fill="currentColor"
    />
    <path
      d="M19.0252 9.42529C17.5852 9.42529 16.4507 10.6035 16.4507 11.9998C16.4507 13.4398 17.6289 14.5744 19.0252 14.5744C20.4216 14.5744 21.5998 13.4398 21.5998 11.9998C21.5998 10.6035 20.4216 9.42529 19.0252 9.42529Z"
      fill="currentColor"
    />
    <path
      d="M12.1309 9.42529C10.6909 9.42529 9.5564 10.6035 9.5564 11.9998C9.5564 13.4398 10.7346 14.5744 12.1309 14.5744C13.5709 14.5744 14.7055 13.3962 14.7055 11.9998C14.7055 10.6035 13.5709 9.42529 12.1309 9.42529Z"
      fill="currentColor"
    />
  </svg>
);

export const MoreIcon = (props) => <Icon component={MoreSvg} {...props} />;
