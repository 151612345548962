import { useContext, useEffect, useState } from "react";
import AutoShuffleSection from "./AutoShuffleSection";
import SectionShuffleButton from "./SectionShuffleButton";
import User from "services/User";
import Food from "services/FoodService";
import Restaurant from "services/RestaurantService";
import { AuthContext } from "context/AuthContext";
import { Card, Col, Row, Divider } from "antd";
import Text from "components/Text";
import Wrapper from "components/Wrapper";
import Layout from "layout";

const colProps = {
  xs: 24,
  sm: 12,
  md: 12,
  lg: 6,
  xl: 6,
  xxl: 6,
};

const Admin = () => {
  const authContext = useContext(AuthContext);
  const { id } = authContext.user;
  const [usersCount, setUsersCount] = useState({
    total: 0,
    admin: 0,
    user: 0,
    manager: 0,
    facbookUsers: 0,
    googleUsers: 0,
  });
  const [foodCount, setFoodCount] = useState(0);
  const [restaurantCount, setRestaurantCount] = useState(0);

  useEffect(() => {
    User.getCount().then((item) => {
      setUsersCount(item.usersCount);
    });
    Food.getCount().then((item) => {
      setFoodCount(item.count);
    });
    Restaurant.getCount().then((item) => {
      setRestaurantCount(item.count);
    });
  }, []);
  return (
    <Layout type="sm">
      <Wrapper mb={4}>
        <SectionShuffleButton />
      </Wrapper>
      <Divider />
      <Wrapper mb={4}>
        <AutoShuffleSection />
      </Wrapper>
      <Divider />
      <Row gutter={[16, 16]}>
        <Col {...colProps}>
          <Card
            title={
              <Text semiBold level={2}>
                Restaurants
              </Text>
            }
            bordered={true}
          >
            <Text bold textColor="primary" level={1}>
              {restaurantCount}
            </Text>
          </Card>
        </Col>
        <Col {...colProps}>
          <Card
            title={
              <Text semiBold level={2}>
                Food
              </Text>
            }
            bordered={true}
          >
            <Text bold textColor="primary" level={1}>
              {foodCount}
            </Text>
          </Card>
        </Col>
        <Col {...colProps}>
          <Card
            title={
              <Text semiBold level={2}>
                Total users
              </Text>
            }
            bordered={true}
          >
            <Text bold textColor="primary" level={1}>
              {usersCount.total}
            </Text>
          </Card>
        </Col>
        <Col {...colProps}>
          <Card
            title={
              <Text semiBold level={2}>
                Admins
              </Text>
            }
            bordered={true}
          >
            <Text bold textColor="primary" level={1}>
              {usersCount.admin}
            </Text>
          </Card>
        </Col>
        <Col {...colProps}>
          <Card
            title={
              <Text semiBold level={2}>
                Managers
              </Text>
            }
            bordered={true}
          >
            <Text bold textColor="primary" level={1}>
              {usersCount.manager}
            </Text>
          </Card>
        </Col>
        <Col {...colProps}>
          <Card
            title={
              <Text semiBold level={2}>
                Users
              </Text>
            }
            bordered={true}
          >
            <Text bold textColor="primary" level={1}>
              {usersCount.user}
            </Text>
          </Card>
        </Col>
        <Col {...colProps}>
          <Card
            title={
              <Text semiBold level={2}>
                Users from Google
              </Text>
            }
            bordered={true}
          >
            <Text bold textColor="primary" level={1}>
              {usersCount.googleUsers}
            </Text>
          </Card>
        </Col>
        <Col {...colProps}>
          <Card
            title={
              <Text semiBold level={2}>
                Users from Facebook
              </Text>
            }
            bordered={true}
          >
            <Text bold textColor="primary" level={1}>
              {usersCount.facbookUsers}
            </Text>
          </Card>
        </Col>
      </Row>
    </Layout>
  );
};
export default Admin;
