import BannerImage1 from "images/bannerImage1.png";
import BannerImage2 from "images/bannerImage2.png";
import { useFieldsTranslation } from "utils/hooks";
import { Banner } from "components/Banner";

const MainBannerSection = () => {
  const { t } = useFieldsTranslation();

  const bannerContent = [
    {
      image: `${BannerImage1}`,
    },
    {
      image: `${BannerImage2}`,
      title: t("banner.text.suggesting.good.food"),
      text: t("banner.text.anytime.anywhere"),
    },
  ];

  return (
    <>
      <Banner content={bannerContent} />
    </>
  );
};

export default MainBannerSection;
