import { useCallback, useContext, useEffect, useState } from "react";
import { useFieldsTranslation } from "utils/hooks";
import RestaurantService from "services/RestaurantService";
import RestaurantInfoCard from "components/RestaurantInfoCard";
import { formatImgUrl } from "utils/helper";
import { useHistory } from "react-router-dom";
import { AuthContext } from "context/AuthContext";
import InfiniteScroll from "react-infinite-scroll-component";
import { Spin, List, Input, Button } from "antd";
import debounce from "lodash.debounce";
import Layout from "layout";
import Wrapper from "components/Wrapper";
import "./styles.scss";

const Restaurants = () => {
  const authContext = useContext(AuthContext);
  const [restaurants, setRestaurants] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [searchText, setSearchText] = useState("");
  const { t, tField } = useFieldsTranslation();
  const history = useHistory();

  const { id } = authContext.user;

  const handleRestaurantPageClick = (id) => {
    history.push(`/updateRestaurant/${id}`);
  };

  const fetchMoreData = useCallback(() => {
    const itemPerPage = 24;
    const conditionRestaurant = {
      populate: JSON.stringify(["categoryId", "countryId"]),
      skip: page * itemPerPage,
      limit: itemPerPage,
      total: true,
    };
    RestaurantService.get(conditionRestaurant).then((data) => {
      setTotal(data.total);
      setRestaurants((item) => [...item, ...data.restaurants]);
    });
  }, [page]);

  useEffect(() => {
    fetchMoreData();
  }, [fetchMoreData]);

  const getSearchText = (e) => {
    setSearchText(e.target.value);
    const conditionRestaurant = {
      populate: JSON.stringify(["categoryId", "countryId"]),
      where: JSON.stringify({
        name: { $regex: e.target.value, $options: "i" },
      }),
    };
    RestaurantService.get(conditionRestaurant).then((data) => {
      setRestaurants(data.restaurants);
    });
  };

  const debouncedChangeHandler = useCallback(debounce(getSearchText, 500), []);

  return (
    <Layout type="sm">
      <div className="hg-search-wrapper">
        <Wrapper width={"300px"}>
          <Input
            size="large"
            placeholder={t("restaurant.search.inp.placheholder")}
            onChange={debouncedChangeHandler}
          />
        </Wrapper>
      </div>
      <InfiniteScroll
        dataLength={restaurants.length}
        next={() => setPage(page + 1)}
        hasMore={restaurants.length < total}
        loader={
          !searchText.length && (
            <Wrapper display="flex" py={4} justifyContent="center">
              <Spin />
            </Wrapper>
          )
        }
        style={{ overflow: "initial" }}
      >
        <List
          grid={{
            gutter: 16,
            xs: 1,
            sm: 1,
            md: 2,
            lg: 3,
            xl: 3,
            xxl: 4,
          }}
          style={{ paddingTop: "56px" }}
          dataSource={restaurants}
          renderItem={(item) => (
            <List.Item
              key={item._id}
              onClick={() => {
                handleRestaurantPageClick(item._id);
              }}
            >
              <RestaurantInfoCard
                cursorPointer={true}
                data={{
                  name: tField(item, "name"),
                  openingHours: item.twentyFourBySeven
                    ? "24/7"
                    : `${
                        new Date(item.openingHours?.open)
                          .toTimeString()
                          .split(" ")[0]
                      } - ${
                        new Date(item.openingHours?.closed)
                          .toTimeString()
                          .split(" ")[0]
                      }`,
                  phone: item.phone,
                  priceRange: item.priceRange,
                  currency: t("AMD"),
                  country: tField(item.countryId, "name"),
                  icon: formatImgUrl(item.profileImg),
                  location:
                    item.address &&
                    item.address[0].name?.split(",").splice(2).join(" "),
                }}
              />
            </List.Item>
          )}
        />
      </InfiniteScroll>
    </Layout>
  );
};
export default Restaurants;
