import { forwardRef, useImperativeHandle, useState } from "react";
import { useHistory } from "react-router-dom";
import { LocationIcon } from "../Icons/LocationIcon";
import { Spin } from "antd";
import "./styles.scss";

const FindFoodNearMe = forwardRef(({ style }, ref) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  useImperativeHandle(
    ref,
    () => ({
      geoFindMe,
    }),
    []
  );

  // const geoFindMe = () => {
  //   if (navigator.geolocation) {
  //     setLoading(true);
  //     navigator.geolocation.getCurrentPosition(
  //       (success) => {
  //         history.push(
  //           `/near-me/${success.coords.latitude}/${success.coords.longitude}`
  //         );
  //         setLoading(false);
  //       },
  //       (err) => {
  //         alert(err.code + ": " + err.message + err.PERMISSION_DENIED + err.POSITION_UNAVAILABLE)
  //         setLoading(false);
  //       },
  //       {
  //         enableHighAccuracy: true,
  //         timeout: 5000,
  //         maximumAge: 0,
  //       }
  //     );
  //   }else{
  //     alert('Geo Location feature is not supported in this browser.');
  //     setLoading(false);
  //   }

  // };

  function geoFindMe() {
    setLoading(true);
    function success(position) {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;
      history.push(`/near-me/${latitude}/${longitude}`);
      setLoading(false);
    }

    function error() {
      alert("Unable to retrieve your location");
      setLoading(false);
    }

    if (!navigator.geolocation) {
      alert("Geolocation is not supported by your browser");
      setLoading(false);
    } else {
      navigator.geolocation.getCurrentPosition(success, error);
    }
  }

  return (
    <>
      <div onClick={geoFindMe} className="hg-find-near-me-button">
        {!loading ? <LocationIcon style={style} /> : <Spin style={style} />}
      </div>
    </>
  );
});

export default FindFoodNearMe;
